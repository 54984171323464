import { createAction, props } from '@ngrx/store';

 
export const getUsernameToUserInformationList = createAction(
  '[USER_DEALER_OUTLET_MAPPING_LIST] Get getUsernameToUserInformation',
  props<any>()
);

export const getUsernameToUserInformationListSuccess = createAction(
'[USER_DEALER_OUTLET_MAPPING_LIST] Get getUsernameToUserInformation Success',
  props<any>()
);

export const getUsernameToUserInformationListFailure = createAction(
'[USER_DEALER_OUTLET_MAPPING_LIST] Get getUsernameToUserInformation Failure',
  props<any>()
);

export const setPermissionForUser = createAction(
  '[USER_DEALER_OUTLET_MAPPING_LIST] Get set Permissions',
  props<any>()
);
