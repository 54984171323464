import { Component } from '@angular/core';

@Component({
  selector: 'app-selecttemplate',
  templateUrl: './selecttemplate.component.html',
  styleUrls: ['./selecttemplate.component.scss']
})
export class SelecttemplateComponent {

}
