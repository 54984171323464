
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CreateCampaignPopupComponent } from '../create-campaign-popup/create-campaign-popup.component';
import { Validators, Editor, Toolbar } from 'ngx-editor';
import { SelectTemplatePopupComponent } from '../../../../select-template-popup/select-template-popup.component';
import { LinepopupComponent } from '../../../../linepopup/linepopup.component';
import { ProfileComponent } from '../../../commonModule/components/profile/profile.component';
import * as fromRoot from 'src/app/app-state';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Subject, takeUntil } from 'rxjs';
import * as actions from 'src/app/app-state/actions';
import { Store } from '@ngrx/store';
import { getDepartmentResponse } from 'src/app/app-state/types';
import { UsersService } from 'src/app/modules/usermanagement/service/users/users.service';
import { DatePipe } from '@angular/common';
import { PreviewTemplateComponent } from '../preview-template/preview-template.component';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
export interface CampaignType {
  value: number;
  name: string;
}

// Define the main interface for the entire response
export interface CampaignTypesResponse {
  result: CampaignType[];
  responseMessage: string;
  isSuccess: boolean;
  error: any; // You can replace 'any' with a more specific type if needed
}
export interface department{
  id: number,
  channelId: number,
  tenant_Id: number,
  tenant_Name:string,
  name_English:string,
  name_Arabic:string,
  value: string,
  "is_Active": null,
  "created_By": number,
  "created_By_Name": string,
  "created_On": string,
  "modified_By": number,
  "modified_By_Name": null,
  "modified_On":string,
  "is_In_Use": null,
  "isWorkFlow": null,
  "action": number,
  "approvedBy": number,
  "approvedOn":string,
  "workFlowStatus": number,
  "masterId": number,
  "approvedByName": null,
  "remark": null
}
export const MY_FORMATS = {
  parse: {
    dateInput:'DD-MM-YYYY hh:mm'
  },
  
  display: {
    dateInput: 'DD-MM-YYYY hh:mm',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY'
  }
};




@Component({
  selector: 'app-create-campaign',
  templateUrl: './create-campaign.component.html',
  styleUrls: ['./create-campaign.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class CreateCampaignComponent implements OnInit, OnDestroy {
  batchvalue: number = 1;
  isActive: boolean = true;
  isActive1: boolean=true;
  isActive2: boolean= true;
  departmentList: Array<any>=[]
  dep:string|any ;
  selectednum:number=0
  showTooltip: boolean = false;
  panelOpenState = false;
  language: any =['English'];
  start: string='';
  selectedMessageType: string = ""; 
  end: string='';
  english: boolean=true;
  french: boolean=false;
  arabic: boolean=false;
  custno:string=''
  id: number=0;
  file:any;
  campaignname: string='';
  mannualentryvalue: string='';
  receipent: string='Regular';
  sub: string=''
  sub1:string=''
  selectmail:string=''
  selectyes:boolean=false;
  selectno:boolean=false;
  selectTemplate:string=''
  editorval: string='';
  selectconnector:string=''
  editorval1:string='';
  ChooseDate: any=new Date()
  senderid: string='';
  senderid1 : string='';
  messagetype: string='';
  channel:string='';
  group: Array<any>=[];
  queparm: string='';
  selectMSISDN:string=''
  selectshortcode:string=''
  grouplist: Array<any> = [];
  languages: Array<any> = [];
  shortcodes:Array<any> = [];
  messages:Array<any> = [];
  campaigns:Array<any> = [];
  originalString: string = '';
  reversedString: string = '';
  channels:Array<any> = [];
  checkfile: boolean=false;
  checkmannual:boolean=false;
  checkgroup:boolean=false
  camptemplates: any;
  smtps: any;
  selectedtemplate: any;
  formattedCurrentTimePlus10: string | null;
  dialog: any;
  uploadeddata: any;
  dataReferenceId: any;
  fileReferenceId: any;
  uploadedmessage: any;
  formattedTime: any;
  calltime :string='00:00'
  calltime3 :string='00:00'
  calltimeto3 :string='23:00'
  editor: Editor = new Editor;
  hours : any;
  minutes : any;
  convertedTime: any;
  selectedDays: any = [0, 1, 2, 3, 4, 5, 6];
  datesDays: any = [0, 1, 2, 3, 4, 5, 6];
  newDays: any[]=[];
  allnewfilename: any;
  errormes: boolean = false;
  errorforfile: boolean = false;
  errorforgroup: boolean = false;
  errorformanual: boolean = false;
  minDate: any;
  schemadata: any;
  campaigngroupdata: any;
  duplicateRecords: any;
  invalidMobNumberCount: any;
  validCount: any;
  totalCount:any;
  recordCount:any;
  ChooseTime: any
  schemaMapping: any;
  payload:any;
  groupuploadeddata:any;
  groupcampaigngroupdata:any;
  groupdataReferenceId: any;
  groupfileReferenceId: any;
  groupuploadedmessage: any;
  groupduplicateRecords: any;
  groupinvalidMobNumberCount: any;
  groupvalidCount: any;
  groupTotalCount: any;
  groupschemadata:any;
  groupschemaMapping:any;
  manualuploadeddata:any;
  manualcampaigngroupdata:any;
  manualdataReferenceId: any;
  manualfileReferenceId: any;
  manualuploadedmessage: any;
  manualduplicateRecords: any;
  manualinvalidMobNumberCount: any;
  manualvalidCount: any;
  manualTotalCount: any;
  manualschemadata:any;
  manualschemaMapping:any;
  timeDifference:any;
  recipientPerBatch: any;
  excelData: any[]=[
    {
        "CUSTOMERNO": "Customer 1",
        "Name": "A",
        "MSISDN": 96555630122,
        "EMAILID": "abc1@gmail.com",
        "USERFIELDPUSH": "User 1",
        "USERFIELDINBOX": "User 1",
        "WHATSAPPMOBILENUMBER": 96555630122,
        "PRODUCTNO": "Product 1",
        "LANGUAGE": "EN",
        "AMOUNT": 10000,
        "CURRENCY": "KWD"
    },
    {
        "CUSTOMERNO": "Customer 2",
        "Name": "B",
        "MSISDN": 96555630123,
        "EMAILID": "abc2@gmail.com",
        "USERFIELDPUSH": "User 2",
        "USERFIELDINBOX": "User 2",
        "WHATSAPPMOBILENUMBER": 96555630123,
        "PRODUCTNO": "Product 2",
        "LANGUAGE": "AR",
        "AMOUNT": 10001,
        "CURRENCY": "KWD"
    },
    {
        "CUSTOMERNO": "Customer 3",
        "Name": "C",
        "MSISDN": 96555630124,
        "EMAILID": "abc1@gmail.com",
        "USERFIELDPUSH": "User 3",
        "USERFIELDINBOX": "User 3",
        "WHATSAPPMOBILENUMBER": 96555630124,
        "PRODUCTNO": "Product 3",
        "LANGUAGE": "EN",
        "AMOUNT": 10002,
        "CURRENCY": "KWD"
    },
    {
        "CUSTOMERNO": "Customer 4",
        "Name": "D",
        "MSISDN": 96555630125,
        "EMAILID": "abc2@gmail.com",
        "USERFIELDPUSH": "User 4",
        "USERFIELDINBOX": "User 4",
        "WHATSAPPMOBILENUMBER": 96555630125,
        "PRODUCTNO": "Product 4",
        "LANGUAGE": "AR",
        "AMOUNT": 10003,
        "CURRENCY": "KWD"
    },
    {
        "CUSTOMERNO": "Customer 5",
        "Name": "E",
        "MSISDN": 96555630126,
        "EMAILID": "abc1@gmail.com",
        "USERFIELDPUSH": "User 5",
        "USERFIELDINBOX": "User 5",
        "WHATSAPPMOBILENUMBER": 96555630126,
        "PRODUCTNO": "Product 5",
        "LANGUAGE": "EN",
        "AMOUNT": 10004,
        "CURRENCY": "KWD"
    },
    {
        "CUSTOMERNO": "Customer 6",
        "Name": "F",
        "MSISDN": 96555630127,
        "EMAILID": "abc2@gmail.com",
        "USERFIELDPUSH": "User 6",
        "USERFIELDINBOX": "User 6",
        "WHATSAPPMOBILENUMBER": 96555630127,
        "PRODUCTNO": "Product 6",
        "LANGUAGE": "AR",
        "AMOUNT": 10005,
        "CURRENCY": "KWD"
    }
];
selectedTemplateData:any;
selectedTemplateName:any;
  campaign: any;
  submit : boolean = false;
  constructor(private dialogref: MatDialog,
    private store: Store,
    public userservice:UsersService,
    private activateRoute:ActivatedRoute,
    private route:Router,

    private datePipe: DatePipe) {
    
       this.minDate = new Date();

    this.calculateTimeDifference()
   
      const currentDate = new Date();
    
      currentDate.setMinutes(currentDate.getMinutes() + 15);
      this.formattedCurrentTimePlus10 = this.datePipe.transform(currentDate, 'dd-MM-yyyy, hh:mm');
      const hh = currentDate.getHours().toString().padStart(2, '0');
      const mm = currentDate.getMinutes().toString().padStart(2, '0');
      let currentTime = `${hh}:${mm}`;
      // const [hours, minutes] = currentTime.split(':').map(Number);
      // const roundedHours = (minutes > 0) ? hours + 1 : hours;
      // const roundedTime = `${roundedHours.toString().padStart(2, '0')}:00`;
      // this.ChooseTime = roundedTime; 
      this.ChooseTime = currentTime
      console.log(this.ChooseTime,"time")
      this.activateRoute.queryParams.subscribe((par:any) => {
        this.queparm = par['parm']

      })
      this.activateRoute.params.subscribe((par: Object|any) => {
        this.id = par?.id
        })
        console.log(this.id,"idd")

    this.store.dispatch(
      actions.getDepartments({}))
    this.store
    .select(fromRoot.selectStatesgetDepartmentsData)
    .pipe(takeUntil(this.destroy$))
    .subscribe((data: getDepartmentResponse) => {
      this.departmentList = data?.result  
      // console.log(this.departmentList,"dd")
   })
   this.userservice.Getgroupsubset({"groupId": 1}).subscribe(res=>{
    this.grouplist=res?.result;
   })
   this.userservice.Getlanguages().subscribe(val=>{
    this.languages = val?.result
    // console.log(this.language,"language")
   })

   this.userservice.Getshortcodes().subscribe(val=>{
    this.shortcodes = val?.result

   })

   this.userservice.Getsmtp().subscribe(val=>{
    this.smtps = val?.result

   })
  //  this.userservice.Getmessagetemplatescampaign({
  //  "channelId": 1,
  //  "languageId": 2,
  //  "departmentId": 1,
  //  "personalisedType": 2}
  //  ).subscribe((val:any)=>{
  //   this.camptemplates = val?.result

  //  })
  if(this.id){
    this.userservice.Getcampaign({id:this.id}).subscribe(val => {
      this.campaign= val?.result
      console.log(this.campaign,"campaign")
      this.campaignname = this.campaign?.campaign?.campaignName
      this.messagetype =  this.campaign?.campaign?.queueTypeId
      this.dep = this.campaign?.campaign?.departmentId
      this.group = this.campaign?.campaign?.groupSelected.split(',').map(Number);
      console.log(this.group,"is")
      this.allnewfilename = this.campaign?.campaign?.excelFilePath
      this.ChooseDate = this.campaign?.campaign?.scheduledDateTime
      const timePart = this.ChooseDate.split('T')[1];
      this.ChooseTime = timePart.substring(0, 5);
      this.calltime3 = this.campaign?.campaign?.allowedStartTime.substring(0, 5);
      this.calltimeto3 = this.campaign?.campaign?.allowedEndTime.substring(0, 5);
      this.selectedDays = this.campaign?.campaign?.daysToSend 
      this.batchvalue = this.campaign?.campaign?.numberOfBatches
      this.selectedtemplate  = this.campaign?.email?.messageTemplateId
      console.log(this.selectedtemplate,"tm")
      this.validCount= this.campaign?.campaign?.validCount
      this.totalCount=this.campaign?.campaign?.totalCount
      this.recordCount=this.campaign?.campaign?.recordCount
      this.calltime= this.campaign?.campaign?.allowedTimeGap
      this.calltime = this.convertMinutesToHHMM(this.calltime);
      console.log(this.calltime, "formatted time");
      [this.hours, this.minutes] = this.calltime.split(':');
      console.log(this.hours,this.minutes,"ttt",this.calltime)
      this.userservice.GetMessageTemplate({ templateID: this.selectedtemplate}).subscribe((res:Object |any) => {
        this.selectedTemplateData = res.result
        this.selectedTemplateName = res?.result?.name;
        console.log(res.result,"temp",this.selectedTemplateName)
      this.calculateTimeDifference()
      this.calculateRecipientPerBatch()
      })

    })

  }


   this.userservice.GetqueueTypes().subscribe(val=>{
    this.messages = val?.result
    
   })
   this.userservice.Getchannels().subscribe((val:any)=>{
    this.channels = val?.result
    // console.log(this.channels,'this.channels')
    
   })
   this.userservice.GetcampaignTypes().subscribe(val=>{
    this.campaigns = val?.result
    console.log(val,'yyttt')
    
   })
   
 
  }
  calculateRecipientPerBatch() {
    console.log(this.groupvalidCount,"ww");
    
    if (this.batchvalue !== 0) {
      if(this.group.length){
        this.recipientPerBatch = (parseInt(this.groupvalidCount || 0) / this.batchvalue || 0);
        console.log(this.recipientPerBatch,"dd1", this.groupvalidCount, this.batchvalue);
      }
      if(this.allnewfilename){
        this.recipientPerBatch = (parseInt(this.validCount || 0) / this.batchvalue || 0);
        console.log(this.recipientPerBatch,"dd2", this.validCount, this.batchvalue)
      }
      if(this.mannualentryvalue){
        this.recipientPerBatch = (parseInt(this.manualvalidCount || 0) / this.batchvalue || 0);
        console.log(this.recipientPerBatch,"dd3", this.manualvalidCount, this.batchvalue)
      }
      if(this.id){
        this.recipientPerBatch = (parseInt(this.validCount || this.recordCount) / this.batchvalue || 0);
        console.log(this.recipientPerBatch,"dd4", this.recordCount, this.batchvalue)
      }
      
      
    } else {
      this.recipientPerBatch = 0;
    }
  }
  convertMinutesToHHMM(minutesString: string): string {
    const minutes = parseInt(minutesString, 10); 
    if (isNaN(minutes)) {
      return 'Invalid Time';
    }
  
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
  
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = remainingMinutes.toString().padStart(2, '0');
  
    return `${formattedHours}:${formattedMinutes}`;
  }


  ques() {
    this.dialogref.open(CreateCampaignPopupComponent, {

      data: {

      },
    });
  };
  getDayOfWeek(eve: string) {
    const date = new Date(eve);
    console.log(date,this.selectedDays);
    const dayIndex = date.getDay();
    this.datesDays.map((value:any)=>{
      if(value == dayIndex){
       this.newDays.splice(0,1,dayIndex)
      }
    })
    console.log(dayIndex,this.newDays,)
    return this.newDays;
}
  toggleDay(day: any): void {
    this.emptyvalues()
    const index = this.selectedDays.indexOf(day);
    if (index === -1) {
      this.selectedDays.push(day);
    } else {
      this.selectedDays.splice(index, 1);
    }
  }
  getDays() {
    this.calltime3 = '00:00';
    this.calltimeto3 = '23:00';
    console.log(this.ChooseDate,"ddate")
    const dayOfWeek = this.getDayOfWeek(this.ChooseDate);
   console.log("dayweek",dayOfWeek)
  //  if (dayOfWeek !== -1) {
    this.selectedDays = dayOfWeek;
  // }
  console.log(this.selectedDays,"wdqw");
  }
  async setDayTime() {
    this.isActive2 = !this.isActive2;
    if(this.isActive2){
      this.selectedDays = [0, 1, 2, 3, 4, 5, 6];
      console.log(this.selectedDays,"dd")
    }else{
     await this.getDays()
    }
    
  }
  selectpopup() {

  const confirmDialog =  this.dialogref.open(SelectTemplatePopupComponent, {
    panelClass:['select-template-popup-box']
    
    })
    confirmDialog.afterClosed().subscribe(result => {
      console.log(result,'reee7788')
      this.selectedtemplate = result
      console.log(this.selectedtemplate,"d")
    })

  }
 
  checkmattab(e:object|any){
    if(e.index== 1){
  this.selectednum =1
    }else{
  this.selectednum =0

    }

  }
  editorConfig = {
    contentsLangDirection: 'rtl', 
  };
  editorConfig1 = {
    contentsLangDirection: 'rtl', 
  };
  reverseString() {
    
    this.editorval1 = this.editorval1.split('').reverse().join('');

  }
  openDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.dialogref.open(LinepopupComponent, {
      width: '250px',
      enterAnimationDuration,
      exitAnimationDuration,
    });
  }

  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike', 'horizontal_rule'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];
  checkTime(newTime: string) {
    this.emptyvalues()


    console.log('New time selected:', newTime);
  this.ChooseTime = newTime
  console.log(this.ChooseTime,"ti",this.group.length)
  // if(this.group.length == 0 || this.allnewfilename == '' || this.mannualentryvalue == ''){
  //   this.errorforfile = false;
  //   return;
  // }
  } 

  emptyvalues(){
    if(this.errorforfile || this.errorforgroup || this.errorformanual){
      this.group = []
      this.groupuploadeddata = []
      this.groupdataReferenceId = ''
      this.groupTotalCount = ''
      this.groupduplicateRecords = ''
      this.groupinvalidMobNumberCount = ''
      this.groupvalidCount = ''

      this.mannualentryvalue = ''
      this.manualdataReferenceId = ''
      this.manualTotalCount = ''
      this.manualduplicateRecords = ''
      this.manualvalidCount = ''
      this.manualuploadeddata = []
      this.manualinvalidMobNumberCount = ''

      this.allnewfilename = ''
      this.uploadeddata = ''
      this.dataReferenceId = ''
      this.fileReferenceId = ''
     this.duplicateRecords = ''
     this.invalidMobNumberCount =  ''
     this.validCount = ''
     this.totalCount = ''
      
    this.errorforfile = false;
    this.errorforgroup = false;
    this.errorformanual = false;
return;
    }
  }

  // onChooseTimeChange(e:any) {
  //   const [hours, minutes] = e.split(':').map(Number);
  //   const roundedHours = (minutes > 0) ? hours + 1 : hours;
  //   const roundedTime = `${roundedHours.toString().padStart(2, '0')}:00`;
  //   this.ChooseTime = roundedTime;
  // //  console.log(this.ChooseTime, "s");
  // }

  async checkdate(e:any){
    this.emptyvalues()
    let d = e.target.value
  // const _ = moment();
  // const date = moment(d).add({hours: _.hour(), minutes:_.minute() , seconds:_.second()})
  // let ChooseDate1 = date.toDate();
  let date : any
  date = moment(d)
  this.ChooseDate = date._d
  console.log(this.ChooseDate,"dd00")
if(!this.isActive2){
  console.log(this.ChooseDate,"dd11")
  await this.getDays()
}
  }

  uploadfile(event:any){
    this.checkfile =  !this.checkfile
  }
  mannualentry(event:any){
    this.checkmannual =  !this.checkmannual
  }
  groupadd(event:any){
    this.checkgroup =  !this.checkgroup
  }
  chooseyes(event:any){
    this.selectyes =  !this.selectyes
  }
  chooseno(event:any){
    this.selectno =  !this.selectno
  }
  ngOnInit(): void {
    this.editor = new Editor();
  }
  languagechange(event:Event){
    this.language = event
    this.english =false
    this.french =false
    this.arabic =false


      if(this.language[0] == 'English' && this.language[1]== 'Arabic' ){
        this.english =true
        this.arabic= true

      }
     else if(this.language[0] == 'Arabic' && this.language[1]== 'English'  ){
  
      this.arabic =true
      this.english=true
      }
     else if( this.language[0] == 'Arabic'){
      this.english =false
        this.arabic =true
        this.selectednum = 1
       
      }
      else if( this.language[1] == 'Arabic'){
      
        this.english =true
          this.arabic =true
        this.selectednum = 1

         
        }
        else if( this.language[0] == 'English'){
      
          this.english =true
            this.arabic =false
        this.selectednum = 0

           
          }
          else if( this.language[1] == 'English'){
      
            this.english =true
              this.arabic =true
        this.selectednum = 0

             
            } 
            else if(this.language.length == 0 ){
              

              this.english= false
              this.arabic = false 
            }
    

    }

  Add(){
    this.emptyvalues()
    this.batchvalue++;
    // this.calculateRecipientPerBatch()


  }
  checkBatch(val:number){
    this.emptyvalues()
    console.log('New time selected:', val);
  this.batchvalue = val
  // this.calculateRecipientPerBatch();
  }
  Minus(){
    if(this.batchvalue > 1){
      this.emptyvalues()
      this.batchvalue--;
      // this.calculateRecipientPerBatch()
    }

  }
  checkHours(val:string){
    this.emptyvalues()
    console.log('New hour selected:', val);
    this.calltime = val
  }

  calculateTimeDifference() {
    
    if (this.calltime3 || this.calltimeto3) {

      const fromTime = new Date(`2022-01-01T${this.calltime3}`);
      const toTime = new Date(`2022-01-01T${this.calltimeto3}`);
      const timeDiff = Math.abs(toTime.getTime() - fromTime.getTime());
      
      const hours = Math.floor(timeDiff / 3600000);
      const minutes = Math.floor((timeDiff % 3600000) / 60000);

      const formattedHours = String(hours);
      const formattedMinutes = String(minutes).padStart(2, '0');
      this.timeDifference = `${formattedHours}H : ${formattedMinutes}M`;
    } else {
      this.timeDifference = null;
    }
  }

  fromCheck(val:string){
    this.emptyvalues()
    console.log('New from selected:', val);
    this.calltime3 = val
    this.calculateTimeDifference();
  }
  toCheck(val:string){
    this.emptyvalues()
    console.log('New to selected:', val);
    this.calltimeto3 = val
    console.log('New:',  this.calltimeto3);
    this.calculateTimeDifference();
  }

  getsplit(){
    this.isActive = !this.isActive;
    if(!this.isActive){
      this.batchvalue = 1;
    }
    
  }
  contentchange(){
    this.isActive1 = !this.isActive1;
    
  }
  openPreview(el:string): void {
    console.log(el,"e")
     this.dialogref.open(PreviewTemplateComponent, {
      data:{send:el}
    });

  }


  ngOnDestroy(): void {
    this.editor.destroy();
  }

    usericonDialog() {
      this.dialogref.open(ProfileComponent,{
        panelClass:['common-modalbox'],
      })
      
        }
        destroy$: Subject<boolean> = new Subject<boolean>();
    uploadGroup(){
      
      this.errorforgroup = true
      const [starthours, startminutes] = this.calltime3.split(':').map(String);
      const roundedMinutes = startminutes;
     const roundedTime = `${starthours.toString().padStart(2, '0')}:${roundedMinutes}`;
      this.calltime3 = roundedTime;
      const [endhours, endminutes] = this.calltimeto3.split(':').map(String);
      const roundedMinutes1 =  endminutes;
     const roundedTime1 = `${endhours.toString().padStart(2, '0')}:${roundedMinutes1}`;
      this.calltimeto3 = roundedTime1;
      console.log(this.ChooseTime,this.ChooseDate,"tt");
      [this.hours, this.minutes] = this.calltime.split(':');
      console.log(this.hours,this.minutes,"ttt")
      let groupPayload = {
        "campaignType": 1,
        "channels": [
        2
        ],
        "scheduleDate": moment(this.ChooseDate).format('YYYY/MM/DD'),
        "scheduleTime": this.ChooseTime,
        "startTime": this.calltime3,
        "endTime": this.calltimeto3,
        "batchCount": this.batchvalue, 
        "dataFilters": {
        "subscription": true,
        "subscriptionValueType": 2,
        "subscriptionDatalevel": 2,
        "mandatoryOptOut": false,
        "dnd": false,
        "dndChannels": [
        3
        ]
        },
        "dataSources": [
        {
        "type": 3,
        "data": {
        "groupIds": this.group
        }
        }
        ],
        "daysToSend": this.selectedDays,
        "allowedTimeGap": {
        "hours": this.hours,
        "minutes": '00'||this.minutes
        },
        "isChannelInformationInSource": true
        }
        console.log(groupPayload,"resgroup")
        const formDatagroup = new FormData();
        formDatagroup.append('body', JSON.stringify(groupPayload));
        this.userservice.uploadfile(formDatagroup).subscribe((res:any) => {
        console.log(res,"groupupload")
        if(res.isSuccess == true){
          this.errorforfile = true;
          this.userservice.toast.snackbarSucces("File Uploaded Successfully!");
      
      
                    this.groupuploadeddata = res?.result?.uploadedData
                    this.groupcampaigngroupdata = res?.result?.uploadedData?.campaignGroups
                    this.groupschemadata = res?.result?.uploadedData?.schema
                    this.groupdataReferenceId = res?.result?.dataReferenceId
                    // this.groupfileReferenceId = res?.result?.fileReferenceId
                    this.groupschemaMapping = res?.result?.uploadedData?.schemaMapping
                    this.groupuploadedmessage = res?.responseMessage
                    this.groupTotalCount = res?.result?.uploadedData?.totalCount
                   this.groupduplicateRecords = res?.result?.uploadedData?.duplicateRecords
                   this.groupinvalidMobNumberCount =  res?.result?.uploadedData?.totalInvalidCount
                   this.groupvalidCount = res?.result?.uploadedData?.validCount
                  }
                  this.calculateRecipientPerBatch()
        })
    }
    uploadManual() {
      this.errorformanual = true;
      const [starthours, startminutes] = this.calltime3.split(':').map(String);
      const roundedMinutes = (startminutes > '00') ? '00' : startminutes;
     const roundedTime = `${starthours.toString().padStart(2, '0')}:${roundedMinutes}`;
      this.calltime3 = roundedTime;
      const [endhours, endminutes] = this.calltimeto3.split(':').map(String);
      const roundedMinutes1 = (endminutes > '00') ? '00' : endminutes;
     const roundedTime1 = `${endhours.toString().padStart(2, '0')}:${roundedMinutes1}`;
      this.calltimeto3 = roundedTime1;
      console.log(this.ChooseTime,this.ChooseDate,"tt");
      [this.hours, this.minutes] = this.calltime.split(':');
      console.log(this.hours,this.minutes,"ttt")
      let manualPayload = {
        "campaignType": 1,
        "channels": [
        2
        ],
        "scheduleDate": moment(this.ChooseDate).format('YYYY/MM/DD'),
        "scheduleTime": this.ChooseTime,
        "startTime": this.calltime3,
        "endTime": this.calltimeto3,
        "batchCount": this.batchvalue, 
        "dataFilters": {
        "subscription": true,
        "subscriptionValueType": 2,
        "subscriptionDatalevel": 2,
        "mandatoryOptOut": false,
        "dnd": false,
        "dndChannels": [
        3
        ]
        },
        "dataSources": [
        {
        "type": 2,
        "data": {
          "manualEntries": this.mannualentryvalue
          }
        }
        ],
        "daysToSend": this.selectedDays,
        "allowedTimeGap": {
        "hours": this.hours,
        "minutes": '00'||this.minutes
        },
        "isChannelInformationInSource": true
        }
        const formDatamanual = new FormData();
        formDatamanual.append('body', JSON.stringify(manualPayload));
        this.userservice.uploadfile(formDatamanual).subscribe((res:any) => {
        console.log(res,"manualupload")
        if(res.isSuccess == true){
          this.userservice.toast.snackbarSucces("Manual Entry Uploaded Successfully!");
                    this.manualuploadeddata = res?.result?.uploadedData
                    this.manualcampaigngroupdata = res?.result?.uploadedData?.campaignGroups
                    this.manualschemadata = res?.result?.uploadedData?.schema
                    this.manualdataReferenceId = res?.result?.dataReferenceId
                    // this.manualfileReferenceId = res?.result?.fileReferenceId
                    this.manualschemaMapping = res?.result?.uploadedData?.schemaMapping
                    this.manualuploadedmessage = res?.responseMessage
      
                   this.manualduplicateRecords = res?.result?.uploadedData?.duplicateRecords
                   this.manualinvalidMobNumberCount =  res?.result?.uploadedData?.totalInvalidCount
                   this.manualvalidCount = res?.result?.uploadedData?.validCount
                   this.manualTotalCount = res?.result?.uploadedData?.totalCount
                   
                  }
                  
                  this.calculateRecipientPerBatch()
        })
    }

    downloadexcel(){
      let exportData2 = JSON.parse(JSON.stringify(this.excelData));

      let dataKeys = Object.keys(exportData2[0]);
  
      var options = {
        fieldSeparator: ',',
  
        quoteStrings: '"',
  
        decimalseparator: '.',
  
        showLabels: true,
  
        showTitle: true,
  
        title: 'Campaign-Creation',
  
        useBom: true,
  
        noDownload: false,
  
        headers: dataKeys,
  
        nullToEmptyString: true,
      };
  
      let report = new AngularCsv(exportData2, options.title, options);
  
          

    }

  

        selectFile(event:any){
          this.errorforfile = true
          let workBook: any = null;
          let jsonData = null;
          const reader = new FileReader();
          const file1 = event.target.files[0];
      
          reader.onload = (event) => {
            const data = reader.result;
            workBook = XLSX.read(data, { type: 'binary' });
            jsonData = workBook.SheetNames.reduce((initial: any, name: any) => {
              const sheet = workBook.Sheets[name];
              initial[name] = XLSX.utils.sheet_to_json(sheet);
              return initial;
            }, {});
            if (jsonData?.Sheet1) {
              this.excelData = jsonData.Sheet1;
            } else if (jsonData?.data) {
              this.excelData = jsonData.data;
            } 
            // else {
            //   // this.excelData = null;
            
            // }
            console.log('ftghjmk',this.excelData)
          };
          reader.readAsBinaryString(file1);



          const [starthours, startminutes] = this.calltime3.split(':').map(String);
          const roundedMinutes = (startminutes > '00') ? '00' : startminutes;
         const roundedTime = `${starthours.toString().padStart(2, '0')}:${roundedMinutes}`;
          this.calltime3 = roundedTime;
          const [endhours, endminutes] = this.calltimeto3.split(':').map(String);
          const roundedMinutes1 = (endminutes > '00') ? '00' : endminutes;
         const roundedTime1 = `${endhours.toString().padStart(2, '0')}:${roundedMinutes1}`;
          this.calltimeto3 = roundedTime1;
          console.log(this.ChooseTime,this.ChooseDate,"tt");
          [this.hours, this.minutes] = this.calltime.split(':');
          console.log(this.hours,this.minutes,"ttt")
          // const file = event.target.files[0];
          const formData = new FormData();
          this.allnewfilename =file1.name

          // const currentDate = new Date();
        // currentDate.setDate(currentDate.getDate());
        // const formattedDate = `${currentDate.getFullYear()}/${(currentDate.getMonth() + 1).toString().padStart(2, '0')}/${currentDate.getDate().toString().padStart(2, '0')}`;

          let payload1 =      {
            "campaignType": 1,
            "channels": [
                2
            ],
            "scheduleDate": moment(this.ChooseDate).format('YYYY/MM/DD'),
            "scheduleTime": this.ChooseTime,
            "startTime": this.calltime3,
            "endTime": this.calltimeto3,
            "batchCount": this.batchvalue,   
           "dataFilters": {
                "subscription": true,
                "subscriptionValueType": 2,
                "subscriptionDatalevel": 2,
                "mandatoryOptOut": false,
                "dnd": false,
                "dndChannels": [
                    3
                ]
            },
            "dataSources": [
                {
                    "type": 1,
                    "data": {
                        "fileName": this.allnewfilename
                    }
                }
            ],
            "daysToSend": this.selectedDays,
            "allowedTimeGap": {
                "hours": this.hours,
                "minutes": this.minutes
            },
            "isChannelInformationInSource": true
          }
          console.log(file1);
           formData.append('file', file1);
        //  formData.set('Content-Type','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') 
          formData.append('body', JSON.stringify(payload1));
          // formData.set('Content-Type', 'application/json');

          console.log(formData,'el3k32k3k23')
          this.file = ''
        
          this.userservice.uploadfile(formData).subscribe((res:any)=>{
            console.log(res,'resss999')
            if(res.isSuccess == true){
    this.userservice.toast.snackbarSucces("File Uploaded Successfully!");

              this.uploadeddata = res?.result?.uploadedData
              this.campaigngroupdata = res?.result?.uploadedData?.campaignGroups
              this.schemadata = res?.result?.uploadedData?.schema
              this.dataReferenceId = res?.result?.dataReferenceId
              this.fileReferenceId = res?.result?.fileReferenceId
              this.schemaMapping = res?.result?.uploadedData?.schemaMapping
              this.uploadedmessage = res?.responseMessage

             this.duplicateRecords = res?.result?.uploadedData?.duplicateRecords
             this.invalidMobNumberCount =  res?.result?.uploadedData?.totalInvalidCount
             this.validCount = res?.result?.uploadedData?.validCount
             this.totalCount = res?.result?.uploadedData?.totalCount
             
            }

            this.calculateRecipientPerBatch()
          })
        }

        validateInputLength(){
          if (this.campaignname.length > 500) {
              this.userservice.toast.snackbarSucces('Character limit exceeded (max: 500)');
          }
        }

        Submit(){
       
          const [starthours, startminutes] = this.calltime3.split(':').map(String);
          const roundedMinutes = (startminutes > '00') ? '00' : startminutes;
         const roundedTime = `${starthours.toString().padStart(2, '0')}:${roundedMinutes}`;
          this.calltime3 = roundedTime;
          const [endhours, endminutes] = this.calltimeto3.split(':').map(String);
          const roundedMinutes1 = (endminutes > '00') ? '00' : endminutes;
         const roundedTime1 = `${endhours.toString().padStart(2, '0')}:${roundedMinutes1}`;
          this.calltimeto3 = roundedTime1;
          [this.hours, this.minutes] = this.calltime.split(':');
          console.log(this.hours,this.minutes,"ttt")
       if( !this.campaignname || !this.messagetype || !this.dep || !this.group || !this.calltime || !this.calltime3 || !this.calltimeto3 || !this.selectedDays.length ){
        this.errormes= true;
        this.userservice.toast.snackbarSucces('Please fill all the required fields');

       }
if(!this.selectedtemplate) {
  this.errormes =true
  return;
}
if(this.campaignname && this.campaignname.length > 500){
  console.log("!!!!!!!!!!!!!")
  this.submit = false;
    this.userservice.toast.snackbarSucces('Character limit exceeded (max: 500)');
    return
} 
else{
  this.submit = true
  this.errormes = false
        if(this.allnewfilename){
        let filepayload={
          "campaignType": 1,
          "id": 0,
          "name": this.campaignname,
          "status": 0,
          "summary": "This is sample campaign summary for the campaign 02- updated",
          "departmentId": parseInt(this.dep),
          "queueTypeId": this.messagetype || 0,
          "language": 1,
          "iid": 2,
          "uid": 1,
          "discardPastMessages": true,
          "isSeparateQueueRequired": true,
          "isChannelInformationInSource": true,
          "channels": [
              2
          ],
          "scheduleDate": moment(this.ChooseDate).format('YYYY/MM/DD'),
          "scheduleTime": this.ChooseTime,
          "startTime": this.calltime3,
          "endTime": this.calltimeto3,
          "batchCount": this.batchvalue,
          "dataFilters": {
            "subscription": true,
            "subscriptionValueType": 2,
            "subscriptionDatalevel": 2,
            "mandatoryOptOut": false,
            "dnd": false,
            "dndChannels": [
                3
            ]
        },
          "dataSources": [
              
              {
                  "type": 1,
                  "data": {
                      "fileName": this.allnewfilename
                  }
              }
          ],
          "daysToSend": this.selectedDays,
          "allowedTimeGap": {
              "hours": this.hours,
              "minutes": this.minutes
          },
          "fieldMappings": {
              "templateFieldMapping": [
                  {
                      "mappingColumn": "EMAILID",
                      "templateText": "FROMEMAIL123"
                  },
                  {
                      "mappingColumn": "ToEmail",
                      "templateText": "TOEMAIL123"
                  }
              ],
              "channelMapping": [
                  {
                      "type": "1",
                      "identifier": {
                          "mappingfield": "MSISDN"
                      },
                      "eventFieldMappings": [
                          {
                              "fieldname": "ShortCodes",
                              "mappingfield": "NBK-Notify"
                          }
                      ]
                  },
                  {
                      "type": "2",
                      "identifier": {
                          "mappingfield": "EMAILID"
                      },
                      "eventFieldMappings": [
                          {
                              "fieldname": "FromEmail",
                              "mappingfield": "FromEmail"
                          },
                          {
                              "fieldname": "CCEmailId",
                              "mappingfield": "CCEmailId"
                          },
                          {
                              "fieldname": "BCCEmailId",
                              "mappingfield": "BCCEmailId"
                          }
                      ]
                  }
              ]
          },
          "campaignData": {
              "sms": {
                  "messageTemplateId": this.selectedtemplate?.id ,
                  "shortCodeId": 5,
                  "mobileField": "MSISDN",
                  "connectorId": 0
              },
              "email": {
                  "messageTemplateId": this.selectedtemplate?.id ,
                  "useSMTP": true,
                  "smstpId": 1,
                  "toEmailIdField": "EMAILID",
                  "emailConnectorId": 2
              }
          },
          "referenceData": {
              "campaigndataReferenceId": this.dataReferenceId,
              "fileReferenceId": this.fileReferenceId,
              "uploadedData": {
                "schemaMapping": this.schemaMapping,
              "schema": this.schemadata,
              "campaignGroups": this.campaigngroupdata,
              "validCount": this.validCount,
              "totalCount": this.totalCount,
              "duplicateRecords": this.duplicateRecords,
              "invalidMobNumberCount": this.invalidMobNumberCount
              }
          }
      }
      this.userservice.createCampaign(filepayload).subscribe((res:any)=>{
        console.log(res,'resdssdds89')
        if(res.isSuccess == true){
          this.userservice.toast.snackbarSucces("Campaign Created Successfully!");
          this.route.navigate(['/campaign'])
        }

   }) 
        }
       else if(this.mannualentryvalue){
        if(!this.errorformanual){
          this.userservice.toast.snackbarSucces('Please upload the manual entry');
          return;
        }
          console.log("manualllllllll")
          let manualpayload = {
                "campaignType": 1,
                "id": 0,
                "name": this.campaignname,
                "status": 0,
                "summary": "This is sample campaign summary for the campaign 02- updated",
                "departmentId": parseInt(this.dep),
                "queueTypeId": this.messagetype || 0,
                "language": 1,
                "iid": 2,
                "uid": 1,
                "discardPastMessages": true,
                "isSeparateQueueRequired": true,
                "isChannelInformationInSource": true,
                "channels": [
                  2
                ],
                "scheduleDate": moment(this.ChooseDate).format('YYYY/MM/DD'),
                "scheduleTime": this.ChooseTime,
                "startTime": this.calltime3,
                "endTime": this.calltimeto3,
                "batchCount": this.batchvalue,
                "dataSources": [
                    {
                        "type": 2,
                         "data": {
                              "manualEntries": this.mannualentryvalue
                          }
                    }
            
                ],
                "daysToSend": this.selectedDays,
                "allowedTimeGap": {
                    "hours": this.hours,
                    "minutes": this.minutes
                },
                "fieldMappings": {
                    "templateFieldMapping": [
                        {
                            "mappingColumn": "EMAILID",
                            "templateText": "FROMEMAIL123"
                        },
                        {
                            "mappingColumn": "ToEmail",
                            "templateText": "TOEMAIL123"
                        }
                    ],
                    "channelMapping": [
                        {
                            "type": "1",
                            "identifier": {
                                "mappingfield": "MSISDN"
                            },
                            "eventFieldMappings": [
                                {
                                    "fieldname": "ShortCodes",
                                    "mappingfield": "NBK-Notify"
                                }
                            ]
                        },
                        {
                            "type": "2",
                            "identifier": {
                                "mappingfield": "EMAILID"
                            },
                            "eventFieldMappings": [
                                {
                                    "fieldname": "FromEmail",
                                    "mappingfield": "FromEmail"
                                },
                                {
                                    "fieldname": "CCEmailId",
                                    "mappingfield": "CCEmailId"
                                },
                                {
                                    "fieldname": "BCCEmailId",
                                    "mappingfield": "BCCEmailId"
                                }
                            ]
                        }
                    ]
                },
                "campaignData": {
                    "sms": {
                        "messageTemplateId":this.selectedtemplate?.id ,
                        "shortCodeId": 5,
                        "mobileField": "MSISDN",
                        "connectorId": 0
                    },
                    "email": {
                        "messageTemplateId":this.selectedtemplate?.id ,
                        "useSMTP": true,
                        "smstpId": 1,
                        "toEmailIdField": "EMAILID",
                        "emailConnectorId": 2
                    }
                },
                "referenceData": {
                    "campaigndataReferenceId": this.manualdataReferenceId,
                    "fileReferenceId": "",
                    "uploadedData": {
              "schemaMapping": this.manualschemaMapping,
            "schema": this.manualschemadata,
            "campaignGroups": this.manualcampaigngroupdata,
            "validCount": this.manualvalidCount,
            "duplicateRecords": this.manualduplicateRecords,
            "invalidMobNumberCount": this.manualinvalidMobNumberCount
            }
                }
            }
            this.userservice.createCampaign(manualpayload).subscribe((res:any)=>{
              console.log(res,'resdssdds89')
              if(res.isSuccess == true){
                this.userservice.toast.snackbarSucces("Campaign Created Successfully!");
                this.route.navigate(['/campaign'])
              }
            })
        }
       else if(this.group.length){
        if(!this.errorforgroup){
          this.userservice.toast.snackbarSucces('Please upload the selected group');
          return;
        }
          console.log("grpupppppppp")
          let grouppayload = {
                "campaignType": 1,
                "id": 0,
                "name": this.campaignname,
                "status": 0,
                "summary": "This is sample campaign summary for the campaign 02- updated",
                "departmentId": parseInt(this.dep),
                "queueTypeId": this.messagetype || 0,
                "language": 1,
                "iid": 2,
                "uid": 1,
                "discardPastMessages": true,
                "isSeparateQueueRequired": true,
                "isChannelInformationInSource": true,
                "channels": [
                  2
                ],
                "scheduleDate": moment(this.ChooseDate).format('YYYY/MM/DD'),
                "scheduleTime": this.ChooseTime,
                "startTime": this.calltime3,
                "endTime": this.calltimeto3,
                "batchCount": this.batchvalue,
                "dataSources": [
                    {
                        "type": 3,
                        "data": {
                            "groupIds": this.group
                        }
                    }
            
                ],
                "daysToSend": this.selectedDays,
                "allowedTimeGap": {
                    "hours": this.hours,
                    "minutes": this.minutes
                },
                "fieldMappings": {
                    "templateFieldMapping": [
                        {
                            "mappingColumn": "EMAILID",
                            "templateText": "FROMEMAIL123"
                        },
                        {
                            "mappingColumn": "ToEmail",
                            "templateText": "TOEMAIL123"
                        }
                    ],
                    "channelMapping": [
                        {
                            "type": "1",
                            "identifier": {
                                "mappingfield": "MSISDN"
                            },
                            "eventFieldMappings": [
                                {
                                    "fieldname": "ShortCodes",
                                    "mappingfield": "NBK-Notify"
                                }
                            ]
                        },
                        {
                            "type": "2",
                            "identifier": {
                                "mappingfield": "EMAILID"
                            },
                            "eventFieldMappings": [
                                {
                                    "fieldname": "FromEmail",
                                    "mappingfield": "FromEmail"
                                },
                                {
                                    "fieldname": "CCEmailId",
                                    "mappingfield": "CCEmailId"
                                },
                                {
                                    "fieldname": "BCCEmailId",
                                    "mappingfield": "BCCEmailId"
                                }
                            ]
                        }
                    ]
                },
                "campaignData": {
                    "sms": {
                        "messageTemplateId":this.selectedtemplate?.id ,
                        "shortCodeId": 5,
                        "mobileField": "MSISDN",
                        "connectorId": 0
                    },
                    "email": {
                        "messageTemplateId":this.selectedtemplate?.id ,
                        "useSMTP": true,
                        "smstpId": 1,
                        "toEmailIdField": "EMAILID",
                        "emailConnectorId": 2
                    }
                },
                "referenceData": {
                    "campaigndataReferenceId": this.groupdataReferenceId,
                    "fileReferenceId": "",
                    "uploadedData":  {
              "schemaMapping": this.groupschemaMapping,
            "schema": this.groupschemadata,
            "campaignGroups": this.groupcampaigngroupdata,
            "validCount": this.groupvalidCount,
            "totalCount": this.groupTotalCount,
            "duplicateRecords": this.groupduplicateRecords,
            "invalidMobNumberCount": this.groupinvalidMobNumberCount
            }
                }
            }
            this.userservice.createCampaign(grouppayload).subscribe((res:any)=>{
              console.log(res,'resdssdds89')
              if(res.isSuccess == true){
                this.userservice.toast.snackbarSucces("Campaign Created Successfully!");
                this.route.navigate(['/campaign'])
              }
      
         })
        }else{
    this.userservice.toast.snackbarSucces('Please select atleast one manual file or group or excel file');
          
        }
       
      }
      }
    }

