import {
    ActionReducer,
    ActionReducerMap,
    MetaReducer
  } from '@ngrx/store';
  import { localStorageSync } from 'ngrx-store-localstorage';
  import { environment } from '../../environments/environment';
  import * as usernameToUserInformationReducers from './reducers/auth/auth.reducers';
  import * as GetUsersReducers from './reducers/user-management/GetUsers.reducers';
  import * as getRolesReducers from './reducers/user-management/getRoles.reducers';
  import * as getGroupsReducers from './reducers/user-management/getGroups.reducers';
  import * as GetMasterpermissionReducers from './reducers/user-management/GetMasterpermission.reducers';
  import * as sideMenuReducers from './reducers/side-menu/sideMenu.reducers';
  import * as getDepartmentsReducers from './reducers/user-management/getDepartments.reducers';
  import * as GetMessageTemplatesReducers from './reducers/dictionary/GetMessageTemplates.reducers';
  import * as GetMessageTemplateReducers from './reducers/dictionary/GetMessageTemplate.reducers';
  import * as AddMessageTemplateReducers from './reducers/dictionary/AddMessageTemplate.reducers'
  import * as UpdateMessageTemplateReducers from './reducers/dictionary/UpdateMessageTemplate.reducers'
  import * as IsTemplateAssociatedToServiceReducers from './reducers/dictionary/IsTemplateAssociatedToService.reducers'
  import * as GetMessageTemplateTypesReducers from './reducers/dictionary/GetMessageTemplateTypes.reducers';
  import * as DeleteMessageTemplateReducers from './reducers/dictionary/DeleteMessageTemplate.reducers'
  import * as ApproveRejectMessageTemplateByWorkFlowReducers from './reducers/dictionary/ApproveRejectMessageTemplateByWorkFlow.reducers'
  import * as UpdateMessageTemplateWorkflowReducers from './reducers/dictionary/UpdateMessageTemplateWorkflow.reducers'
  import * as UpdateMessageAssetPathXmlReducers from './reducers/dictionary/UpdateMessageAssetPathXml.reducers'
  import * as DeactivateUserReducers from './reducers/user-management/DeactivateUser.reducers'
  import * as GetDepartmentByIdReducers from './reducers/user-management/GetDepartmentById.reducers'
  
  export interface State {

    usernameToUserInformation: usernameToUserInformationReducers.State;
    GetUsers: GetUsersReducers.State;
    getRoles: getRolesReducers.State;
    getGroups: getGroupsReducers.State;
    GetMasterpermission: GetMasterpermissionReducers.State;
    sideMenu: sideMenuReducers.State;
    getDepartments: getDepartmentsReducers.State;
    GetMessageTemplates: GetMessageTemplatesReducers.State;
    GetMessageTemplate: GetMessageTemplateReducers.State;
    AddMessageTemplate: AddMessageTemplateReducers.State;
    UpdateMessageTemplate: UpdateMessageTemplateReducers.State;
    IsTemplateAssociatedToService: IsTemplateAssociatedToServiceReducers.State;
    GetMessageTemplateTypes: GetMessageTemplateTypesReducers.State;
    DeleteMessageTemplate: DeleteMessageTemplateReducers.State;
    ApproveRejectMessageTemplateByWorkFlow: ApproveRejectMessageTemplateByWorkFlowReducers.State;
    UpdateMessageTemplateWorkflow: UpdateMessageTemplateWorkflowReducers.State
    UpdateMessageAssetPathXml: UpdateMessageAssetPathXmlReducers.State,
    DeactivateUser:DeactivateUserReducers.State,
    GetDepartmentById: GetDepartmentByIdReducers.State
  }

  export const reducers: ActionReducerMap<State> = {
    usernameToUserInformation: usernameToUserInformationReducers.reducer,
    GetUsers : GetUsersReducers.reducer,
    getRoles : getRolesReducers.reducer,
    getGroups : getGroupsReducers.reducer,
    GetMasterpermission : GetMasterpermissionReducers.reducer,
    sideMenu : sideMenuReducers.reducer,
    getDepartments : getDepartmentsReducers.reducer,
    GetMessageTemplates:GetMessageTemplatesReducers.reducer,
    GetMessageTemplate:GetMessageTemplateReducers.reducer,
    AddMessageTemplate:AddMessageTemplateReducers.reducer,
    UpdateMessageTemplate: UpdateMessageTemplateReducers.reducer,
    IsTemplateAssociatedToService: IsTemplateAssociatedToServiceReducers.reducer,
    GetMessageTemplateTypes: GetMessageTemplateTypesReducers.reducer,
    DeleteMessageTemplate: DeleteMessageTemplateReducers.reducer,
    ApproveRejectMessageTemplateByWorkFlow: ApproveRejectMessageTemplateByWorkFlowReducers.reducer,
    UpdateMessageTemplateWorkflow: UpdateMessageTemplateWorkflowReducers.reducer,
    UpdateMessageAssetPathXml: UpdateMessageAssetPathXmlReducers.reducer,
    DeactivateUser:DeactivateUserReducers.reducer,
    GetDepartmentById: GetDepartmentByIdReducers.reducer
  };

  const reducerKeys = ['usernameToUserInformation'];
  export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({keys: reducerKeys})(reducer);
  }

  export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
    return function(state, action) {

      return reducer(state, action);
    };
  }


  export const metaReducers: MetaReducer<State>[] = !environment.production ? [debug, localStorageSyncReducer] : [localStorageSyncReducer];

  export * from './selectors/index';




