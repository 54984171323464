import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { retry, catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { ToastComponent } from "../shared/toast/toast.component";
import { MatDialog } from "@angular/material/dialog";
import { UsersService } from "../modules/usermanagement/service/users/users.service";
export interface ErrorResponse {
  errorCode: string;
  errorMsg: string;
}
interface ApiResponse {
  result:  Object | null;
  responseMessage: string;
  isSuccess: boolean;
  error?: ErrorResponse | null;
  status: String;
  url: String;
}

export interface LoginResponse {
  result:  Object | null;
  responseMessage: string;
  isSuccess: boolean;
  error: ErrorResponse | null;
}
export interface TemplateResponse {
  result:  Object | null;
  responseMessage: string;
  isSuccess: boolean;
  error: ErrorResponse | null;
}
export interface GroupResponse {
  result:   Object | null;
  responseMessage: string;
  isSuccess: boolean;
  error: ErrorResponse | null;
}

@Injectable({
  providedIn: "root",
})


export class HttpClientInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private UserService: UsersService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retry(0),
      catchError((error: HttpErrorResponse | ApiResponse) => {
        if (error && error.status == 401 && error.url?.includes("login")) {
          // debugger
          this.UserService.toast.snackbarSucces(error.error.responseMessage);
          window.localStorage.clear();
          this.UserService.triggerDataSourceError();
          this.router.navigate(["/login"]);
        } else if (error && error.status == 401) {
          // this.UserService.toast.snackbarSucces(error?.error?.error);
          this.UserService.toast.snackbarSucces('Session Expired');
          this.dialog.closeAll()
          window.localStorage.clear();
          this.router.navigate(["/login"]);
        }
    
      else  if (error && error.status === 404) {
            const response: ApiResponse = error.error;
            this.UserService.toast.snackbarSucces(response.responseMessage);
            this.UserService.triggerDataSourceError();
        }
       else if (error && error.status == 400) {
          if (error?.error) {
            this.UserService.toast.snackbarSucces(error.error.responseMessage);
          }
          if ( error?.error && error?.url?.includes("Campaign/v1/create") && error.error.errors.BatchCount[0]) {
            this.UserService.toast.snackbarSucces(error.error.errors.BatchCount[0]);
          }
          if (error?.error && (error.url?.includes("Group/v1/groups") ||error.url?.includes("MessageTemplate/v1/templates") )) {
            this.UserService.triggerDataSourceError();
          }
        
          const errorResponse = error.error.errors;
          const errorMessage =
          errorResponse["ReferenceData.CampaignDataReferenceId"]?.[0];
          const errorMessage1 = errorResponse["CampaignName"]?.[0];
          if (error?.error && error?.url?.includes("Campaign/v1/create") && errorMessage) {
            this.UserService.toast.snackbarSucces(errorMessage);
          }
          if (error?.error && error.url?.includes("Campaign/v1/create") && errorMessage1) {
            this.UserService.toast.snackbarSucces(errorMessage1);
          }
        }
        return throwError(() => {
          Error("api error");
        });
      })
    );
  }
}
