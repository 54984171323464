import { Component, ViewChild } from '@angular/core';
import { ProfileComponent } from '../../../commonModule/components/profile/profile.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GroupAddComponent } from '../group-add/group-add.component';
import { DeletegroupComponent } from '../deletegroup/deletegroup.component';
import { GroupcopyComponent } from '../groupcopy/groupcopy.component';
import { Store } from '@ngrx/store';
import { UsersService } from '../../../usermanagement/service/users/users.service';
import * as actions from 'src/app/app-state/actions';
import * as fromRoot from 'src/app/app-state';
import * as moment from 'moment';
import { Subject, takeUntil } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DatePipe } from '@angular/common';

export interface PeriodicElement {
  groupName:string,
    description:string,
    channel:string,
    noOfMember:string,
    createdBy:string,
    createdDate:string,
    modifiedBy:string,
    modifiedDate:string,

}





@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss'],

})

export class GroupsComponent {
  SortVal:string ='first';
  @ViewChild(MatPaginator)

  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!:MatSort;
  userSearch:string=''
  pageSize:number = 10;
  pageIndex: number = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  displayedColumns: string[] = [
    'name',
    'description',
    'channels',
    'count',
    'created_By_Name',
    'created_On',
    'modified_By_Name',
    'modified_On',
    'action',
  ];
  dataSource =new MatTableDataSource<object>([]);


  destroy$: Subject<boolean> = new Subject<boolean>();
  public moment = moment;
  groupslists: Array<any>=[];
  channels:Array<any>=[];
  selectchannels: any = 0;
    loadingData: boolean = true;
  constructor(private store:Store, public dialog: MatDialog,private users:UsersService, private datePipe: DatePipe){
  this.users.Getchannels().subscribe((val:object|any)=>{
    this.channels=val?.result
    console.log(this.channels,'this.channels')
  })
  this.selectchannel(this.selectchannels)



  }

 
  selectchannel(el:string | Object|any){
    if(el.value){
    this.selectchannels = el.value || 0;
    console.log(this.selectchannels, 'yyyyyyy')
    }
    else{
      this.selectchannels =  0;
    }

    
    console.log(this.selectchannels,'dssdsd98',el.value)
    let payload = {
      "channelId": this.selectchannels
    }
    this.loadingData= true;
   this.users.getGroups(payload).subscribe((data:any)=> {
      this.dataSource.data=[]
      this.dataSource.data= data?.result?.tenantGroups
      console.log(this.dataSource.data,'da5434432')
     this.groupslists = data?.result?.tenantGroups
     this.dataSource.sort= this.sort;
     this.dataSource.paginator = this.paginator
     this.applyFilters()
     this.loadingData=false;

     })
  console.log(this.selectchannels,'sasadas79')

     }

     applyFilters(): void {
      let filteredData = this.groupslists;
    
      if (this.userSearch) {
        filteredData = filteredData.filter((val: any) =>
          // console.log(val,"vsl")
          val?.name?.toLowerCase().trim().includes(this.userSearch.toLowerCase().trim())

      );
      console.log(filteredData,"data12")
      }
    
      if (this.selectchannels) {
        filteredData = filteredData.filter((val: any) =>
          // console.log(val.channel_Type_Id,"id",this.selectchannels,val.channel_Type_Id.includes(this.selectchannels))
          val.channel_Type_Id.includes(this.selectchannels)
      );
      }
    
      this.dataSource.data = filteredData;
    }
    
    SearchUser(event:string|any): void{
      // this.userSearch = event.target.value || '';
    this.applyFilters()
  }

  usericonDialog() {
    this.dialog.open(ProfileComponent,{
      panelClass:['common-modalbox'],
    })
    
      }
  
      addUserDialog(val:string){
        const dialogref= this.dialog.open(GroupAddComponent, {
          data:{
            val:val,
            
          },
          panelClass: ['common-modalbox-add']})
          dialogref.afterClosed().subscribe((data: any) => {  
            console.log(data,"sd")
            // let payload = {
            //   "channelId": 1
            // }
            // this.store.dispatch(
            //   actions.getGroups({payload}))
          this.selectchannel(this.selectchannels)

               
        })
      }
      editUserDialog(val:string,id:number){
        const dialogref= this.dialog.open(GroupAddComponent, {
          data:{
            val:val,
            id:id
          },
          panelClass: ['common-modalbox-add']})
          dialogref.afterClosed().subscribe((data: any) => { 
            console.log(data,"dd")
            this.selectchannels=''
            setTimeout(()=>{

              this.selectchannel(this.selectchannels)
            }, 100)
 
        })
      }

      View(val:string,id:number){
        this.dialog.open(GroupAddComponent, {
          panelClass: ['common-modalbox-add'],
          data:{
            val:val,
            id:id
          },
    
        })

      }
      copydialog(){
        this.dialog.open(GroupcopyComponent, {
          panelClass: ['common-modalbox-add'],
    
        })
      }

      deletegroup(groupid:number){
       return
        this.dialog.open(DeletegroupComponent, {
          data:{
            groupid:groupid
          },
          panelClass:['delete-popup-box'],
    
        })

      }
 
   ngOnInit(): void {
    
    this.users.dataSourceErrorSubject.subscribe((getres:any)=>{
      console.log(getres,'uuuuuu')
      this.users.toast.snackbarSucces("Please provide a valid channel id. the valid channel IDs are: 0. All,   1. SMS,   2. Email,   3. Push   4. Inbox   5. WhatsApp");
      this.dataSource.data = []
      this.loadingData = false;

   
 })

   }
    resetSorting() {
      if (this.sort) {
        this.sort.active = "";
        this.sort.direction = "";
      }
      this.dataSource.data = [...this.dataSource.data];
    }

    Sort(e:string){
      this.dataSource.data= []
      this.resetSorting();

          if(e == 'first'){
            this.dataSource.data = this.groupslists
          }else{
            this.dataSource.data = [...this.groupslists].reverse()
  
          }
  }
  Reset() {
    this.userSearch = "";
    this.selectchannels = 0;
    this.selectchannel(this.selectchannels)
  }

}



