import { createAction, props } from '@ngrx/store';

 
export const GetMessageTemplates = createAction(
  '[GET_MessageTemplates] Get MessageTemplates',
  props<any>()
);

export const GetMessageTemplatesSuccess = createAction(
'[GET_MessageTemplates] Get MessageTemplates Success',
  props<any>()
);

export const GetMessageTemplatesFailure = createAction(
'[GET_MessageTemplates] Get MessageTemplates Failure',
  props<any>()
);

export const GetMessageTemplate = createAction(
  '[GET_MessageTemplate] Get MessageTemplate',
  props<any>()
);

export const GetMessageTemplateSuccess = createAction(
'[GET_MessageTemplate] Get MessageTemplate Success',
  props<any>()
);

export const GetMessageTemplateFailure = createAction(
'[GET_MessageTemplate] Get MessageTemplate Failure',
  props<any>()
);

