import { createAction, props } from '@ngrx/store';

 
export const getMessageTemplateTypes = createAction(
  '[Get_MessageTemplateTypes] Get MessageTemplateTypes'
);

export const getMessageTemplateTypesSuccess = createAction(
'[Get_MessageTemplateTypes] Get MessageTemplateTypes Success',
  props<any>()
);

export const getMessageTemplateTypesFailure = createAction(
'[Get_MessageTemplateTypes] Get MessageTemplateTypes Failure',
  props<any>()
);