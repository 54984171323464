import { AuthguardServiceService } from './services/authguard-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastComponent } from './shared/toast/toast.component';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import { CKEditorModule } from 'ckeditor4-angular';
import { NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { MatTableModule } from '@angular/material/table'  
import { MatSidenavModule } from '@angular/material/sidenav';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TemplateComponent } from './modules/dictionary/components/template/template.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import { NgxPaginationModule } from 'ngx-pagination';


import {
  MatInputModule,
} from '@angular/material/input';
import { AppStateModule } from './app-state/app-state.module';
import {MatSelectModule} from '@angular/material/select';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { UserComponent } from './modules/usermanagement/components/user/user.component';
import { RoleListComponent } from './modules/usermanagement/components/role-list/role-list.component';
import { RoleAddComponent } from './modules/usermanagement/components/role-add/role-add.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CampaignsComponent } from './modules/campaign/components/campaigns/campaigns.component';
import { CampaignboxComponent } from './modules/campaign/components/campaignbox/campaignbox.component';
import { HeaderComponent } from './modules/commonModule/components/header/header.component';
import { SelecttemplateComponent } from './selecttemplate/selecttemplate.component';
import { MessageReportComponent } from './message-report/message-report.component';
import { MobileReportComponent } from './modules/commonModule/components/mobile-report/mobile-report.component';
import { CampaignReportComponent } from './modules/campaign/components/campaign-report/campaign-report.component';
import { SummaryReportComponent } from './summary-report/summary-report.component';

import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';

import { CreateCampaignComponent } from './modules/campaign/components/create-campaign/create-campaign.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { MatTabsModule } from '@angular/material/tabs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxEditorModule } from 'ngx-editor';
import { ProfileComponent } from './modules/commonModule/components/profile/profile.component';
import { SelectTemplatePopupComponent } from './select-template-popup/select-template-popup.component';
import { AdminpopupComponent } from './adminpopup/adminpopup.component';
import { HttpClientInterceptor } from './interceptors/http-client.interceptor';
import { LinepopupComponent } from './linepopup/linepopup.component';
import { RoleviewComponent } from './modules/usermanagement/components/roleview/roleview.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { PagenotfoundComponent } from './modules/commonModule/components/pagenotfound/pagenotfound.component';
import { CommonFileModule } from './modules/commonModule/common-module';
import { MatSortModule } from '@angular/material/sort';
import { DatePipe } from '@angular/common';
import { PreviewTemplateComponent } from './modules/campaign/components/preview-template/preview-template.component';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  declarations: [
    AppComponent,
    TemplateComponent,
    UserComponent,
    RoleListComponent,
    
    RoleAddComponent,

    CampaignsComponent,
    CampaignboxComponent,
    HeaderComponent,
    SelecttemplateComponent,
    MessageReportComponent,
    MobileReportComponent,
    CampaignReportComponent,
    SummaryReportComponent,
    CreateCampaignComponent,
    ProfileComponent,
    SelectTemplatePopupComponent,
    AdminpopupComponent,
    LinepopupComponent,
    RoleviewComponent,
    PagenotfoundComponent,
    PreviewTemplateComponent,
    
  
    
  ],
  imports: [
    MatPaginatorModule,
    MatTableModule,
    MatSelectModule,
    CommonFileModule,
    NgxMaterialTimepickerModule,
    NgxMatTimepickerModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    CKEditorModule,
    BrowserModule,
    AppStateModule,
    AppRoutingModule,
    MatInputModule,
    MatCheckboxModule,
    CdkAccordionModule,
    MatExpansionModule,
    MatSidenavModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatNativeDateModule,
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    MatTabsModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    NgxEditorModule,
    
    NgxPaginationModule,
    MatSortModule,
    MatFormFieldModule
    
    ],
  providers: [DatePipe,ToastComponent,MatSnackBar,AuthguardServiceService,    {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpClientInterceptor,
    multi: true
  },
],
  bootstrap: [AppComponent],
  
})
export class AppModule { }
