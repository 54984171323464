<div class="header-wrapper">
    <span>{{data?.item?.is_Active ? 'Deactivate' : 'Activate'}}</span>
    <button mat-icon-button class="btn close-button" [mat-dialog-close]="true">
        <i class="fa fa-close"></i>
      </button>
</div>
  
<div mat-dialog-content>
    <div class="container-fluid">
        <div class="row mb-3">
            <div class="col-12">
                <i class="fa fa-exclamation-triangle"></i>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-12" *ngIf="data?.id && data?.item?.is_Active">
                <p>Are you sure that you want to deactivate this user?</p>
            </div>
            <div class="col-12" *ngIf="data?.id && !data?.item?.is_Active">
                <p>Are you sure that you want to activate this user?</p>
            </div>
            <div class="col-12" *ngIf="data?.templateID">
                <p>Are you sure that you want to delete this template?</p>
            </div>
            <div class="col-12" *ngIf="data?.roleid">
                <p>Are you sure that you want to delete this role?</p>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <button class="btn btn-theme" *ngIf="data?.id && !data?.item?.is_Active" (click)="Activate()" [(ngModel)]="isActive">Activate</button>
<button class="btn btn-theme" *ngIf="data?.id && data?.item?.is_Active" (click)="Delete()">Deactivate</button>
<button class="btn btn-theme" *ngIf="data?.templateID" (click)="Delete()">Delete</button>
<button class="btn btn-theme" *ngIf="data?.roleid" (click)="Delete1()">Delete</button>
<button class="btn btn-cancle" mat-dialog-close>Cancel</button>
</div>