import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as reducer from '../../reducers/dictionary/GetMessageTemplate.reducers'

const state = createFeatureSelector<reducer.State>('GetMessageTemplate');


export const selectLoadingGetMessageTemplate = createSelector(
  state,
  state => state.isLoading
);

export const selectStatesGetMessageTemplate= createSelector(
  state,
  state => state
);

export const selectStatesGetMessageTemplateBody= createSelector(
  state,
  state => state.body
);

export const selectStatesGetMessageTemplateData= createSelector(
  state,
  state => state.GetMessageTemplate
);
