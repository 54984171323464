import { createAction, props } from '@ngrx/store';

 
export const UpdateMessageTemplateWorkflow = createAction(
  '[Update_MessageTemplateWorkflow] Update_MessageTemplateWorkflow',
  props<any>()
);

export const UpdateMessageTemplateWorkflowSuccess = createAction(
'[Update_MessageTemplateWorkflow] Update_MessageTemplateWorkflow Success',
  props<any>()
);

export const UpdateMessageTemplateWorkflowFailure = createAction(
'[Update_MessageTemplateWorkflow] Update_MessageTemplateWorkflow Failure',
  props<any>()
);