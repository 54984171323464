import { Component } from '@angular/core';

@Component({
  selector: 'app-groupcopy',
  templateUrl: './groupcopy.component.html',
  styleUrls: ['./groupcopy.component.scss']
})
export class GroupcopyComponent {

}
