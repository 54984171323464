import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-departmentview',
  templateUrl: './departmentview.component.html',
  styleUrls: ['./departmentview.component.scss']
})
export class DepartmentviewComponent {
  checkbox:string=''
  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any){
      this.checkbox = this.data?.showdata?.is_Active

  }
}
