import { UserEditComponent } from '../user-edit/user-edit.component';
import { Component,  ViewChild } from '@angular/core';
import { UserAddComponent } from '../user-add/user-add.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ProfileComponent } from '../../../commonModule/components/profile/profile.component';
import { AuthService } from 'src/app/modules/auth/service/auth/auth.service';
import * as actions from 'src/app/app-state/actions';
import * as fromRoot from 'src/app/app-state';
import * as moment from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { HttpClient, } from '@angular/common/http';
import { AdminpopupComponent } from '../../../../adminpopup/adminpopup.component';
import { DeletePopupComponent } from '../../../dictionary/components/delete-popup/delete-popup.component';
import { UserViewComponent } from '../user-view/user-view.component';
import {  GetUsersResponse, MyRequest } from 'src/app/app-state/types';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { formatDate } from '@angular/common';
export interface DepRolenterface {
  departmentId : number,
  departmentName: string
}
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})

export class UserComponent {
  SortVal:string='first'
  dataSource = new MatTableDataSource<object>([]);
  displayedColumns: string[] = [
    'display_Name',
    'login_Name',
    'roleName',
    'departmentName',
    'created_By_Name',
    'created_On',
    'modified_By_Name',
    'modified_On',
    'login_On',
    'action',
  ];
  
  public moment = moment;

  destroy$: Subject<boolean> = new Subject<boolean>();
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  pageSize:number = 10;
  pageIndex: number = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  userlists: MyRequest[] = [];
  userSearch:string = ''
  // datevalue:Array<MyRequest> = []
  start:string ='';
  end:string =''
  status: string='';
  loadingData: boolean = true;
  maxDate: string;
  constructor(private readonly store: Store,private AuthService:AuthService, public http: HttpClient,private router:Router, 
    public dialog: MatDialog) {
      const currentDate = new Date();
    this.maxDate = formatDate(currentDate, 'yyyy-MM-dd', 'en');
      this.store
      .select(fromRoot.selectLoadingGetUsers)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data:boolean) => {
        this.loadingData = data;
         })
    this.getUsersLists();
    
  }
  getUsersLists(){
    this.store.dispatch(
      actions.GetUsers({}))
     this.store
     .select(fromRoot.selectStatesGetUsersData)
     .pipe(takeUntil(this.destroy$))
     .subscribe((data:object|any) => {
      console.log(data,'pppp')
    
      this.userlists = data.result?.allUsers
      this.dataSource.data = data?.result?.allUsers
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
        })
        }
  closeDatefilter(){
    // if(this.userSearch){
    //    this.start = ''
    //    this.end = ''
    //    this.dataSource.data = this.searchlist
    // }else{
      this.store
      .select(fromRoot.selectStatesGetUsersData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
      // .subscribe((data: GetUsersResponse) => {
        console.log(data,'dssdsd78')
       this.dataSource.data = data?.result?.allUsers
       this.start = ''
       this.end = ''
     })
    // }
   

  }
    //   SearchUser(event:string): void {
    //     // if(this.start && this.end){
    //     //   console.log('11111111')
    //     //   this.SortVal ='first'
    //     //   if(event){
    //     //     this.dataSource.data = this.datevalue.filter((val:MyRequest) =>
    //     //       (
    //     //       val.display_Name.toLowerCase().trim().includes(event.toLowerCase().trim())
    //     //       )
    //     //     ) 
    //     //     this.searchlist = this.dataSource.data
  
    //     //   }else {
    //     //     this.dataSource.data = this.userlists
    //     //   }
    //     // }else{
    //     //   console.log('2222222222')
    //       this.start = ''
    //       this.end = ''
    //       this.SortVal ='first'
    //       if(event){
    //         this.dataSource.data = this.userlists.filter((val:MyRequest) =>
    //           (
    //           val.display_Name.toLowerCase().trim().includes(event.toLowerCase().trim())
    //           )
    //         ) 
    //         // this.searchlist = this.dataSource.data
  
    //       }else {
    //         this.dataSource.data = this.userlists
    //       }
    //     // }
       
    // }

    applyFilters(): void {
      let filteredData = this.userlists;
    
      if (this.userSearch) {
        filteredData = filteredData.filter(
          (val: MyRequest) =>
            val.display_Name.toLowerCase().trim().includes(this.userSearch.toLowerCase().trim())
        );
      }
    
      if (this.start && this.end) {
        filteredData = filteredData.filter((arr: MyRequest) =>
          moment(moment(arr?.created_On).format('MM-DD-YYYY')).isBetween(
            moment(this.start).format('MM-DD-YYYY'),
            moment(this.end).format('MM-DD-YYYY'),
            undefined,
            '[]'
          )
        );
      }
    
      if (this.SortVal === 'last') {
        filteredData = [...filteredData].reverse();
      }
    
      this.dataSource.data = filteredData;
    }
    SearchUser(event: string): void {
      this.applyFilters();
    }

    dateRangeChange(eve: string, startend: string): void {
      if (startend === 'start') {
        this.start = eve;
      }
      if (startend === 'end') {
        this.end = eve;
      }
      this.applyFilters();
    }
    
    Sort(e: string): void {
      this.SortVal = e;
      this.applyFilters();
    }
  usericonDialog() {
    this.dialog.open(ProfileComponent,{
      panelClass:['common-modalbox'],
    })
    
  }
  DeactivateUser(id:number,item:Object){
      const dialogref= this.dialog.open(DeletePopupComponent,{
        data:{
          id:id,
          item:item
        },
        panelClass:['delete-popup-box'],
      })
      dialogref.afterClosed().subscribe((data: string) => {
        this.getUsersLists();
    })
}

  deleteiconDialog(){
    this.dialog.open(DeletePopupComponent,{
      panelClass:['common-modalbox'],
    })

  }




  addUserDialog(){
    
   const dialogref = this.dialog.open(UserAddComponent, {
    
      panelClass: ['common-modalbox'],
    })
    dialogref.afterClosed().subscribe((data: string) => {
    this.getUsersLists();


    })
  }

  adminpopupDialog(flag:string,lists:DepRolenterface){
    this.dialog.open(AdminpopupComponent,{
      data:{
        flag:flag,
        role:lists,
        department:lists

      }
    })
  }
  userview(id:number){
    this.dialog.open(UserViewComponent,{
      data:{
        id:id
      },
      panelClass: ['common-modalbox'],

    })

  }



  EditUserDialog(id:number){
    
  const dialogref =this.dialog.open(UserEditComponent, {
      data:{
        id:id,
      },
      panelClass: ['user-edit-box'],
    })
    dialogref.afterClosed().subscribe((data: string) => {
      this.getUsersLists();
  
  
      })

  }
  


  changeStatus(){
    let statarr:Array<MyRequest>=[]
    this.userlists.forEach((item:MyRequest)=>{
      if(item.is_Active == this.status){
        statarr.push(item)
        this.dataSource.data = statarr     
      }
    })
  }

    

  

//   dateRangeChange(eve:string,startend:string){
//     // if(this.userSearch){
//     //   if(startend == 'start'){
//     //     this.start=eve;
//     //   }
//     //   if(startend == 'end'){
//     //     this.end=eve;
//     //   }
    
//     //   // datevalue=this.userlists
  
//     //   if(this.start != null && this.end != null){
//     //     this.datevalue= this.searchlist.filter((arr:MyRequest)=>{
  
//     //     return  ((this.start && this.end ) ? moment(moment(arr?.created_On).format('MM-DD-YYYY')).isBetween(moment(this.start).format('MM-DD-YYYY'), moment(this.end).format('MM-DD-YYYY'), undefined, '[]') : true)
   
//     //     })
//     //     this.dataSource.data = this.datevalue
//     //   }
//     // }else{
//       if(startend == 'start'){
//         this.start=eve;
//       }
//       if(startend == 'end'){
//         this.end=eve;
//       }
// this.userSearch = ''
//       this.SortVal ='first'
  
//     let datevalue:Array<MyRequest> = []
//       // datevalue=this.userlists
  
//       if(this.start != null && this.end != null){
//         datevalue= this.userlists.filter((arr:MyRequest)=>{
  
//         return  ((this.start && this.end ) ? moment(moment(arr?.created_On).format('MM-DD-YYYY')).isBetween(moment(this.start).format('MM-DD-YYYY'), moment(this.end).format('MM-DD-YYYY'), undefined, '[]') : true)
   
//         })
//         this.dataSource.data = datevalue
//             }
  
//   }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

//   Sort(e:string){
    
//     // if(this.userSearch){
//       //   if(e == 'first'){
//         //     this.dataSource.data=this.searchlist
//     //   }else if(e == 'last') {
//         //     this.dataSource.data=[...this.searchlist].reverse()
//     //   }
//     // }else{
//       this.dataSource.data = []
//       this.userSearch = ''
//       this.resetSorting();
//       if(e == 'first'){
//         this.dataSource.data=this.userlists
//       }else if(e == 'last') {
//         this.dataSource.data=[...this.userlists].reverse()
//       }
//     // }

 
// }
Reset() {
  this.userSearch = "";
  this.start = '';
  this.end = '';
  this.SortVal ='first'
  this.applyFilters();
  // this.dataSource.data= this.userlists 
}

resetSorting() {
  if (this.sort) {
    this.sort.active = "";
    this.sort.direction = "";
  }
  this.dataSource.data = [...this.dataSource.data];
}


  
  
}

