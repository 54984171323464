<div class="info-fill-tab">
    <div class="headings inner">
        <span><i class="fa fa-question-circle-o me-1"></i>Help</span>
        <span><i class="fa fa-youtube-play" aria-hidden="true"></i></span>
    </div>
    <div class="inner-body-content">
        <div class="wrapper">
            <div class="block-wrap">
                <div class="count">1</div>
                <div class="title">
                    Enter Details with Recipients
                </div>
                <div class="details">
                    Given a name to the campaign, select sender ID and type.
                    To add recipient- You can select a contact group, upload
                    xls/csv file or add mobiles manually.
                </div>
            </div>
            <div class="block-wrap">
                <div class="count">2</div>
                <div class="title">
                    Create Content
                </div>
                <div class="details">
                    Create message text in English/Arabic by entering text here or selecting a template.
                    Enter ## to use variables in text
                </div>
            </div>
            <div class="block-wrap">
                <div class="count">3</div>
                <div class="title">
                    Schedule
                </div>
                <div class="details">
                    Select start date and time with an option to split message sending process in batches
                </div>
            </div>
            <div class="block-wrap">
                <div class="count">4</div>
                <div class="title">
                    Confirm
                </div>
                <div class="details">
                    View summary of details added and confirm to save the campaign
                </div>
            </div>
        </div>
    </div>
</div>