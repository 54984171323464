<div class="adminpopup" *ngIf="flag == 'role'">
  
    <i class="fa fa-time cross"  aria-hidden="true"></i>
    <ul class="box" >
       
        <li class="admin" *ngFor="let rolelist of deprole">
            <a href="javascript:void(0);">
               <span class="content">{{rolelist?.roleName}}</span> 
            
            </a>
        </li >
    </ul>
</div>
<div class="adminpopup" *ngIf="flag == 'department'">
  
    <i class="fa fa-time cross"  aria-hidden="true"></i>
    <ul class="box" >
       
        <li class="admin" *ngFor="let deplist of deprole">
            <a href="javascript:void(0);">
               <span class="content">{{deplist?.departmentName}}</span> 
            
            </a>
        </li >
    </ul>
</div>