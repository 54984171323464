import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UsersService } from 'src/app/modules/usermanagement/service/users/users.service';
export interface getUserDetail{
  id: number,
  tenant_Id: number,
  tenant_Name: null,
  tenantUID: {
      iiD_Id: number,
      iiD_value: number,
      uiD_Id: number,
      uiD_value: number
  },
  display_Name: string,
  login_Name: string,
  password: string,
  login_On: string,
  is_Active: true,
  owned_By: number,
  owned_By_Name: string,
  created_By: number,
  created_By_Name: string,
  created_On: string,
  modified_By: number,
  modified_By_Name: null,
  modified_On: string,
  roles: [
      {
          roleId: number,
          roleNam:string
      }
  ],
  department: [
      {
          departmentId: number,
          departmentName: string
      }
  ],
  isWorkFlow: false,
  action: number,
  approvedBy: number,
  approvedOn: string,
  workFlowStatus: number,
  masterId: number,
  approvedByName: null,
  remark: null
}
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
  showPassword: boolean = false;
  showPassword1: boolean = false;
  showPassword2: boolean = false;
  currentpw:string=''
  newpw:string=''
  confirmpw:string=''
  showerror:boolean=false
  userId: any = localStorage.getItem('userId')
  detail:any = localStorage.getItem('userDetails')
  pd:any = localStorage.getItem('userdata')
  userdetails: any=Array<[]>;
  departid: getUserDetail[]=[];
  rolesid: any[]=[];
  loadingData: boolean = true;
  constructor(private UsersService:UsersService, private router:Router,
    public dialog: MatDialog,
    ){
      console.log(this.userId,"name")
      setTimeout(() => {
        this.loadingData = true;
      this.UsersService.GetUserDetail({  "userId":this.userId}).subscribe((items)=>{
          
        this.loadingData = false;
          this.userdetails = items?.result
       console.log(this.userdetails,"details")
      //  this.currentpw = this.pd
        // this.userdetails?.department?.forEach((val:object|any)=>{
        //   this.departid.push(val?.departmentId)
        //   console.log(this.departid,"department")
        // })
  
        // this.userdetails?.roles.forEach((val:object|any)=>{
        //   this.rolesid.push(val?.roleId)
        //   console.log(this.rolesid,"roles")
        // })
      })
    }, 0);

  }
  save() {
    if(!this.newpw || !this.confirmpw || !this.currentpw){
      this.showerror = true
      return
    }else{
      this.showerror = false


    }

  }
  logout() {
    window.localStorage.clear();
    this.dialog.closeAll()
    // window.location.reload();

    this.router.navigate(['/']);

  }
  preventSpace(event: any): void {
    console.log(event,"e")
    const inputValue: string = event.target.value;
    
    if (event.code === "Space" ) {
        event.preventDefault();
    }
  
  }

}

