<div class="header-wrapper">
    <span>Edit</span>
    <button mat-icon-button class="btn close-button" [mat-dialog-close]="true">
        <i class="fa fa-close"></i>
      </button>
</div>
  
<div mat-dialog-content>
    <div class="container-fluid">
        <div class="row mb-3">
            <div class="col-md-12">
                <mat-form-field class="example-full-width">
                    <mat-label>English Name*</mat-label>
                    <input matInput [(ngModel)]="name" (keydown)="preventSpace($event)" (input)="validateInputLength()">
                </mat-form-field>
                <p class="error-text m-0" style="color:red;" *ngIf="!name && errormes">*This field is required
                    
                </p>
                <!-- <span style="color:red;" *ngIf="!name && errormes">*This field is required</span> -->
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-md-12">
                <mat-form-field class="example-full-width">
                    <mat-label> Arabic Name*</mat-label>
                    <input matInput [(ngModel)]="arabicName" (keydown)="preventSpace($event)" (input)="validateInputLength()">
                </mat-form-field>
                <p class="error-text m-0" style="color:red;" *ngIf="!arabicName && errormes">*This field is required

                </p>
                <!-- <span style="color:red;" *ngIf="!arabicName && errormes">*This field is required</span> -->
            </div>
        </div>
    
        <div class="row mb-3">
            <div class="col-md-6">
                <mat-form-field class="example-full-width">
                    <mat-label>Value*</mat-label>
                    <input matInput [(ngModel)]="value" (input)="validateInputLength()">
                </mat-form-field>
                <p class="error-text m-0"  style="color:red;" *ngIf="!value && errormes">*This field is required

                </p>
                <!-- <span style="color:red;" *ngIf="!value && errormes">*This field is required</span> -->
            </div>

            <div class="col-md-6" *ngIf="role != 'role'">
                <div class="check-toggle">
                    <p>Active</p>
                    <label [ngClass]="!checkbox  ? 'switch-action1' : 'switch-action'">
                        <input [(ngModel)]="checkbox" type="checkbox">
                        <span class="slider round"></span>
                    </label>
                </div>
            </div>
            <div class="col-md-6" *ngIf="role == 'role'">
                <div class="check-toggle">
                    <p>Active</p>
                    <label [ngClass]="checkbox == 'true' ? 'switch-action1' : 'switch-action'">
                        <input [(ngModel)]="checkbox" type="checkbox">
                        <span class="slider round"></span>
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions>
<button class="btn btn-theme" (click)="Submit()">Save</button>
<button class="btn btn-cancle" mat-dialog-close >Cancel</button>
</div>