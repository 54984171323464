import { Component } from '@angular/core';

@Component({
  selector: 'app-create-campaign-popup',
  templateUrl: './create-campaign-popup.component.html',
  styleUrls: ['./create-campaign-popup.component.scss']
})
export class CreateCampaignPopupComponent {

}
