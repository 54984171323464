import { createAction, props } from '@ngrx/store';

 
export const GetDepartmentById = createAction(
  '[GET_DepartmentById] Get DepartmentById',
  props<any>()
);

export const GetDepartmentByIdSuccess = createAction(
'[GET_DepartmentById] Get DepartmentById Success',
  props<any>()
);

export const GetDepartmentByIdFailure = createAction(
'[GET_DepartmentById] Get DepartmentById Failure',
  props<any>()
);


