import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import * as actions from 'src/app/app-state/actions';
import * as fromRoot from 'src/app/app-state';
import { Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { sideMenuType } from '../app-state/types/side-menu/sideMenu.types';


@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent {
  destroy$: Subject<boolean> = new Subject<boolean>();
  sidemenulist: Array<any>=[];
  selectedpage: string;
  
  constructor(private store:Store,
    private router: Router,
    ){
      this.selectedpage= this.router.url

    this.store?.dispatch(
      actions.sideMenu({}))
     this.store
     .select(fromRoot.selectStatessideMenuData)
     .pipe(takeUntil(this.destroy$))
     .subscribe((data:sideMenuType[]) => {
       
       this.sidemenulist = JSON.parse(JSON.stringify(data)).result?.menuList;
     

    
    })

  
  }
  navigate(url:string){
    if(url == '/role'){
      this.router.navigate(['/role'],{queryParams:{page:10},
    
    
    })
    }else if(url == '/template'){
      this.router.navigate(['/template'],{queryParams:{page:10},
    
    
    })
    }
    else{

      this.router.navigate([url])
    }
  }

}
