import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as reducer from '../../reducers/user-management/GetDepartmentById.reducers'

const state = createFeatureSelector<reducer.State>('GetDepartmentById');


export const selectLoadingGetDepartmentById = createSelector(
  state,
  state => state.isLoading
);

export const selectStatesGetDepartmentById= createSelector(
  state,
  state => state
);

export const selectStatesGetDepartmentByIdBody= createSelector(
  state,
  state => state.body
);

export const selectStatesGetDepartmentByIdData= createSelector(
  state,
  state => state.GetDepartmentById
);
