<div class="container-fluid p-0">
  <div class="main-outer ">
    <div class="form-bg-box">
      <div class="logo-bg-box">
        <img alt="" class="logo-value" src="../../assets/images/LOGO 2.png">
      </div>
      <form [formGroup]="loginForm" (ngSubmit)="OnSubmit();$event.stopPropagation();$event.preventDefault();">
          <div class="login-bg-box">
            <p class="login-text-value">
                Login
            </p>
            <div class="form-group">
                <input type="email" formControlName="username" class="form-control email-btn" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Username" (keydown)="preventSpace($event)">
                <mat-error class="mat-error"  *ngIf="(loginForm.controls['username'].touched || submitted) &&
                loginForm.controls['username'].errors && loginForm.controls['username'].errors?.['required']">*This Field is Required</mat-error>

<div class="eyeicon-box"> 
  <img alt="" class="eye-icon" src="../../assets/img/{{showPassword ? 'Show' : 'Show_Hide'}}.svg"
  (click)="showPassword = !showPassword" alt="show-hide" />


                <input  type="{{ showPassword ? 'text' : 'password' }}" formControlName="password" class="form-control email-btn pass-btn" id="exampleInputPassword1" placeholder="Password" (keydown)="preventSpace($event)">
              </div>
                <mat-error class="mat-error"  *ngIf="(loginForm.controls['password'].touched || submitted) &&
                loginForm.controls['password'].errors && loginForm.controls['password'].errors?.['required']">*This Field is Required</mat-error>

                
              </div>
            <div class="forgot-box">
              <a routerLink="/forget-password" href="" class="forget-pass">Forgot Password?</a>
            </div>
              <button [disabled]="loadingNext" [ngClass]="loadingNext ? 'loadingClass' : ''" type="submit"  class="btn submit-btn-value w-100 ">
                <span *ngIf="!loadingNext">Login</span>

                <img alt="" src="../../assets/images/arrow-right-svgrepo-com (2) 1.png">
                <mat-spinner *ngIf="loadingNext" [diameter]="20"></mat-spinner>

              </button>
        </div>
      </form>
    </div>
      
  </div>
</div>