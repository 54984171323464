<div class="top-box">
    <div class="mb-3 text-end" (click)="cancel()">
        <i class="fa fa-close close-icon"></i>
    </div>
    <div class="form-field-wrapper-one  phone-view-box ">
        <mat-form-field class="form-field-wrapper" appearance="fill">
            <mat-label>Language</mat-label>
            <mat-select [(ngModel)]="language">
                <mat-option *ngFor="let option of languageOptions" [value]="option.value">{{ option.label }}</mat-option>
            </mat-select>
        </mat-form-field>

    </div>
    <div class="frame-class" *ngIf="language == 1">
        <img class="frame-img" src="../../../../../assets/images/frame-1.png">
        <div class="template">
            <div class="inside-box1">
                <p [innerHTML]="arabicSub"></p>
            </div>
            <div class="inside-box1">
                <p [innerHTML]="arabicBody">

                </p>

            </div>
        </div>
    </div>
    <div class="frame-class"  *ngIf="language == 2">
        <img class="frame-img" src="../../../../../assets/images/frame-1.png">
        <div class="template">
            <div class="inside-box">
                <p [innerHTML]="englishSub"></p>
            </div>
            <div class="inside-box">
                <p [innerHTML]="englishBody"></p>

            </div>
        </div>
    </div>
</div>