import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsersService } from '../../service/users/users.service';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as actions from 'src/app/app-state/actions';
import * as fromRoot from 'src/app/app-state';
import { Subject, takeUntil } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

interface DepartmentResponse {
  departmentId: number;
  departmentName: string;
}
interface RolesResponse{
  roleId: number,
  roleName:string
}
export interface getUserDetail{
  id: number,
  tenant_Id: number,
  tenant_Name: null,
  tenantUID: {
      iiD_Id: number,
      iiD_value: number,
      uiD_Id: number,
      uiD_value: number
  },
  display_Name: string,
  login_Name: string,
  password: string,
  login_On: string,
  is_Active: true,
  owned_By: number,
  owned_By_Name: string,
  created_By: number,
  created_By_Name: string,
  created_On: string,
  modified_By: number,
  modified_By_Name: null,
  modified_On: string,
  roles: [
      {
          roleId: number,
          roleNam:string
      }
  ],
  department: [
      {
          departmentId: number,
          departmentName: string
      }
  ],
  isWorkFlow: false,
  action: number,
  approvedBy: number,
  approvedOn: string,
  workFlowStatus: number,
  masterId: number,
  approvedByName: null,
  remark: null
}
@Component({
  selector: 'app-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.scss']
})

export class UserViewComponent {
  destroy$: Subject<boolean> = new Subject<boolean>();
  public UserForm: FormGroup;
  userdetails: any=Array<[]>;
  tenantiid:number|any;
  tenantuiid: number|any;
  id: number;
  departmentList: any=Array<[]>;
  departid: number[]=[];
  rolesid: any[]=[];
  tenantrole: string|any;
  isFormReadOnly: boolean = false;
  isFormDisabled: boolean = false;
  constructor( private UsersService:UsersService, private store: Store, private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data:{id:number},
    private MatDialog:MatDialog){
      this.id = data?.id
      this.store.dispatch(
        actions.getDepartments({}))
      this.store
      .select(fromRoot.selectStatesgetDepartmentsData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.departmentList = data?.result
     
     })
    

     this.UsersService.Getroles({}).subscribe((items)=>{
     this.tenantrole = items
              })
              this.UsersService.GetTenantIID({}).subscribe((data)=>{
                this.tenantiid = data
                  })
          
                  this.UsersService.GetTenantUIID({}).subscribe((data)=>{
                    this.tenantuiid = data
                      })
          
              this.UserForm = new FormGroup({
          
                name : new FormControl('',[Validators.required]),
                password : new FormControl('',[Validators.required]),
                username : new FormControl('',[Validators.required]),
                rolesControl : new FormControl([],[Validators.required]),
                iidControl:  new FormControl('',[Validators.required]),
                uiidControl : new FormControl('',[Validators.required]),
                DepartmentControl : new FormControl([],[Validators.required]),
                isactive:new FormControl([],)
              
                })
  }
  ngOnInit(): void {
    // this.isFormDisabled = true;
    // this.isFormReadOnly = true
    this.UsersService.GetUserDetail({  "userId":this.id}).subscribe((items)=>{
      this.userdetails = items?.result
     console.log(this.userdetails,"details")
      

      this.userdetails?.department?.forEach((val:DepartmentResponse)=>{
        this.departid.push(val?.departmentId)
      })

      this.userdetails?.roles.forEach((val:RolesResponse)=>{
        this.rolesid.push(val?.roleId)
        this.isFormDisabled = true;
        this.isFormReadOnly = true;
      })
      

      this.UserForm = new FormGroup({

        isactive : new FormControl(!this.userdetails.is_Active),

        name : new FormControl(this.userdetails.display_Name,[Validators.required]),
        password : new FormControl(''),
        username : new FormControl(this.userdetails.login_Name,[Validators.required]),
        rolesControl : new FormControl(this.rolesid,[Validators.required]),
        iidControl:  new FormControl(this.userdetails.tenantUID.iiD_value,[Validators.required]),
        uiidControl : new FormControl(this.userdetails.tenantUID.uiD_value,[Validators.required]),
        DepartmentControl : new FormControl(this.departid,[Validators.required]),
      
        })
        this.UserForm.get('isactive')?.disable();
        this.UserForm.get('name')?.disable();
        this.UserForm.get('password')?.disable();
        this.UserForm.get('username')?.disable();
        this.UserForm.get('rolesControl')?.disable();
        this.UserForm.get('iidControl')?.disable();
        this.UserForm.get('uiidControl')?.disable();
        this.UserForm.get('DepartmentControl')?.disable();
    })
    // if (this.isFormDisabled) {
    //   this.UserForm.get('rolesControl')?.disable();
    // } else {
    //   this.UserForm.get('rolesControl')?.enable();
    // }
  }

}
