import { Component } from '@angular/core';
import { ProfileComponent } from '../profile/profile.component';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mobile-report',
  templateUrl: './mobile-report.component.html',
  styleUrls: ['./mobile-report.component.scss']
})
export class MobileReportComponent {
  constructor(private router: Router, public dialog: MatDialog) { }

  usericonDialog() {
    this.dialog.open(ProfileComponent,{
      panelClass:['common-modalbox'],
    })
    
      }
}
