import { Action, createReducer, on } from '@ngrx/store';
import { sideMenu, sideMenuBody, sideMenuType } from '../../types/side-menu';
import * as actions from '../../actions';

export interface State {
  sideMenu: sideMenuType[];
  defaultPreferredWorkshop: sideMenu[];
  isLoading: boolean;
  isLoadingSuccess: boolean;
  isLoadingFailure: string;
  body: sideMenuBody;
}

export const initialState: State = {
  sideMenu: [],
  defaultPreferredWorkshop:[],
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: '',
  body:{
  },
};

const funReducer = createReducer(
  initialState,
  on(actions.sideMenu, (state,action) => ({...state, isLoading: true})),
  on(actions.sideMenuSuccess, (state, result) => {
    return{
      ...state,
      defaultPreferredWorkshop: result.response,
      sideMenu: result.response,
      isLoading: false,
      isLoadingSuccess: true
    }}),
  on(actions.sideMenuFailure, (state, result) => ({
      ...state,
      sideMenu: [],
      isLoading: false,
      isLoadingSuccess: false,
      isLoadingFailure:result.error
  })),
);

export function reducer(state: State | undefined, action: Action): any {
  return funReducer(state, action);
}

