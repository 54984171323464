import { createAction, props } from '@ngrx/store';

 
export const UpdateMessageAssetPathXml = createAction(
  '[Update_MessageAssetPathXml] UpdateMessageAssetPathXml',
  props<any>()
);

export const UpdateMessageAssetPathXmlSuccess = createAction(
'[Update_MessageAssetPathXml] Update MessageAssetPathXml Success',
  props<any>()
);

export const UpdateMessageAssetPathXmlFailure = createAction(
'[Update_MessageAssetPathXml] Update MessageAssetPathXml Failure',
  props<any>()
);