import { UsersService } from '../../service/users/users.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as actions from 'src/app/app-state/actions';
import * as fromRoot from 'src/app/app-state';
import { Subject, takeUntil } from 'rxjs';
import { AllDepartments, getDepartmentResponse } from 'src/app/app-state/types';



interface DepartmentResponse {
  departmentId: number;
  departmentName: string;
}
interface RolesResponse{
  roleId: number,
  roleName:string
}
export interface getUserDetail{
  id: number,
  tenant_Id: number,
  tenant_Name: null,
  tenantUID: {
      iiD_Id: number,
      iiD_value: number,
      uiD_Id: number,
      uiD_value: number
  },
  display_Name: string,
  login_Name: string,
  password: string,
  login_On: string,
  is_Active: true,
  owned_By: number,
  owned_By_Name: string,
  created_By: number,
  created_By_Name: string,
  created_On: string,
  modified_By: number,
  modified_By_Name: null,
  modified_On: string,
  
  roles: [
      {
          roleId: number,
          roleName:string
      }
  ],
  department: [
      {
          departmentId: number,
          departmentName: string
      }
  ],
  isWorkFlow: false,
  action: number,
  approvedBy: number,
  approvedOn: string,
  workFlowStatus: number,
  masterId: number,
  approvedByName: null,
  remark: null
}
export interface tenantiid{
  
    id: number,
    tenant_Id: number,
    iid: number
  
}
export interface tenantUiid{
id: number,
uid: number,
iid: number
}
export interface SelectedRole{
  roleId: number,
  roleNam: string
}
export interface tenantROle{
id: string,
tenant_Id : number,
tenant_Name: string,
name: string,
description: string,
is_Active : null,
created_By: number,
created_By_Name: string,
created_On: string,
modified_By: number,
modified_By_Name: string,
modified_On: string,
isWorkFlow: false,
action: 0,
approvedBy: 0,
approvedOn: string,
workFlowStatus: number,
masterId: number,
approvedByName: null,
remark: null
}
@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent {
  destroy$: Subject<boolean> = new Subject<boolean>();
  public UserForm: FormGroup;
  submitted: boolean=false;
  showPassword: boolean = false;

  departmentList: any=Array<[]>;
  id: number;
  userdetails: any=Array<[]>;
  tenantiid:number|any;
  tenantuiid: number|any; 
  tenantrole: string|any;
  departid: Array<number>=[] ;
  rolesid: any[]=[];

  constructor(private UsersService:UsersService, private store: Store,
    @Inject(MAT_DIALOG_DATA) public data:{id:number},
    private MatDialog:MatDialog
    ){
      this.id = data?.id
console.log()
      this.store.dispatch(
        actions.getDepartments({}))
      this.store
      .select(fromRoot.selectStatesgetDepartmentsData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.departmentList = data?.result
     
     })
    

     this.UsersService.Getroles({}).subscribe((items)=>{
     this.tenantrole = items
              })
 
    this.UsersService.GetTenantIID({}).subscribe((data)=>{
      this.tenantiid = data
        })

        this.UsersService.GetTenantUIID({}).subscribe((data)=>{
          this.tenantuiid = data
            })


    this.UserForm = new FormGroup({

      name : new FormControl('',[Validators.required]),
      password : new FormControl('',[Validators.required,Validators.minLength(6)]),
      username : new FormControl('',[Validators.required]),
      rolesControl : new FormControl([],[Validators.required]),
      iidControl:  new FormControl([],[Validators.required]),
      uiidControl : new FormControl([],[Validators.required]),
      DepartmentControl : new FormControl([],[Validators.required]),
      isactive:new FormControl([],)
    
      })
  }


 
  ngOnInit(): void {
    this.UsersService.GetUserDetail({  "userId":this.id}).subscribe(items=>{
      console.log(items,'yyyyy')
      this.userdetails = items?.result
     
      

      this.userdetails?.department?.forEach((val: DepartmentResponse)=>{
        console.log(val,'val')
        this.departid.push(val?.departmentId)
      })

      this.userdetails?.roles.forEach((val: RolesResponse)=>{
        this.rolesid.push(val?.roleId)
      })
      

      this.UserForm = new FormGroup({

        isactive : new FormControl(!this.userdetails.is_Active),

        name : new FormControl(this.userdetails.display_Name,[Validators.required]),
        password : new FormControl('',[Validators.minLength(6)]),
        username : new FormControl(this.userdetails.login_Name,[Validators.required]),
        rolesControl : new FormControl(this.rolesid,[Validators.required]),
        iidControl:  new FormControl(this.userdetails.tenantUID.iiD_value,[Validators.required]),
        uiidControl : new FormControl(this.userdetails.tenantUID.uiD_value,[Validators.required]),
        DepartmentControl : new FormControl(this.departid,[Validators.required]),
      
        })
    })

  }
  validateInputLength(){
    if (this.UserForm.value.name.length > 50) {
      this.UsersService.toast.snackbarSucces('Character limit exceeded (max: 50)');
    }
  }

  preventSpace(event: any): void {
    console.log(event,"e")
    const inputValue: string = event.target.value;
    
    if (event.code === "Space" ) {
        event.preventDefault();
    }

  }

  removerole(index:number){
    let arr = [...this.UserForm.value.rolesControl]
    arr.splice(index,1)
    this.UserForm.controls['rolesControl'].setValue(arr);

  }
  removedepartment(index:number){
    let arr = [...this.UserForm.value.DepartmentControl]
    arr.splice(index,1)
    this.UserForm.controls['DepartmentControl'].setValue(arr);
  
  }
 

  onSubmit(){
    if(this.UserForm.value.name && this.UserForm.value.name.length > 50){
      console.log("!!!!!!!!!!!!!")
      this.submitted = false;
      this.UsersService.toast.snackbarSucces('Character limit exceeded (max: 50)');
      return
    }
    this.submitted = true;
    if(this.UserForm.valid){
    this.UsersService.AddUser({
      'userId': this.id,
      "displayName": this.UserForm.value.name,
      "loginName": this.UserForm.value.username,
      "password": this.UserForm.value.password,
      "isActive": !this.UserForm.value.isactive,
      "tenantId": 1,
      "tenantIIDId": parseInt(this.UserForm.value.iidControl),
      "tenantUIId":  
       parseInt(this.UserForm.value.uiidControl),
      "roleIds": this.UserForm?.value?.rolesControl.map((val:string)=>{
        return parseInt(val)
      }),
      "departmentIds": this.UserForm.value.DepartmentControl.map((val:string)=>{   
          return parseInt(val)
        })
    }).subscribe(()=>{
      this.MatDialog.closeAll()
    this.UsersService.toast.snackbarSucces("User Updated Successfully!");
      })


  }
}




}
