import { createAction, props } from '@ngrx/store';

 
export const ApproveRejectMessageTemplateByWorkFlow = createAction(
  '[ApproveReject_MessageTemplateByWorkFlow] Approve Reject MessageTemplateByWorkFlow',
  props<any>()
);

export const ApproveRejectMessageTemplateByWorkFlowSuccess = createAction(
'[ApproveReject_MessageTemplateByWorkFlow] Approve Reject MessageTemplateByWorkFlow Success',
  props<any>()
);

export const ApproveRejectMessageTemplateByWorkFlowFailure = createAction(
'[ApproveReject_MessageTemplateByWorkFlow] Approve Reject MessageTemplateByWorkFlow Failure',
  props<any>()
);