<div class="header">
    <div class="logo">
        <h4 class="text"><img alt="" class="dashboard-img" src="../../assets/images/dash-icon.png"> Dashboard </h4>
    </div>

    <div class="toggle-icon">
        <label class="switch">
            <input type="checkbox" checked>
            <span class="slider round"></span>
        </label>
        <img alt="" alt="" src="../../assets/images/user.png">
    </div>

</div>