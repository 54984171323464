import { DeletePopupComponent } from '../../../dictionary/components/delete-popup/delete-popup.component';
import { EditPopupComponent } from '../../../dictionary/components/edit-popup/edit-popup.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ProfileComponent } from '../../../commonModule/components/profile/profile.component';
import { MatDialog} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as actions from 'src/app/app-state/actions';
import * as fromRoot from 'src/app/app-state';
import { Subject, takeUntil } from 'rxjs';
import * as moment from 'moment';
import { RoleviewComponent } from '../roleview/roleview.component';
import { DepartmentviewComponent } from '../../../dictionary/components/departmentview/departmentview.component';
import { MatTableDataSource } from '@angular/material/table';
import { GetRolesResponse } from 'src/app/app-state/types/user-management/getRoles.types';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MyRequest } from 'src/app/app-state/types/user-management/getRoles.types';
import { UsersService } from '../../service/users/users.service';

interface Role {
  id: number;
  tenant_Id: number;
  tenant_Name: string;
  name: string;
  description: string;
  is_Active: boolean;
  created_By: number;
  created_By_Name: string;
  created_On: string;
  modified_By: number;
  modified_By_Name: string;
  modified_On: string;
  isWorkFlow: boolean;
  action: number;
  approvedBy: number;
  approvedOn: string | null;
  workFlowStatus: number;
  masterId: number;
  approvedByName: string | null;
  remark: string | null;
}

interface RoleApiResponse {
  result: {
    myRequest: null;
    pendingRequests: null;
    approvedRequests: null;
    allRoles: Role[];
    isWorkFlowEnabled: boolean;
  };
  responseMessage: string;
  isSuccess: boolean;
  error: null;
}

@Component({
  selector: 'app-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.scss']
})
export class RoleListComponent implements OnInit {
  SortVal:string='first'
  dataSource = new MatTableDataSource<object>([]);
  displayedColumns: string[] = [
    'name',
    'description',
    'createdBy',
    'created_On',
    'modified_By_Name',
    'modified_On',
    'action',
  ];
  
  destroy$: Subject<boolean> = new Subject<boolean>();
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  pageSize:number = 10;
  pageIndex: number = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  public moment = moment;
  page: number = 1;
  count: number = 0;
  tableSize: number = 15;
  userSearch:string=''
  rolelists: MyRequest[]=[];
  status: string='';
loadingData: boolean = true;
filteredData: any[]=[];
  constructor(private readonly store: Store,private router: Router, public dialog: MatDialog, public service: UsersService, private readonly route: ActivatedRoute,) {

     
 this.getroles()

   }

   getroles(){
   this.loadingData = true;
    this.service.getRoles({}).subscribe((res:object|any)=>{
      console.log(res,'iiuuuuu')
      this.rolelists = res?.result?.allRoles
         this.dataSource.data = this.rolelists
         this.loadingData = false;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    })
   }
   onPageChange(event:object|any): void {
    this.route.queryParams.subscribe((params) => {
      console.log(params,'eeeee')
      this.router.navigate(['/role'], {
        queryParams: {
          page: event.pageSize,
          pageIndex: event?.pageIndex,
        },
      });
    });

  }
   ngOnInit(): void {
    this.route.queryParams.subscribe((params:object|any) => {
      if(params){
      this.pageSize = params.page;
      this.pageIndex = params.pageIndex;
      this.dataSource.paginator = this.paginator;
      }
      setTimeout(() => {
        this.paginator.pageIndex = this.pageIndex;
        this.paginator.pageSize = this.pageSize;
      }, 0);
    });
  }

  editrole(element:object|any){
    this.router.navigate(['/role-edit/'+element?.id ],{
      queryParams: {
        page: this.pageSize,
        pageIndex: this.pageIndex,
      },
    } );
   
  } 
  addrole(event:any){
    this.route.queryParams.subscribe((params:object|any) => {
      if(params){
      this.pageSize = params.page;
      this.pageIndex = params.pageIndex;
      this.dataSource.paginator = this.paginator;
      }
      setTimeout(() => {
        this.paginator.pageIndex = this.pageIndex;
        this.paginator.pageSize = this.pageSize;
      }, 0);
    });
    this.router.navigate(['/role-add'] ,{
      queryParams: {
        page: this.pageSize,
        pageIndex: this.pageIndex,
      },
    } ) 
  }
 
   changeStatus(){
    let statarr:Array<MyRequest>=[]

      this.rolelists.forEach((item:MyRequest)=>{
      if(item.is_Active==this.status){
        statarr.push(item)
        this.dataSource.data = statarr
      }else{
        this.dataSource.data=[]
      }
     
    })
  }


  depview(userdata:MyRequest){
    this.dialog.open(DepartmentviewComponent,{
      data:{
        showdata:userdata
      },
      panelClass: ['common-modalbox'],
  
    })
  
  }
  usericonDialog() {
    this.dialog.open(ProfileComponent,{
      panelClass:['common-modalbox'],
    })
    
  }

      deleteiconDialog(roleid:number){
        
        this.dialog.open(DeletePopupComponent,{
          data:{
            roleid:roleid
          },
          panelClass:['common-modalbox'],
        })
      }

      roledialog(data:MyRequest, id:number){
      const dialogref =  this.dialog.open(EditPopupComponent,{
          data:{
            role:'role',
            roledata: data,
            id:id
   
          },
          panelClass:['common-modalbox'],
        })
        dialogref.afterClosed().subscribe(() => {
          this.getroles();
      
      
          })

      }
      Reset() {
        this.userSearch = "";
        this.SortVal ='first'
        this.dataSource.data= this.rolelists 
      }
      SearchUser(event:string): void {
       this.SortVal ='first'
       this.status =''
  
        if(event){
          this.dataSource.data = this.rolelists.filter((val:MyRequest) =>
            (val.name.toLowerCase().trim().includes(event.toLowerCase().trim())
            || val.description.toLowerCase().trim().includes(event.toLowerCase().trim())
            )
          ) 
          this.filteredData = this.dataSource.data
        }else {
          this.dataSource.data= this.rolelists
        }
    }

    userview(iD:number){
  this.router.navigate(['/role-edit/'+iD],{queryParams:{parm : 'roleview'}})

    }

    resetSorting() {
      if (this.sort) {
        this.sort.active = "";
        this.sort.direction = "";
      }
      this.dataSource.data = [...this.dataSource.data];
    }

    
    
  Sort(e:string){
    
    if(this.userSearch){
      // this.resetSorting();
    if(e == 'first'){
      this.dataSource.data=this.filteredData
    }else if(e == 'last') {
      this.dataSource.data=[...this.filteredData].reverse()
    }
    }else{
      this.dataSource.data = []
      this.resetSorting();
  
      if(e == 'first'){
        this.dataSource.data=this.rolelists
      }else if(e == 'last') {
        this.dataSource.data=[...this.rolelists].reverse()
      }
      
    }
}    

}
