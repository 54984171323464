import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-roleview',
  templateUrl: './roleview.component.html',
  styleUrls: ['./roleview.component.scss']
})
export class RoleviewComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any){

  }

}
