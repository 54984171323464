import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as reducer from '../../reducers/auth/auth.reducers'
  
const state = createFeatureSelector<reducer.State>('usernameToUserInformation');


export const getUsernameToUserInformationList = createSelector(
  state,
  state => state.isLoading
);

export const selectStatesgetUsernameToUserInformationList= createSelector(
  state,
  state => state
);

export const selectStatesgetUsernameToUserInformationListBody= createSelector(
  state,
  state => state.body
);

export const selectStatesgetUsernameToUserInformationListData= createSelector(
  state,
  state => state.getUsernameToUserInformation
);

export const selectStatesgetUsernameToUserInformationOutletListData= createSelector(
  state,
  state => state.getUsernameToUserInformation.userOutletNameList
);


export const selectPermissionForUserData= createSelector(
  state,
  state => state.permissions
);

