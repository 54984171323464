import { Component } from '@angular/core';

@Component({
  selector: 'app-deletecampaign',
  templateUrl: './deletecampaign.component.html',
  styleUrls: ['./deletecampaign.component.scss']
})
export class DeletecampaignComponent {

}
