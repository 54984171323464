import { Component, Inject, OnInit, inject } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms'  
import { MAT_DIALOG_DATA,MatDialog } from '@angular/material/dialog';
import { UsersService } from 'src/app/modules/usermanagement/service/users/users.service';

@Component({
  selector: 'app-group-add',
  templateUrl: './group-add.component.html',
  styleUrls: ['./group-add.component.scss']
})
export class GroupAddComponent implements OnInit {
  name = 'Angular';  
    
  productForm: FormGroup;
  description: string='';
  channel: string='';
  channels: Array<any>=[];
  grouplist:  Array<any>=[];
  groupdescription:string='';
  groupname:string='';
  id: number|any;
  isActive: boolean=true;
  languages: Array<any>=[];
  groups: Array<any>=[];
  channelid: any[]=[];
  activeornot:boolean=true;
  language:string='';
  channels1: Array<any>=[];
  errormes: boolean = false;
  submit: boolean = false
     
  constructor(private fb:FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any ,private MatDialog:MatDialog, private userservice:UsersService) { 

     
  
    
    this.productForm= this.fb.group({
     quantities:new FormArray([]) 
    })
    console.log(this.productForm,"dd")
    if(this.data?.val == 'edit' || this.data?.val == 'view'){
      this.getGroupDetail()
    }
    if(this.data?.val == 'view') {
    
    }
 

     this.userservice.Getchannels().subscribe((val:object|any)=>{
      this.channels = val?.result
      console.group(this.channels,"d")
     })


     this.userservice.Getlanguages().subscribe((val:object|any)=>{
      this.languages = val?.result
      

     })

     this.userservice.Getgroupchannels({
      "groupId": this.data?.id
    }).subscribe((val:object|any)=>{
      this.channels1 = val?.result
      console.log(this.channels1,"c")

     })

  }  

  getGroupDetail(){
    this.userservice.getGroupById({  "groupId": this.data?.id
  }).subscribe((res:object|any)=>{
    this.groups=res?.result

    this.channelid = res?.result?.channels
    console.log(res?.result,"dw")
   this.groupname=res?.result?.name
   this.groupdescription=res?.result?.description
   this.activeornot = res?.result?.is_Active 
  })
  }
  
   ngOnInit(): void {
     
   }
     
   
    
  quantities() : FormArray {  
    return this.productForm.get("quantities") as FormArray  
  }  
     
  newQuantity(): FormGroup {  
    return this.fb.group({  
      price: '',  
    })  
  }  

  addQuantity() {  
    this.quantities().push(this.newQuantity());  
  }  
     
  removeQuantity(i:number) {  
    this.quantities().removeAt(i);  
  }  
  activenot(){
     this.isActive =  !this.isActive;
    
  }
  preventSpace(event: any): void {
    console.log(event,"e")
    const inputValue: string = event.target.value;
    
    if (event.code === "Space" && inputValue.trim() === "") {
        event.preventDefault();
    }

  }
  validateInputLength(){
    if (this.groupname.length > 50) {
        this.userservice.toast.snackbarSucces('Character limit exceeded (max: 50)');
    }
  }
  validateInputLength1(){
    if (this.groupdescription.length > 1000) {
        this.userservice.toast.snackbarSucces('Character limit exceeded (max: 1000)');
    }
  }
  onSubmit(){
    console.log(this.channelid,'sdsdsd9')

    // return
      if(this.data?.val == 'edit'){
        if(!this.groupname.trim() || !this.groupdescription.trim() || this.channelid.length == 0){
          this.errormes = true
          return
        }
        if(this.groupname && this.groupname.length > 50){
          console.log("!!!!!!!!!!!!!")
          this.submit = false;
            this.userservice.toast.snackbarSucces('Character limit exceeded (max: 50)');
          return
        }
        if(this.groupdescription && this.groupdescription.length > 1000){
          console.log("2222222222222222")
          this.submit = false;
            this.userservice.toast.snackbarSucces('Character limit exceeded (max: 1000)');
            return
        } 
        else{
          this.submit= true;
          this.errormes = false
          this.userservice.Addgroup(
   
          {
            "id": this.data?.id,
            "name": this.groupname,
            "description": this.groupdescription,
            "isActive": this.activeornot,
            "groupType": 1,
            "channelType": "1",
            "channels": this.channelid,
            "dataSourceTypeId": 1,
            "handlerId": 0
          
        }
          ).subscribe((res:object|any)=>{
            this.grouplist=res
          }) 
          this.MatDialog.closeAll()
          this.userservice.toast.snackbarSucces("Group Updated Successfully");
        }
        }
        else{
          console.log(this.channelid.length == 0,'ddssccsd89')
          if(!this.groupname || !this.groupdescription || this.channelid.length == 0 ){
            this.errormes = true
            return
          }
          if(this.groupname && this.groupname.length > 50){
            console.log("!!!!!!!!!!!!!")
            this.submit = false;
              this.userservice.toast.snackbarSucces('Character limit exceeded (max: 50)');
              return
          } 
          if(this.groupdescription && this.groupdescription.length > 1000){
            console.log("2222222222222222")
            this.submit = false;
              this.userservice.toast.snackbarSucces('Character limit exceeded (max: 1000)');
              return
          } 
          else{ 
            this.submit = true;
            this.errormes = false
            console.log(this.channelid,"11")
            this.userservice.Addgroup(
              {
                "id": 0,
                "name": this.groupname,
                "description": this.groupdescription,
                "isActive": this.activeornot,
                "groupType": 1,
                "channelType": "1",
                "channels": this.channelid,
                "dataSourceTypeId": 1,
                "handlerId": 0
              
            }).subscribe((res:object|any)=>{
              console.log(res,'ressdd8')
              if(res?.isSuccess == true){

                this.grouplist=res
                this.MatDialog.closeAll()
                this.userservice.toast.snackbarSucces("Group Added Successfully");
              }else{
                this.userservice.toast.snackbarSucces(res?.responseMessage);


              }
            }) 
          }
          
        }
  }

}

