import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as reducer from '../../reducers/user-management/DeactivateUser.reducers'

const state = createFeatureSelector<reducer.State>('DeactivateUser');


export const selectLoadingDeactivateUser = createSelector(
  state,
  state => state.isLoading
);

export const selectStatesDeactivateUser= createSelector(
  state,
  state => state
);

export const selectStatesDeactivateUserBody= createSelector(
  state,
  state => state.body
);

export const selectStatesDeactivateUserData= createSelector(
  state,
  state => state.DeactivateUser
);
