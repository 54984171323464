<div class="header-wrapper">
    <span>View User</span>
    <button mat-icon-button class="btn close-button" [mat-dialog-close]="true">
        <i class="fa fa-close"></i>
      </button>
  </div>
  <form  [formGroup]="UserForm"  >
    <div mat-dialog-content>
      <div class="container-fluid">
    
          <div class="row mb-3">
              <div class="col-md-6">
                  <mat-form-field class="example-full-width">
                      <mat-label>Name</mat-label>
                      <input matInput  formControlName ="name">
                      
                    </mat-form-field>
               
                   </div>
              <div class="col-md-6">
                  <div class="check-toggle">
                      <p>Active</p>
                      <label [ngClass]="userdetails?.is_Active == true ? 'switch-action' : 'switch-action1'">
                          <input  type="checkbox" formControlName="isactive">
                          <span class="slider round"></span>
                      </label>
                  </div>
              </div>
          </div>
          <div class="row mb-3">
              <div class="col-md-6">
    
    
                  <mat-form-field appearance="fill">
                      <mat-label>IID</mat-label>
                      <mat-select formControlName ="iidControl" >
                        <mat-option *ngFor="let iid of tenantiid?.result?.iids" [value]="iid.iid">{{iid.iid}}</mat-option>
                    
                      </mat-select>
                    </mat-form-field>
                      </div>
              <div class="col-md-6">
                  <mat-form-field appearance="fill">
                      <mat-label>UID</mat-label>
                      <mat-select formControlName ="uiidControl">
                        <mat-option *ngFor="let uiid of tenantuiid?.result?.uids" [value]="uiid.uid">{{uiid.uid}}</mat-option>
                    </mat-select>
                    </mat-form-field>
                    </div>
          </div>
          <div class="row mb-3">
              <div class="col-md-12">
                  <mat-form-field>
                      <mat-label>Roles</mat-label>
                      <mat-select formControlName ="rolesControl" multiple>
                    
                
                    
                        <mat-option *ngFor="let role of tenantrole?.result?.roles" [value]="role.id">{{role.name}}</mat-option>
                    
                      </mat-select>
                    </mat-form-field>
                   </div>
          </div>
          <div class="row mb-3">
              <div class="col-md-12">
                  <mat-form-field>
                      <mat-label>Departments</mat-label>
                      <mat-select formControlName ="DepartmentControl"  multiple >
                    
                      
                    
                        <mat-option *ngFor="let department of departmentList" [value]="department?.value">{{department?.name}}</mat-option>
                    
                      </mat-select>
                    </mat-form-field>
                 
              </div>
          </div>
          <div class="row mb-3">
              <div class="col-md-12">
                  <mat-form-field class="example-full-width">
                      <mat-label>Username</mat-label>
                      <input matInput type="username"  formControlName ="username">
                  </mat-form-field>
    
              </div>
          </div>
          <div class="row">
              <div class="col-md-12">
                  <mat-form-field class="example-full-width">
                      <mat-label>Password</mat-label>
                      <input matInput type="password" formControlName ="password">
                  </mat-form-field>
              </div>
          </div>
        </div>
      </div>
    </form>
    <div mat-dialog-actions>
      <button class="btn btn-cancle" mat-dialog-close>Cancel</button>
    </div>

  <!-- <div mat-dialog-content>
    <div class="container-fluid">
  
        <div class="row mb-3">
            <div class="col-md-6">
                <mat-form-field class="example-full-width">
                    <mat-label>Name</mat-label>
                    <input matInput value="{{data?.showdata?.display_Name}}" readonly="readonly" >
                    
                  </mat-form-field>
             
                     </div>
            <div class="col-md-6" >
                <div class="check-toggle">
                    <p>Active</p>
                    <label  [ngClass]="data?.showdata?.is_Active ? 'switch-action' : 'switch-action1'">
                        <input type="checkbox" disabled>
                        <span class="slider round"></span>
                    </label>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-6">
  
                <mat-form-field appearance="fill">
                    <mat-label>IID</mat-label>
                    <input matInput value="{{data?.showdata?.tenantUID?.iiD_value}}" readonly="readonly" >

                  </mat-form-field>
                  </div>
            <div class="col-md-6">
                <mat-form-field appearance="fill">
                    <mat-label>UID</mat-label>
                    <input matInput value="{{data?.showdata?.tenantUID?.uiD_value}}" readonly="readonly" >

                  </mat-form-field>
                    </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Roles</mat-label>
                    <mat-select multiple>
                      <mat-option *ngFor="let role of tenantrole?.result?.roles" [value]="role.id">{{role.name}}</mat-option>
                  
                    </mat-select>

                  </mat-form-field>
                   </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Departments</mat-label>
                    <mat-select  >
                      <mat-option  *ngFor="let iid of data?.showdata?.department" >{{iid?.departmentName}}</mat-option>
                  
                    </mat-select>
                  </mat-form-field>
                             </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-12">
                <mat-form-field class="example-full-width">
                    <mat-label>Username</mat-label>
                    <input matInput value="{{data?.showdata?.login_Name}}" readonly="readonly" >
                </mat-form-field>
                
            </div>
        </div>
       
      </div>
    </div>
    <div mat-dialog-actions>
      <button class="btn btn-cancle" mat-dialog-close>Cancel</button>
    </div> -->