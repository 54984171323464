import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as reducer from '../../reducers/dictionary/IsTemplateAssociatedToService.reducers'

const state = createFeatureSelector<reducer.State>('IsTemplateAssociatedToService');


export const selectLoadingIsTemplateAssociatedToService = createSelector(
  state,
  state => state.isLoading
);

export const selectStatesIsTemplateAssociatedToService= createSelector(
  state,
  state => state
);

export const selectStatesIsTemplateAssociatedToServiceBody= createSelector(
  state,
  state => state.body
);

export const selectStatesIsTemplateAssociatedToServiceData= createSelector(
  state,
  state => state.IsTemplateAssociatedToService
);
