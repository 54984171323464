import { ApiService } from '../../../../services/service';
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface language {
  value: number;
  name: string;
}
export interface languageResponse {
  result: language[];
  responseMessage: string;
  isSuccess: boolean;
  error: any; 
}
interface CampaignDetailsResponse {
  result: {
    workflowEnabled: boolean;
    requested: null | any;
    pending: null | any;
    approvedOrRejected: null | any;
    all: Campaign[];
  };
  responseMessage: string;
  isSuccess: boolean;
  error: null | any;
}

interface Campaign {
  rowNumber: number;
  tenantId: number;
  rowId: number;
  workflowId: number;
  campaignId: number;
  campaignName: string;
  createdOn: string;
  createdBy: number;
  createdByName: string;
  approvedBy: number | null;
  approvedOn: string | null;
  approvedByName: string | null;
  statusId: number;
  campaignStatus: string;
  summary: string | null;
  scheduledDateTime: string;
  sourceTypeId: string;
  excelFilePath: string;
  allowedStartTime: string;
  allowedEndTime: string;
  languageId: string;
  allowedTimeGap: number;
  recordCount: number;
  campaignType: number;
  days: number[];
  channels: number[];
  startDate: string;
  endDate: string;
}

export interface Shortcode {
  value: number;
  name: string;
}

export interface ShortcodesResponse {
  result: Shortcode[];
  responseMessage: string;
  isSuccess: boolean;
  error: any;
}


export interface CampaignType {
  value: number;
  name: string;
}


export interface CampaignTypesResponse {
  result: CampaignType[];
  responseMessage: string;
  isSuccess: boolean;
  error: any; 
}

export interface SmtpType {
  value: number;
  name: string;
}


export interface SmtpTypeResponse {
  result: SmtpType[];
  responseMessage: string;
  isSuccess: boolean;
  error: any; 
}

export interface QueueType {
  value: number;
  name: string;
}

export interface QueueTypesResponse {
  result: QueueType[];
  responseMessage: string;
  isSuccess: boolean;
  error: any; 
}

interface Language {
  lan: 'en' | 'ar';
  body: string;
}

interface XmlMessage {
  language: Language[];
}

interface Result {
  messageTemplateTypeId: number;
  messageTemplateTypeName: string;
  baseXML: string;
  version: string;
  tenantId: number;
  baseXSD: string;
}

interface TemplateTypesResponse {
  result: Result[];
  responseMessage: string;
  isSuccess: boolean;
  error: any; 
}

interface GroupSubset {
  value: number;
  name: string;
}

interface GroupSubsetResponse {
  result: GroupSubset[];
  responseMessage: string;
  isSuccess: boolean;
  error: null | string;
}

interface UserDetailResponse {
  result: {
    id: number;
    tenant_Id: number;
    tenant_Name: string;
    tenantUID: {
      iiD_Id: number;
      iiD_value: number;
      uiD_Id: number;
      uiD_value: number;
    };
    display_Name: string;
    login_Name: string;
    password: string;
    login_On: string | null;
    is_Active: boolean;
    owned_By: number;
    owned_By_Name: string | null;
    created_By: number;
    created_By_Name: string;
    created_On: string;
    modified_By: number;
    modified_By_Name: string | null;
    modified_On: string | null;
    roles: {
      roleId: number;
      roleName: string;
    }[];
    department: {
      departmentId: number;
      departmentName: string;
    }[];
    isWorkFlow: boolean;
    action: number;
    approvedBy: number;
    approvedOn: string | null;
    workFlowStatus: number;
    masterId: number;
    approvedByName: string | null;
    remark: string | null;
  };
  responseMessage: string;
  isSuccess: boolean;
  error: any; 
}

interface CampaignDetailResponse {
  result: {
    campaign: {
      id: number;
      campaignUniqId: number;
      campaignName: string;
      campaignType: number;
      campaignNameArabic: string | null;
      templateServiceID: number;
      seviceType: string | null;
      schedulingInterval: string | null;
      campaignFilter: string | null;
      templateId: number;
      arabicTemplateId: number;
      enableNotification: boolean;
      title: string | null;
      shortDesc: string | null;
      subject: string | null;
      titleA: string | null;
      shortDescA: string | null;
      subjectA: string | null;
      serviceId: number;
      createdDate: string;
      createdBy: number;
      modifiedDate: string;
      modifiedBy: number;
      createdUserId: number;
      thumbnailURL: string | null;
      emailSubject: string | null;
      emailSubjectArb: string | null;
      emailEngTemplateID: number;
      emailArbTemplateID: number;
      campaignTypeFlag: string | null;
      languageField: string;
      languageId: number;
      iidid: number;
      uidid: number;
      queueTypeId: number;
      departmentId: number;
      tenantId: number;
      isMandatory: boolean;
      isChannelInfoInSource: boolean;
      isSeperateQueueRequire: boolean;
      amount_Field: string | null;
      currency_Field: string | null;
      statusId: number;
      fieldMappingXML: string;
      isSubscriptionCheck: boolean;
      isDNDCheck: boolean;
      dndTags: string | null;
      customerNoField: string;
      productNoField: string;
      channels: string;
      dndChannels: string | null;
      isDiscarded: boolean;
      isTimeRestricted: boolean;
      startDate: string | null;
      endDate: string | null;
      scheduledDateTime: string;
      schemaInfo: string;
      subscriptionDatalevelId: number;
      subscriptionValueId: number;
      summary: string | null;
      excelFilePath: string;
      allowedStartTime: string;
      allowedEndTime: string;
      allowedTimeGap: number;
      recordCount: number;
      numberOfBatches: number;
      daysToSend: string;
      groupSelected: string;
      sourceTypeId: number;
      sourceTypeIds: string;
    };
    sms: null;
    email: {
      messageTemplateId: number;
      useSMTP: boolean;
      smstpId: number;
      toEmailIdField: string;
      emailConnectorId: number | null;
    };
    push: null;
    inbox: null;
    whatsApp: null;
    isRunning: boolean;
    channelConfiguration: {
      sms: null;
      email: {
        englishTemplate: string;
        toEmail: string;
        useSMTP: boolean;
        smtpConfig: string;
        isDataInSource: boolean;
        validationMode: string;
        validationRule: string;
        identifierType: string;
        persistantType: string;
        lookupFields: string;
      };
    };
  };
  responseMessage: string;
  isSuccess: boolean;
  error: any; 
}
interface Channel {
  value: number;
  name: string;
}

interface ChannelResponse {
  result: Channel[];
  responseMessage: string;
  isSuccess: boolean;
  error: null | any; 
}
interface ChannelDataContent {
  language: number;
  subject: string;
  body: string;
}

interface ChannelData {
  from: string;
  cc: string;
  bcc: string;
  content: ChannelDataContent[];
}

interface MessageTemplate {
  id: number;
  tenantId: number;
  type: number;
  name: string;
  isActive: boolean;
  createdBy: number;
  createdByName: string;
  createdDate: string;
  modifiedBY: number | null;
  modifiedByName: string | null;
  modifiedDate: string | null;
  isPersonalized: boolean;
  departmentIds: number[];
  channelData: ChannelData;
}

interface TemplatesResponse {
  result: {
    myRequest: null;
    pendingMessageTemplates: null;
    approvedMessageTemplates: null;
    allMessageTemplates: MessageTemplate[];
    IsWorkFlowEnabled: boolean;
  };
  responseMessage: string;
  isSuccess: boolean;
  error: null | any; 
}





@Injectable({
  providedIn: 'root'
})

export class UsersService  extends ApiService{
  token: string='';
  basepath = environment.DEV_ENDPOINT

  
 dataSourceErrorSubject = new Subject<void>();
  puffinId: string = environment.X_Mt_Puffin_Id
  // dataSourceError$ = this.dataSourceErrorSubject.asObservable();

  triggerDataSourceError() {
    this.dataSourceErrorSubject.next();
  }

  GetUsers(data:object){
    let reqHeader:HttpHeaders = this.getRequestHeaders()
    return this.http.get<string>(`${this.basepath}User/v1/users` ,{ headers: reqHeader });
    }

  DeactivateUser(data:object){
    let reqHeader:HttpHeaders = this.getRequestHeaders()
    return this.http.post<string>(`${this.basepath}User/v1/deactivate` , data,{ headers: reqHeader });
  }

  getGroups(data:object){
    let reqHeader:HttpHeaders = this.getRequestHeaders()
    return this.http.post<string>(`${this.basepath}Group/v1/groups` , data ,{ headers: reqHeader });
    }

    getGroupById(data:object){
      let reqHeader:HttpHeaders = this.getRequestHeaders()
      return this.http.post<string>(`${this.basepath}Group/v1/group` , data ,{ headers: reqHeader });
      }
    GetUserDetail(data:object){
      let reqHeader:HttpHeaders = this.getRequestHeaders()
      let userUrl=`${this.basepath}User/v1/user`;
      return this.http.post<UserDetailResponse>(userUrl ,data,{ headers: reqHeader });
    }

  getRoles(data:object){
    let reqHeader:HttpHeaders = this.getRequestHeaders()
    return this.http.get<string>(`${this.basepath}Role/v1/roles` ,{ headers: reqHeader });
  }

GetDepartments(data:object){
  let reqHeader:HttpHeaders = this.getRequestHeaders()
  return this.http.get<string>(`${this.basepath}Dropdown/v1/departments` ,{ headers: reqHeader });
}
departments(data:object){
  let reqHeader:HttpHeaders = this.getRequestHeaders()
  return this.http.get<string>(`${this.basepath}Department/v1/departments` ,{ headers: reqHeader });

}
Getchannels(){
  let reqHeader:HttpHeaders = this.getRequestHeaders()
  return this.http.get<ChannelResponse>(`${this.basepath}Dropdown/v1/channels` ,{ headers: reqHeader });
}
Getgroupchannels(data:object){
  let reqHeader:HttpHeaders = this.getRequestHeaders()
  return this.http.post<string>(`${this.basepath}Group/v1/groupChannels`,data,{ headers: reqHeader }); 

}
GetDepartmentById(data:object){
  let reqHeader:HttpHeaders = this.getRequestHeaders()
  return this.http.post<string>(`${this.basepath}Department/v1/department`,data,{ headers: reqHeader }); 
}
Addgroup(data:object){
  let reqHeader:HttpHeaders = this.getRequestHeaders()
  return this.http.post<string>(`${this.basepath}Group/v1/create`,data,{ headers: reqHeader }); 

}
Getmessagetemplatescampaign(data:object){
  let reqHeader:HttpHeaders = this.getRequestHeaders()
  return this.http.post<string>(`${this.basepath}Group/v1/create`,data,{ headers: reqHeader }); 

}
sideMenu(data:object){
  let reqHeader:HttpHeaders = this.getRequestHeaders()
  return this.http.get<string>(`${this.basepath}Utility/v1/menus` ,{ headers: reqHeader });
}

GetMasterpermission(data:object){
  let reqHeader:HttpHeaders = this.getRequestHeaders()
  return this.http.get<string>(`${this.basepath}Utility/v1/masterPermissions` ,{ headers: reqHeader });
}

// Gettenantroles(){
//   let reqHeader:HttpHeaders = this.getRequestHeaders()
//   return this.http.get(`${this.basepath}Utility/v1/tenants` ,{ headers: reqHeader });
// }
Getroles(data:object){
  let reqHeader:HttpHeaders = this.getRequestHeaders()
  return this.http.get<string>(`${this.basepath}Utility/v1/roles` ,{ headers: reqHeader });
}


GetRoleByID(data:object){
  let reqHeader:HttpHeaders = this.getRequestHeaders()
  return this.http.post(`${this.basepath}Role/v1/rolePermissions`,data,{ headers: reqHeader });
}
// Cancelcampaign(data:any){
//   let reqHeader:HttpHeaders = this.getRequestHeaders()
//   return this.http.post(`${this.basepath}Campaign/v1/cancel`,data,{ headers: reqHeader });
// }

GetRole(data:object){
  let reqHeader:HttpHeaders = this.getRequestHeaders()
  return this.http.post(`${this.basepath}Role/v1/role`,data,{ headers: reqHeader });
}


GetTenantIID(data:Object){
  let reqHeader:HttpHeaders = this.getRequestHeaders()
  return this.http.get(`${this.basepath}Utility/v1/iids` ,{ headers: reqHeader });
}

GetTenantUIID(data:object){
  let reqHeader:HttpHeaders = this.getRequestHeaders()
  return this.http.post(`${this.basepath}Utility/v1/uids` ,data,{ headers: reqHeader });
}

AddUser(data:object){
  let reqHeader:HttpHeaders = this.getRequestHeaders()
  return this.http.post(`${this.basepath}User/v1/create` ,data,{ headers: reqHeader });
}

CreateDepartment(data:object){
  let reqHeader:HttpHeaders = this.getRequestHeaders()
  return this.http.post(`${this.basepath}Department/v1/create` ,data,{ headers: reqHeader });
}

CreateRole(data:object){
  let reqHeader:HttpHeaders = this.getRequestHeaders()
  return this.http.post(`${this.basepath}Role/v1/create` ,data,{ headers: reqHeader });
}
GetMessageTemplates(data:object){
  let reqHeader:HttpHeaders = this.getRequestHeaders()
  return this.http.post<TemplatesResponse>(`${this.basepath}MessageTemplate/v1/templates` , data,{ headers: reqHeader });
  }
  GetMessageTemplate(data:object){
    let reqHeader:HttpHeaders = this.getRequestHeaders()
    return this.http.post<string>(`${this.basepath}MessageTemplate/v1/template` , data,{ headers: reqHeader });
    
    }
    AddMessageTemplate(data:object){
      let reqHeader:HttpHeaders = this.getRequestHeaders()
      return this.http.post<string>(`${this.basepath}MessageTemplate/v1/create` , data,{ headers: reqHeader });
      }
      UpdateMessageTemplate(data:object){
        let reqHeader:HttpHeaders = this.getRequestHeaders()
        return this.http.post<string>(`${this.basepath}MessageTemplate/v1/update` , data,{ headers: reqHeader });
        }
        IsTemplateAssociatedToService(data:object){
          let reqHeader:HttpHeaders = this.getRequestHeaders()
          return this.http.post<string>(`${this.basepath}MessageTemplate/v1/IsTemplateAssociatedToService` , data,{ headers: reqHeader });
          }
          getMessageTemplateTypes(){
            let reqHeader:HttpHeaders = this.getRequestHeaders()
            return this.http.get<TemplateTypesResponse>(`${this.basepath}MessageTemplate/v1/templateTypes` ,{ headers: reqHeader });
          }
          GetConnectors(){
            let reqHeader:HttpHeaders = this.getRequestHeaders()
            return this.http.get(`${this.basepath}Dropdown/v1/connectors` ,{ headers: reqHeader });
          }
          Getlanguages(){
            let reqHeader:HttpHeaders = this.getRequestHeaders()
            return this.http.get<languageResponse>(`${this.basepath}Dropdown/v1/languages` ,{ headers: reqHeader });
         
          }
        
          GetcampaignTypes(){
            let reqHeader:HttpHeaders = this.getRequestHeaders()
            return this.http.get<CampaignTypesResponse>(`${this.basepath}Dropdown/v1/campaignTypes` ,{ headers: reqHeader });
         
          }
          GetqueueTypes(){
            let reqHeader:HttpHeaders = this.getRequestHeaders()
            return this.http.get<QueueTypesResponse>(`${this.basepath}Dropdown/v1/queueTypes` ,{ headers: reqHeader });
         
          }
          GetsubscriptionValue(){
            let reqHeader:HttpHeaders = this.getRequestHeaders()
            return this.http.get(`${this.basepath}Dropdown/v1/subscriptionValue` ,{ headers: reqHeader });
         
          }
          Getshortcodes(){
            let reqHeader:HttpHeaders = this.getRequestHeaders()
            return this.http.get<ShortcodesResponse>(`${this.basepath}Dropdown/v1/shortcodes` ,{ headers: reqHeader });
         
          }
          Getsmtp(){
            let reqHeader:HttpHeaders = this.getRequestHeaders()
            return this.http.get<SmtpTypeResponse>(`${this.basepath}Dropdown/v1/smtp` ,{ headers: reqHeader });
         
          }
          Getsubscriptions(){
            let reqHeader:HttpHeaders = this.getRequestHeaders()
            return this.http.get(`${this.basepath}Dropdown/v1/subscriptions` ,{ headers: reqHeader });
         
          }
          GetdataSourceTypes(){
            let reqHeader:HttpHeaders = this.getRequestHeaders()
            return this.http.get(`${this.basepath}Dropdown/v1/dataSourceTypes` ,{ headers: reqHeader });
         
          }
          GetgroupTypes(){
            let reqHeader:HttpHeaders = this.getRequestHeaders()
            return this.http.get(`${this.basepath}Dropdown/v1/groupTypes` ,{ headers: reqHeader });
         
          }
          Getcampaigns(data:object){
            let reqHeader:HttpHeaders = this.getRequestHeaders()
            return this.http.post<CampaignDetailsResponse>(`${this.basepath}Campaign/v1/campaigns` ,data,{ headers: reqHeader });
         
          }
          Getcampaign(data:object){
            let reqHeader:HttpHeaders = this.getRequestHeaders()
            return this.http.post<CampaignDetailResponse>(`${this.basepath}Campaign/v1/campaign` ,data,{ headers: reqHeader });
         
          }

          Getgroupsubset(data:object){
            let reqHeader:HttpHeaders = this.getRequestHeaders()
            return this.http.post<GroupSubsetResponse>(`${this.basepath}Dropdown/v1/groupSubset` ,data,{ headers: reqHeader });
         
          }
          GetmessageTemplates(data:object){
            let reqHeader:HttpHeaders = this.getRequestHeaders()
            return this.http.post(`${this.basepath}Dropdown/v1/messageTemplate` ,data,{ headers: reqHeader });
         
          }
          GetsubscriptionDataLevel(){
            let reqHeader:HttpHeaders = this.getRequestHeaders()
            return this.http.get(`${this.basepath}Dropdown/v1/subscriptionDataLevel` ,{ headers: reqHeader });
         
          }
          DeleteMessageTemplate(id:object){
            let reqHeader:HttpHeaders = this.getRequestHeaders()
            return this.http.delete<string>(`${this.basepath}MessageTemplate/v1/delete`, { headers: reqHeader, body:id });
          }
          ApproveRejectMessageTemplateByWorkFlow(data:object){
            let reqHeader:HttpHeaders = this.getRequestHeaders()
            return this.http.post<string>(`${this.basepath}MessageTemplate/v1/ApproveRejectMessageTemplateByWorkFlow` , data,{ headers: reqHeader });
          }
          UpdateMessageTemplateWorkflow(data:object){
            let reqHeader:HttpHeaders = this.getRequestHeaders()
            return this.http.post<string>(`${this.basepath}MessageTemplate/v1/UpdateMessageTemplateWorkflow` , data,{ headers: reqHeader });
          }
          UpdateMessageAssetPathXml(data:object){
            let reqHeader:HttpHeaders = this.getRequestHeaders()
            return this.http.post<string>(`${this.basepath}MessageTemplate/v1/UpdateMessageAssetPathXml
            ` , data,{ headers: reqHeader });
          }
         createCampaign(data:object){
            let reqHeader:HttpHeaders = this.getRequestHeaders()
            return this.http.post<string>(`${this.basepath}Campaign/v1/create` , data,{ headers: reqHeader });
          }
          uploadfile(data:object){
            // debugger
            let reqHeader:HttpHeaders = this.getRequestHeaders(true)
            return this.http.post<string>(`${this.basepath}Campaign/v1/uploadData` , data,{ headers: reqHeader });
          }

getRequestHeaders(isMultiPart?:boolean){
  
  let token:any = localStorage.getItem('userDetails')
    token = JSON.parse(token)
    this.token = token.token
    let reqHeader = new HttpHeaders({ 
      // 'Content-Type': isMultiPart ? `multipart/form-data`: `application/json; charset=utf-8`,
      'Accept': isMultiPart ? `*/*`: `*/*`,
      'Access-Control-Allow-Headers': `Content-Type`,
      'Access-Control-Allow-Origin': `*`,
      'X-MT-Puffin-Id': this.puffinId,
     'Authorization': 'Bearer ' + this.token
     });
     return reqHeader;
}

}
