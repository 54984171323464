import { Action, createReducer, on } from '@ngrx/store';
import { GetDepartmentById, GetDepartmentByIdBody,GetDepartmentByIdResponse, AllDepartments } from '../../types/user-management/GetDepartmentById.types';
import * as actions from '../../actions';

export interface State {
    GetDepartmentById: GetDepartmentByIdResponse;
  defaultPreferredWorkshop: GetDepartmentById[];
  isLoading: boolean;
  isLoadingSuccess: boolean;
  isLoadingFailure: string;
  body: GetDepartmentByIdBody;
}

export const initialState: State = {
    GetDepartmentById:{
    result:null,
    approvedRequests: null,
    isWorkFlowEnabled:null,
    myRequest:null,
    pendingRequests: null,
    allDepartments:[]
  },
  defaultPreferredWorkshop:[], 
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: '',
  body:{
  },
};

const funReducer = createReducer(
  initialState,
  on(actions.GetDepartmentById, (state,action) => ({...state, isLoading: true})),
  on(actions.GetDepartmentByIdSuccess, (state, result) => {
    return{
      ...state,
      defaultPreferredWorkshop: result.response,
      GetDepartmentById: result.response,
      isLoading: false,
      isLoadingSuccess: true
    }}),
  on(actions.GetDepartmentByIdFailure, (state, result) => ({
      ...state,
      GetDepartmentById: result,
      isLoading: false,
      isLoadingSuccess: false,
      isLoadingFailure:result.error
  })),
);

export function reducer(state: State | undefined, action: Action): any {
  return funReducer(state, action);
}

