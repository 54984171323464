import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as reducer from '../../reducers/dictionary/UpdateMessageAssetPathXml.reducers'

const state = createFeatureSelector<reducer.State>('UpdateMessageAssetPathXml');


export const selectLoadingUpdateMessageAssetPathXml = createSelector(
  state,
  state => state.isLoading
);

export const selectStatesUpdateMessageAssetPathXml= createSelector(
  state,
  state => state
);

export const selectStatesUpdateMessageAssetPathXmlBody= createSelector(
  state,
  state => state.body
);

export const selectStatesUpdateMessageAssetPathXmlData= createSelector(
  state,
  state => state.UpdateMessageAssetPathXml
);
