import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-adminpopup',
  templateUrl: './adminpopup.component.html',
  styleUrls: ['./adminpopup.component.scss']
})
export class AdminpopupComponent {
  deprole: any;
  flag: string;
  constructor( @Inject(MAT_DIALOG_DATA) public data: any){
    this.flag = data?.flag
    if(this.flag == 'role'){
      this.deprole = data?.role

    }else{

      this.deprole = data?.department
      const arrayUniqueByKey6 = [...new Map(data.department.map((item:any) =>
        [item['departmentName'], item])).values()];
        this.deprole = [...arrayUniqueByKey6];    }



  }

}
