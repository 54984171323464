import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UsersService } from '../../service/users/users.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as fromRoot from 'src/app/app-state';
import * as moment from 'moment';
import * as actions from 'src/app/app-state/actions';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { AllDepartments, MyRequest, getDepartmentResponse } from 'src/app/app-state/types';

    export interface tenantiid{
        id: number,
        tenant_Id: number,
        iid: number
    }
    interface TenantIIDsResponse {
      result: {
        iids: tenantiid[];
      };
      responseMessage: string;
      isSuccess: boolean;
      error: null;
    }
    export interface tenantUiid{
      id: number,
      uid: number,
      iid: number
  }
  export interface tenantROle{
    id: number,
    tenant_Id : number,
    tenant_Name: string,
    name: string,
    description: string,
    is_Active : null,
    created_By: number,
    created_By_Name: string,
    created_On: string,
    modified_By: number,
    modified_By_Name: string,
    modified_On: string,
    isWorkFlow: false,
    action: 0,
    approvedBy: 0,
    approvedOn: string,
    workFlowStatus: number,
    masterId: number,
    approvedByName: null,
    remark: null
}
    export interface department{
      id: number,
      channelId: number,
      tenant_Id: number,
      tenant_Name:string,
      name_English:string,
      name_Arabic:string,
      value: string,
      "is_Active": null,
      "created_By": number,
      "created_By_Name": string,
      "created_On": string,
      "modified_By": number,
      "modified_By_Name": null,
      "modified_On":string,
      "is_In_Use": null,
      "isWorkFlow": null,
      "action": number,
      "approvedBy": number,
      "approvedOn":string,
      "workFlowStatus": number,
      "masterId": number,
      "approvedByName": null,
      "remark": null
  }
  interface DepartmentResponse {
    result: DepartmentItem[];
    responseMessage: string;
    isSuccess: boolean;
    error: null;
  }
  
  interface DepartmentItem {
    value: number;
    name: string;
  }
  

@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.scss']
})
export class UserAddComponent  {
  destroy$: Subject<boolean> = new Subject<boolean>();
  showPassword: boolean = false;

  public UserForm: FormGroup;
  departmentList:DepartmentItem[]=[]
  submitted: Boolean = false;
  tenantiid: object | any;
  tenantuiid: object| any;
  tenantrole: string|any;
  checkValue:boolean =true;
  activeornot: boolean=true;
  isActive: boolean=true;
  constructor(private UsersService:UsersService,private MatDialog:MatDialog, private store:Store,
    @Inject(MAT_DIALOG_DATA) public data: any, ){
    
    this.store.dispatch(
      actions.getDepartments({}))
    this.store
    .select(fromRoot.selectStatesgetDepartmentsData)
    .pipe(takeUntil(this.destroy$))
    .subscribe((data) => {
      this.departmentList = data?.result
      
   
   })

    this.UsersService.Getroles({}).subscribe((items)=>{

      this.tenantrole = items
        })
    
    this.UsersService.GetTenantIID({}).subscribe((data)=>{console.log(data,'sss')

      this.tenantiid = data
              })

        this.UsersService.GetTenantUIID({}).subscribe((data)=>{
          this.tenantuiid = data
            })

    this.UserForm = new FormGroup({

      name : new FormControl('',[Validators.required]),
      password : new FormControl('',[Validators.required,Validators.minLength(6)]),
      username : new FormControl('',[Validators.required]),
      rolesControl : new FormControl([],[Validators.required]),
      iidControl:  new FormControl([],[Validators.required]),
      uiidControl : new FormControl([],[Validators.required]),
      DepartmentControl : new FormControl([],[Validators.required]),

    
      })
  }
 
  removerole(index:number){
    let arr = [...this.UserForm.value.rolesControl]
    arr.splice(index,1)
    this.UserForm.controls['rolesControl'].setValue(arr);

  }
 
  activenot(){
    this.isActive = !this.isActive;
    
  }
  validateInputLength(){
    if (this.UserForm.value.name.length > 50) {
      this.UsersService.toast.snackbarSucces('Character limit exceeded (max: 50)');
    }
  }
  onSubmit(){
    if(this.UserForm.value.name && this.UserForm.value.name.length > 50){
      console.log("!!!!!!!!!!!!!")
      this.submitted = false;
      this.UsersService.toast.snackbarSucces('Character limit exceeded (max: 50)');
      return
    }
    this.submitted = true;

    if(this.UserForm.valid){
      console.log(this.UserForm.value,'iiooooo')
      this.UsersService.AddUser({
          "userId": 0,
          "displayName": this.UserForm.value.name,
          "loginName": this.UserForm.value.username,
          "password": this.UserForm.value.password,
          "isActive": this.isActive,
          "tenantId": 1,

       
          "tenantIIDId": parseInt(this.UserForm.value.iidControl),
          "tenantUIId":  
           parseInt(this.UserForm.value.uiidControl),
          "roleIds": this.UserForm.value.rolesControl.map((val:MyRequest)=>{
             
            return parseInt(val.id)
          }),
          "departmentIds": this.UserForm.value.DepartmentControl.map((val:object|any)=>{   
              return parseInt(val)
            })
      
    }).subscribe(()=>{
    this.MatDialog.closeAll()
    this.UsersService.toast.snackbarSucces("User Added Successfully!");


    })
    }
}
preventSpace(event: any): void {
  console.log(event,"e")
  const inputValue: string = event.target.value;
  
  if (event.code === "Space" ) {
      event.preventDefault();
  }

}

}
