import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})


export class AuthService   {
  headers: HttpHeaders;
  puffinId :string= environment.X_Mt_Puffin_Id
  Dev_Env :string= environment.DEV_ENDPOINT


  constructor(private http: HttpClient) {
    const headerDict = { 
      'Content-Type': `application/json; charset=utf-8`,
      'Accept': `application/json`,
      'Access-Control-Allow-Headers': `Content-Type`,
      'Access-Control-Allow-Methods': `POST, GET, OPTIONS, PUT`,
      'Access-Control-Allow-Origin': `*`,
      'X-Mt-Puffin-Id': this.puffinId,
      
    }
    this.headers = new HttpHeaders(headerDict);
 
  }


  LoginUser(data: object) {
 
    // let loginurl = `https://twowayserver.future-club.com/api/User/v1/login`;
    let loginurl = `${this.Dev_Env}User/v1/login`;
    return new Promise((resolve, reject) => {
      this.http.post(loginurl,data , { headers: this.headers }).subscribe(
        (res:object) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }


  
}
