<div class="header-wrapper">
    <span>View Role</span>
    <button mat-icon-button class="btn close-button" [mat-dialog-close]="true">
        <i class="fa fa-close"></i>
      </button>
  </div>
  

  <div mat-dialog-content>
    <div class="container-fluid">
  
        <div class="row mb-3">
            <div class="col-md-6">
                <mat-form-field class="example-full-width">
                    <mat-label>Name</mat-label>
                    <input matInput value="{{data?.showdata.name}}" readonly="readonly" >
                    
                  </mat-form-field>
             
                     </div>
            <div class="col-md-6">
                <div class="check-toggle">
                    <p>Active</p>
                    <label class="switch-action">
                        <input type="checkbox">
                        <span class="slider round"></span>
                    </label>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-6">
  
                <mat-form-field appearance="fill">
                    <mat-label>Description</mat-label>
                    <input matInput value="{{data?.showdata.description}}" readonly="readonly" >

                  </mat-form-field>
                  </div>
           
        </div>
       
      </div>
    </div>
    <div mat-dialog-actions>
      <button class="btn btn-cancle" mat-dialog-close>Cancel</button>
    </div>