import { Component, ViewChild } from '@angular/core';
import { ProfileComponent } from '../../../commonModule/components/profile/profile.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { EditPopupComponent } from '../edit-popup/edit-popup.component';
import { DeletePopupComponent } from '../delete-popup/delete-popup.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import * as actions from 'src/app/app-state/actions';
import * as moment from 'moment';
import * as fromRoot from 'src/app/app-state';
import { Subject, takeUntil } from 'rxjs';
import { UsersService } from 'src/app/modules/usermanagement/service/users/users.service';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss']
})
export class TemplateComponent {
  SortVal:string='first'  
  userSearch:string='';
  destroy$: Subject<boolean> = new Subject<boolean>();

  moment=moment
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  pageSize:number = 10;
  pageIndex: number = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  dataSource = new MatTableDataSource<object>([]);

  displayedColumns: string[] = [
    'name',
    'type',
    'createdByName',
    'createdDate',
    'modifiedByName',
    'modifiedDate',
    'action',
  ];
  filterValue: string='';
  filter: string='';
  templateeditid: number|any;
  templates:Array<any>=[];
  Channels:any=Array<[]>;
  channelval:number=0
  loadingData: boolean = true;
tenplteName: any
originalData: any[] = [];
filteredData: any[] = []
  constructor(private router: Router, public dialog: MatDialog,private readonly store: Store, private service:UsersService,private activateRoute:ActivatedRoute
    ) {
      this.service.Getchannels().subscribe((res:object|any)=>{
        this.Channels=res?.result;
       })
this.Gettemplates(this.channelval)
   }

   Clicktemplate(eve:any){
    this.tenplteName = eve
console.log(this.tenplteName)
   }


   Gettemplates(eve:any){
    this.loadingData = true;
    setTimeout(()=>{
      this.dataSource.data = []
      console.log(eve, this.tenplteName,"dq")
      this.service.GetMessageTemplates({
        "messageType": eve?.value || 0 ,
        // "templateName":this.tenplteName || 'Email'
        "templateName": ''
      })  
      .subscribe((res:object|any)=>{
        this.loadingData = false;
        this.templates =  res?.result?.allMessageTemplates;
        this.dataSource.data = res?.result?.allMessageTemplates;
        console.log(this.dataSource.data,"ds")
        
        this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        this.Filters()
      })
      // this.userSearch = ''
    },0)
   
   }
   
   ngOnInit(): void {
    this.activateRoute.paramMap.subscribe(params => {
      this.templateeditid = params.get('id');
    })
    this.activateRoute.queryParams.subscribe((params: object|any) => {
      if(params){
      this.pageSize = params.page;
      this.pageIndex = params.pageIndex;
      this.dataSource.paginator = this.paginator;
      }
      setTimeout(() => {
        this.paginator.pageIndex = this.pageIndex;
        this.paginator.pageSize = this.pageSize;
      }, 0);
    });

    this.service.dataSourceErrorSubject.subscribe((getres:any)=>{
      console.log(getres,'wewe3223')
         this.service.toast.snackbarSucces("No message templates were found for the specified criteria");
         this.dataSource.data = []
         this.loadingData = false;

      
    })

    
  }
  openpage(event:object|any){
    this.activateRoute.queryParams.subscribe((params) => {
            this.router.navigate(['/template'], {
              queryParams: {
                page: event.pageSize,
                pageIndex: event?.pageIndex,
              },
            });
          });
  }
   Edittemplate(item:object|any,val:string){
    console.log(item,'item')
    if(val === 'view'){
      this.router.navigate(['/template-view/'+item?.id],{queryParams:{temp:'view'}})
    }
    if(val === 'edit'){

      this.router.navigate(['/template-edit/'+item] ,{
        queryParams: {
          page: this.pageSize,
          pageIndex: this.pageIndex,
        },
      } )  
    }   
    
  }
  addtemplate(event:any,val:any){
    if(val === 'add'){
      this.activateRoute.queryParams.subscribe((params) => {
        this.router.navigate(['/template'], {
          queryParams: {
            page: event.pageSize,
            pageIndex: event?.pageIndex,
          },
        });
      });
      this.router.navigate(['/create-template'] ,{
        queryParams: {
          page: this.pageSize,
          pageIndex: this.pageIndex,
        },
      } )  
    }  
  }

  usericonDialog() {
    this.dialog.open(ProfileComponent,{
      panelClass:['common-modalbox'],
    })
      }
    
      deleteiconDialog(templateID:number) {
        this.dialog.open(DeletePopupComponent,{
          data:{
            templateID:templateID ,
           
          },
          panelClass:['common-modalbox-delete'],
        })
    
      }
      Filters(): void {
        let filteredData = this.templates;
      
        if (this.userSearch) {
          filteredData = filteredData.filter((val: any) =>
            // console.log(val,"vsl")
            val?.name?.toLowerCase().trim().includes(this.userSearch.toLowerCase().trim())
  
        );
        console.log(filteredData,"data12")
        }
      
        if (this.channelval) {
          console.log(filteredData,"data12")
          filteredData = filteredData.filter((val: any) =>
            // console.log(val.type,"id",this.channelval,)
            val?.type == this.channelval
            // val.type.includes(this.channelval)
        );
        }
        if (this.SortVal === 'last') {
          filteredData = [...filteredData].reverse();
        }
      
        this.dataSource.data = filteredData;
      }
  applyFilter(event:Object|null|any) {
    
      if(event){
         this.Filters()
      }else {
        this.dataSource.data = this.templates
      }

  }
  resetSorting() {
    if (this.sort) {
      this.sort.active = "";
      this.sort.direction = "";
    }
    this.dataSource.data = [...this.dataSource.data];
  }
    
  Sort(e:string){
    this.SortVal = e;
    this.Filters()
  
   
}
Reset() {
  this.userSearch = "";
  this.channelval = 0;
  this.SortVal = "first";
  // this.Filters()
  this.Gettemplates(this.channelval)
}
}
