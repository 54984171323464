import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as reducer from '../../reducers/dictionary/DeleteMessageTemplate.reducers'

const state = createFeatureSelector<reducer.State>('DeleteMessageTemplate');


export const selectLoadingDeleteMessageTemplate = createSelector(
  state,
  state => state.isLoading
);

export const selectStatesDeleteMessageTemplate= createSelector(
  state,
  state => state
);

export const selectStatesDeleteMessageTemplateBody= createSelector(
  state,
  state => state.body
);

export const selectStatesDeleteMessageTemplateData= createSelector(
  state,
  state => state.DeleteMessageTemplate
);
