import { Component, ViewChild } from '@angular/core';
import { UsersService } from '../modules/usermanagement/service/users/users.service';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

interface Channel {
  value: number;
  name: string;
}

interface ChannelResponse {
  result: Channel[];
  responseMessage: string;
  isSuccess: boolean;
  error: null | any; 
}
interface Smtp {
  value: number;
  name: string;
}

interface ChannelDataContent {
  language: number;
  subject: string;
  body: string;
}

interface ChannelData {
  from: string;
  cc: string;
  bcc: string;
  content: ChannelDataContent[];
}

interface MessageTemplate {
  id: number;
  tenantId: number;
  type: number;
  name: string;
  isActive: boolean;
  createdBy: number;
  createdByName: string;
  createdDate: string;
  modifiedBY: number | null;
  modifiedByName: string | null;
  modifiedDate: string | null;
  isPersonalized: boolean;
  departmentIds: number[];
  channelData: ChannelData;
}

@Component({
  selector: 'app-select-template-popup',
  templateUrl: './select-template-popup.component.html',
  styleUrls: ['./select-template-popup.component.scss']
})
export class SelectTemplatePopupComponent {
  selection2 = new SelectionModel<SelectTemplatePopupComponent>(
    false,
    []
  );
  templatesearch:string ='';
  Channels:Channel[]=[];
  smtps: Smtp[]=[];
  templates: MessageTemplate[]=[];
  moment=moment
  dataSource = new MatTableDataSource<object>([]);
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  pageSize:number = 10;
  pageIndex: number = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  loadingData: boolean = true;
  displayedColumns: string[] = [
    'templateName',
    'messageTemplateType',
    'createdBy',
    'created_On',
    
  ];
  selectedelement: MessageTemplate[]=[];
  constructor(private userservice:UsersService,
    public dialogRef: MatDialogRef<SelectTemplatePopupComponent>,
    ){
    this.userservice.Getchannels().subscribe(res=>{
      this.Channels=res?.result;
     })

   
     this.userservice.Getsmtp().subscribe(val=>{
      this.smtps = val?.result
      
     })
     this.SelectChannel(0)
    //  this.userservice.GetConnectors({}).subscribe((val)=>{
    //   this.connectors = val?.result
      
    //  })
  }

  ngOnInit(): void {
    this.userservice.dataSourceErrorSubject.subscribe(getres=>{
      console.log(getres,'wewe3223')
         this.userservice.toast.snackbarSucces("No message templates were found for the specified criteria");
         this.loadingData = false;
         this.dataSource.data = []

      
    })
  }

  SelectChannel(eve:any){
    console.log(eve,'llkkkkk')
    this.loadingData=true;
    setTimeout(()=>{
    this.userservice.GetMessageTemplates({
      "messageType": eve?.value || 0,
     "templateName": ""}).subscribe(res=>{
      console.log(res,'kkkkk')
      this.dataSource.data = []
      this.templates =  res?.result?.allMessageTemplates;
      this.dataSource.data =res?.result?.allMessageTemplates
      this.loadingData = false
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      
      console.log(this.dataSource.data,"dad")
     })
    },100)
  }

  selectcheck(ev:any,elem:any){
    ev.source.checked
    ? this.selection2.select(elem)
    : this.selection2.deselect(elem);

    console.log( elem,ev,'ewwek32')
    this.selectedelement = elem
    console.log(this.selectedelement,'iooll')
  }
  submit(){
    this.dialogRef.close(this.selectedelement);
  }

  Searchtemplate(event:string): void {

    if(event){
      this.dataSource.data = this.templates.filter(val =>
        (val?.name.toLowerCase().trim().includes(event.toLowerCase().trim()) 
        )
      ) 
    }else {
      this.dataSource.data = this.templates
    }
}
}
