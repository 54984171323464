<app-side-menu></app-side-menu>
<div class="header">
    <div class="logo">
        <h4 class="text"><img alt="" class="dashboard-img" src="../../assets/images/dash-icon.png">Campaign Reports </h4>
    </div>

    <div class="toggle-icon">
        <button class="btn btn-theme me-3">Create</button>
            <img alt="" src="../../assets/images/user.png" (click)="usericonDialog();"  style="width: 25px">
    </div>

</div>

<mat-drawer-container class="drawercontainer">
    <div class="main-page-wrapper">
    </div>
    </mat-drawer-container>