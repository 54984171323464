
<app-side-menu></app-side-menu>
<div class="header">
    <div class="logo">
        <h4 class="text"><img alt="" class="dashboard-img" src="../../assets/images/dash-icon.png"> Users </h4>
    </div>

    <div class="toggle-icon">
        <img alt="" src="../../assets/images/user.png" (click)="usericonDialog();"  style="width: 25px">
    </div>
    

</div>

<mat-drawer-container class="drawercontainer">
    <div class="main-page-wrapper">
        <div class="main-content-area" [ngClass]="{ 'disabled-form': loadingData }">
            <div class="example-form">
                <div class="search-block d-flex align-items-center mb-2">
                    <div class="toggle-icon">
                        <button (click)="addUserDialog();" class="btn btn-theme"><i class="fa fa-plus"></i></button>
                    </div>
                    <mat-form-field appearance="outline" class="mx-3 user-search-input">
                        <input matInput placeholder="Search" [(ngModel)]="userSearch"  (input)="SearchUser(userSearch)"/>
                        <img alt="" src="../../assets/images/Icons_Search.svg" class="icon-search" />
                    </mat-form-field>

                    
                    <mat-form-field class="form-data1 user-search-input user-date-picker" appearance="outline" #perform [floatLabel]="'always'">
                        <mat-date-range-input [rangePicker]="picker" [max]="maxDate">
                            <input matStartDate #dateRangeStart placeholder="Start date"  name="dateform.startdate" [(ngModel)]="start" (dateChange)="dateRangeChange(start,'start')" >
                            <input matEndDate #dateRangeEnd placeholder="End date"  name="dateform.enddate" [(ngModel)]="end" (dateChange)="dateRangeChange(end,'end')" 
                            >
                        </mat-date-range-input>
    
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                        <span class="closeicon"  *ngIf="start || end" (click)="closeDatefilter()"> <i class="fa fa-times-circle"></i></span>
                    </mat-form-field>


                    <div class="">
                      <div class="form-field-wrapper">
                        <mat-form-field class="serchbox user-search-input " appearance="fill">
                          <mat-label class="sort-lable">Sort</mat-label>
                          <mat-select class="HELLO" [(ngModel)]="SortVal" (selectionChange)="Sort(SortVal)">
                            <mat-option value="first">First to Last</mat-option>
                            <mat-option value="last">Last to First</mat-option>
    
                          </mat-select>
                        </mat-form-field>
    
                      </div>
                    </div>
                    <div class="btn-bg-box">
                      <button (click)="Reset()" class="btn btn-cancle">Reset</button>
                    </div>
                </div>

                

              

            </div>

            <div class="data-table">
                <table matSort mat-table summary="table" [dataSource]="dataSource"  class="mat-elevation-z8 mat-table">

                    <ng-container matColumnDef="display_Name">
                        <th mat-header-cell *matHeaderCellDef class="" mat-sort-header style="min-width: 85px">
                          <div class="status">
                            MEMBER NAME 
                            <mat-select panelClass="matRole" class="status-bg-value" (selectionChange)="changeStatus()" [(ngModel)]="status">
                              
                              <mat-option [value]="false">
                                <div class="red  check-bg-red">
                                <input type="radio" id="html" name="status" value="Inactive" class="bttn1">Inactive

                                </div>
                            </mat-option>
                              <mat-option [value]="true">
                                <div class="green  check-bg-green ">
                                <input type="radio" id="html" name="status" value="Active"  class="bttn2">Active
                                </div>
                            </mat-option>
                     
                            </mat-select>
                        </div>
                        </th>
                        <td mat-cell *matCellDef="let element" class="" style="min-width: 85px">
                          <div class="view-status">
<span class="display-name-text">
  {{ element.display_Name }}
</span>
                            <div>

                         <svg (click)="userview(element.id)" fill="#0396a6" width="24" height="24" viewBox="0 0 64 64" version="1.1"
                         xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                         xml:space="preserve" xmlns:serif="http://www.serif.com/"
                         style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">

                         <rect id="Icons" x="-896" y="-256" width="1280" height="800" style="fill:none;" />

                         <g id="Icons1" serif:id="Icons">

                             <g id="Strike">

                             </g>

                             <g id="H1">

                             </g>

                             <g id="H2">

                             </g>

                             <g id="H3">

                             </g>

                             <g id="list-ul">

                             </g>

                             <g id="hamburger-1">

                             </g>

                             <g id="hamburger-2">

                             </g>

                             <g id="list-ol">

                             </g>

                             <g id="list-task">

                             </g>

                             <g id="trash">

                             </g>

                             <g id="vertical-menu">

                             </g>

                             <g id="horizontal-menu">

                             </g>

                             <g id="sidebar-2">

                             </g>

                             <g id="Pen">

                             </g>

                             <g id="Pen1" serif:id="Pen">

                             </g>

                             <g id="clock">

                             </g>

                             <g id="external-link">

                             </g>

                             <g id="hr">

                             </g>

                             <g id="info">

                             </g>

                             <g id="warning">

                             </g>

                             <g id="plus-circle">

                             </g>

                             <g id="minus-circle">

                             </g>

                             <g id="vue">

                             </g>

                             <g id="cog">

                             </g>

                             <g id="logo">

                             </g>

                             <g id="radio-check">

                             </g>

                             <g id="eye-slash">

                             </g>

                             <g id="eye">

                                 <path
                                     d="M32.513,13.926c10.574,0.15 19.141,9.894 23.487,18.074c0,0 -1.422,2.892 -2.856,4.895c-0.694,0.969 -1.424,1.913 -2.191,2.826c-0.547,0.65 -1.112,1.283 -1.698,1.898c-5.237,5.5 -12.758,9.603 -20.7,8.01c-8.823,-1.77 -15.732,-9.498 -20.058,-17.629c0,0 1.248,-2.964 2.69,-4.964c0.646,-0.897 1.324,-1.77 2.034,-2.617c0.544,-0.649 1.108,-1.282 1.691,-1.897c4.627,-4.876 10.564,-8.63 17.601,-8.596Zm-0.037,4c-5.89,-0.022 -10.788,3.267 -14.663,7.35c-0.527,0.555 -1.035,1.127 -1.527,1.713c-0.647,0.772 -1.265,1.569 -1.854,2.386c-0.544,0.755 -1.057,1.805 -1.451,2.59c3.773,6.468 9.286,12.323 16.361,13.742c6.563,1.317 12.688,-2.301 17.016,-6.846c0.529,-0.555 1.04,-1.128 1.534,-1.715c0.7,-0.833 1.366,-1.694 1.999,-2.579c0.557,-0.778 1.144,-1.767 1.588,-2.567c-3.943,-6.657 -10.651,-13.944 -19.003,-14.074Z" />

                                 <path
                                     d="M32.158,23.948c4.425,0 8.018,3.593 8.018,8.017c0,4.425 -3.593,8.017 -8.018,8.017c-4.424,0 -8.017,-3.592 -8.017,-8.017c0,-4.424 3.593,-8.017 8.017,-8.017Zm0,4.009c2.213,0 4.009,1.796 4.009,4.008c0,2.213 -1.796,4.009 -4.009,4.009c-2.212,0 -4.008,-1.796 -4.008,-4.009c0,-2.212 1.796,-4.008 4.008,-4.008Z" />

                             </g>

                             <g id="toggle-off">

                             </g>

                             <g id="shredder">

                             </g>

                             <g id="spinner--loading--dots-" serif:id="spinner [loading, dots]">

                             </g>

                             <g id="react">

                             </g>

                             <g id="check-selected">

                             </g>

                             <g id="turn-off">

                             </g>

                             <g id="code-block">

                             </g>

                             <g id="user">

                             </g>

                             <g id="coffee-bean">

                             </g>

                             <g id="coffee-beans">

                                 <g id="coffee-bean1" serif:id="coffee-bean">

                                 </g>

                             </g>

                             <g id="coffee-bean-filled">

                             </g>

                             <g id="coffee-beans-filled">

                                 <g id="coffee-bean2" serif:id="coffee-bean">

                                 </g>

                             </g>

                             <g id="clipboard">

                             </g>

                             <g id="clipboard-paste">

                             </g>

                             <g id="clipboard-copy">

                             </g>

                             <g id="Layer1">

                             </g>

                         </g>

                     </svg>

                     <ng-container *ngIf="element.is_Active == true">
                         <svg class="circle-active" fill="green" width="16px" height="16px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="m0 12c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12c-6.624-.008-11.992-5.376-12-11.999zm2.4 0c0 5.302 4.298 9.6 9.6 9.6s9.6-4.298 9.6-9.6-4.298-9.6-9.6-9.6c-5.299.006-9.594 4.301-9.6 9.599v.001zm4 0c0-3.093 2.507-5.6 5.6-5.6s5.6 2.507 5.6 5.6-2.507 5.6-5.6 5.6c-3.093 0-5.6-2.507-5.6-5.6z"></path></g></svg>
                     </ng-container>
                     <ng-container *ngIf="element.is_Active == false">
                         <svg class="circle-active" fill="red" width="16px" height="16px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="m0 12c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12c-6.624-.008-11.992-5.376-12-11.999zm2.4 0c0 5.302 4.298 9.6 9.6 9.6s9.6-4.298 9.6-9.6-4.298-9.6-9.6-9.6c-5.299.006-9.594 4.301-9.6 9.599v.001zm4 0c0-3.093 2.507-5.6 5.6-5.6s5.6 2.507 5.6 5.6-2.507 5.6-5.6 5.6c-3.093 0-5.6-2.507-5.6-5.6z"></path></g></svg>
                     </ng-container>
                    </div>
                  </div>

                        </td>
                      </ng-container>

                      <ng-container matColumnDef="login_Name">
                        <th mat-header-cell *matHeaderCellDef class="" mat-sort-header style="min-width: 85px">
                          Login Name
                        </th>
                        <td mat-cell *matCellDef="let element" class="" style="min-width: 85px">
                          {{ element.login_Name }} 
                        </td>
                      </ng-container>
    
                      <ng-container matColumnDef="roleName">
                        <th mat-header-cell *matHeaderCellDef class="" mat-sort-header style="min-width: 85px">
                         ROLES
                        </th>
                        <td mat-cell *matCellDef="let element" class="" style="min-width: 85px">
                          {{ element.roles[0]?.roleName }}<span *ngIf="element?.roles?.length" (click)="adminpopupDialog('role',element?.roles);"  class="admin-button" >{{element?.roles?.length}}+</span>

                        
                      </ng-container>
    
                      <ng-container matColumnDef="departmentName">
                        <th mat-header-cell *matHeaderCellDef class="" mat-sort-header style="min-width: 85px">
                         DEPARTMENT
                        </th>
                        <td mat-cell *matCellDef="let element" class="" style="min-width: 85px">
                         {{element?.department[0]?.departmentName}}<span *ngIf="element?.department?.length" (click)="adminpopupDialog('department',element?.department);"  class="admin-button" >{{element?.department?.length}}+</span>

                        </td>
                      </ng-container>
    
                      <ng-container matColumnDef="created_By_Name">
                        <th mat-header-cell *matHeaderCellDef class="" mat-sort-header style="min-width: 85px">
                          CREATED BY
                        </th>
                        <td mat-cell *matCellDef="let element" class="" style="min-width: 85px">
                          {{ element.created_By_Name }}
                           
                        </td>
                      </ng-container>
    
                      <ng-container matColumnDef="created_On">
                        <th mat-header-cell *matHeaderCellDef class="" mat-sort-header style="min-width: 85px">
                         CREATED DATE
                        </th>
                        <td mat-cell *matCellDef="let element" class="" style="min-width: 85px">
                         {{moment(element?.created_On).format('DD-MM-YYYY HH:mm:ss')}}
                        
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="modified_By_Name">
                        <th mat-header-cell *matHeaderCellDef class="" mat-sort-header style="min-width: 85px">
                         MODIFIED BY
                        </th>
                        <td mat-cell *matCellDef="let element" class="" style="min-width: 85px">
                          {{ element.modified_By_Name || '--' }}
                         
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="modified_On">
                        <th mat-header-cell *matHeaderCellDef class="" mat-sort-header style="min-width: 85px">
                          MODIFIED DATE
                        </th>
                        <td mat-cell *matCellDef="let element" class="" style="min-width: 85px">
                          {{ element.vehicleModel }}
                          {{element?.modified_On ? moment(element?.modified_On).format('DD-MM-YYYY HH:mm:ss') : '--' }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="login_On">
                        <th mat-header-cell *matHeaderCellDef class="" mat-sort-header style="min-width: 85px">
                         LAST LOGIN Time
                        </th>
                        <td mat-cell *matCellDef="let element" class="" style="min-width: 85px">
                          {{element?.login_On ?  moment(element?.login_On).format('DD-MM-YYYY HH:mm:ss')  : '--'}}

                        </td>
                      </ng-container>
                      <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef class="" mat-sort-header style="min-width: 85px">
                          ACTION
                        </th>
                        <td mat-cell *matCellDef="let element" class="" style="min-width: 85px">

                          <svg (click)="EditUserDialog(element.id);" width="40" height="24" viewBox="0 0 24 24" fill="var(--shade-of-green)"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M4.5 17.2071V19C4.5 19.2761 4.72386 19.5 5 19.5H6.79289C6.9255 19.5 7.05268 19.4473 7.14645 19.3536L15.6452 10.8548L13.1452 8.35485L4.64645 16.8536C4.55268 16.9473 4.5 17.0745 4.5 17.2071Z"
                              stroke="#5ce61b" />
                          <path
                              d="M15.0897 6.4103L17.5897 8.9103L18.7929 7.70711C19.1834 7.31658 19.1834 6.68342 18.7929 6.2929L17.7071 5.20711C17.3166 4.81658 16.6834 4.81658 16.2929 5.20711L15.0897 6.4103Z"
                              stroke="#5ce61b" />
                      </svg>
                      <button [ngClass]="element?.is_Active == false ?  'btn-deactive' : 'btn-active' " (click)="DeactivateUser(element?.id,element)" >{{element?.is_Active == false ? 'Activate' : 'Deactivate'}}</button>
                        </td>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns" style="cursor: pointer">
                      </tr>
                </table>
                <mat-paginator #paginator [pageSize]="pageSize" [pageIndex]="pageIndex" class="calling-list-pagenation"
  [pageSizeOptions]="pageSizeOptions" showFirstLastButtons  aria-label="Select page"></mat-paginator>
  <!-- <ng-container *ngIf="loadingData" > -->
    <div class="loader" *ngIf="loadingData">
    <div class="spinner-border" role="status"> 
    </div>
    <span >Loading...</span>
     <!-- <mat-spinner diameter="40" color="accent"></mat-spinner>
     <div>Loading...</div>-->
    </div>
   
 <!-- </ng-container> -->


            </div>
        </div>
    </div>
    <app-footer></app-footer>

</mat-drawer-container>