
<div class="campaign" id="TestDIV"><div class="outerbox">
    <div class="innerbox">
        <div class="info1">
            <div class="campaignlabel">
               <span class="property"> Campaign Name </span>
            </div>
            
          
            <div class="campaigntime">  
                 <i class="far">&#xf017;</i> 
                <label class="time" name="class">4:30PM(AST)</label> </div>
   
        </div>
        <div class="info2">
            <div class="campaignlabel">
                From
            </div> 
            <div class="campaigndata">
                <label name="data">$data</label>
            </div>

        </div>
        <div class="info3">
            <div class="campaignlabel">
                Type
            </div> 
            <div class="campaigndata">
                <label name="data">$data</label>
            </div>

        </div>
            
        <div class="info4">
            <div class="campaignlabel">
                Recepients
            </div> 
            <div class="campaigndata">
                <label name="data">$data</label>
            </div>

        </div>
            
        <div class="info5">
            <div class="campaignlabel">
                Language
            </div> 
            <div class="campaigndata">
                <label name="data">$data</label>
            </div>

        </div>

        <a href="#"><i class="material-icons" style="font-size:36px;float:right;margin-top: 20px;">more_vert</i></a>   
    </div>
    </div>
    </div>
    
