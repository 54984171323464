<mat-drawer mode="side" class="menu-drawer" opened>
    <nav class="main-menu has-subnav-sidemenu">
        <ul>
            <li class="has-subnav ">

                <mat-accordion >
                    <mat-expansion-panel hideToggle disabled>
                        <mat-expansion-panel-header>
                            <mat-panel-title [ngClass]="selectedpage == '/main-dashboard' ? 'activenew' : '' " (click)="navigate('/main-dashboard')" >
                                <div class="svg-wrapper">
                                    <svg class="dashboard-icon" width="28" height="27" viewBox="0 0 28 27" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M26 7.13592V3.28398C26 2.94345 25.8595 2.61686 25.6095 2.37607C25.3594 2.13528 25.0203 2 24.6667 2H18C17.6464 2 17.3072 2.13528 17.0572 2.37607C16.8071 2.61686 16.6667 2.94345 16.6667 3.28398V7.13592C16.6667 7.47645 16.8071 7.80304 17.0572 8.04383C17.3072 8.28462 17.6464 8.4199 18 8.4199H24.6667C25.0203 8.4199 25.3594 8.28462 25.6095 8.04383C25.8595 7.80304 26 7.47645 26 7.13592ZM11.3333 23.8277V19.9757C11.3333 19.6352 11.1929 19.3086 10.9428 19.0678C10.6928 18.827 10.3536 18.6917 10 18.6917H3.33333C2.97971 18.6917 2.64057 18.827 2.39052 19.0678C2.14048 19.3086 2 19.6352 2 19.9757V23.8277C2 24.1682 2.14048 24.4948 2.39052 24.7356C2.64057 24.9764 2.97971 25.1116 3.33333 25.1116H10C10.3536 25.1116 10.6928 24.9764 10.9428 24.7356C11.1929 24.4948 11.3333 24.1682 11.3333 23.8277Z"
                                            stroke="white" stroke-width="2.937" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                        <path
                                            d="M10 13.5558H3.33333C2.97971 13.5558 2.64057 13.4205 2.39052 13.1798C2.14048 12.939 2 12.6124 2 12.2718V3.28398C2 2.94345 2.14048 2.61686 2.39052 2.37607C2.64057 2.13528 2.97971 2 3.33333 2H10C10.3536 2 10.6928 2.13528 10.9428 2.37607C11.1929 2.61686 11.3333 2.94345 11.3333 3.28398V12.2718C11.3333 12.6124 11.1929 12.939 10.9428 13.1798C10.6928 13.4205 10.3536 13.5558 10 13.5558ZM26 23.8277V14.8398C26 14.4993 25.8595 14.1727 25.6095 13.9319C25.3594 13.6911 25.0203 13.5558 24.6667 13.5558H18C17.6464 13.5558 17.3072 13.6911 17.0572 13.9319C16.8071 14.1727 16.6667 14.4993 16.6667 14.8398V23.8277C16.6667 24.1682 16.8071 24.4948 17.0572 24.7356C17.3072 24.9764 17.6464 25.1116 18 25.1116H24.6667C25.0203 25.1116 25.3594 24.9764 25.6095 24.7356C25.8595 24.4948 26 24.1682 26 23.8277Z"
                                            stroke="white" stroke-width="2.937" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </svg>
                                </div>

                                <span class="nav-text">
                                    Dashboard
                                </span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                    </mat-expansion-panel>
                </mat-accordion>

            </li>
            <li class="has-subnav">

                <mat-accordion>
                    <mat-expansion-panel *ngFor="let menu of sidemenulist">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <div class="svg-wrapper">
                                    <i class="{{menu.icon}}"></i>
                                
                                </div>

                                <span class="nav-text" >
                                    {{menu.txt}}
                                </span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ng-container *ngFor="let submenu of menu.subMenuList">

                            <a  [ngClass]="selectedpage == submenu.url ? 'active' : '' "   (click)="navigate(submenu.url)" >
                            <span class="sub-menu-text">
                                {{submenu.txt}}
                            </span> </a>
                        </ng-container>
                    </mat-expansion-panel>
                </mat-accordion>

            </li>
        </ul>
    </nav>
</mat-drawer>