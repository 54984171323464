import { createAction, props } from '@ngrx/store';

 
export const IsTemplateAssociatedToService = createAction(
  '[Is_TemplateAssociatedService] IsTemplate AssociatedToService',
  props<any>()
);

export const IsTemplateAssociatedToServiceSuccess = createAction(
'[Is_TemplateAssociatedService] IsTemplate AssociatedToService Success',
  props<any>()
);

export const IsTemplateAssociatedToServiceFailure = createAction(
'[Is_TemplateAssociatedService] IsTemplate AssociatedToService Failure',
  props<any>()
);