import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as reducer from '../../reducers/dictionary/ApproveRejectMessageTemplateByWorkFlow.reducers'

const state = createFeatureSelector<reducer.State>('IsTemplateAssociatedToService');


export const selectLoadingApproveRejectMessageTemplateByWorkFlow = createSelector(
  state,
  state => state.isLoading
);

export const selectStatesApproveRejectMessageTemplateByWorkFlow= createSelector(
  state,
  state => state
);

export const selectStatesApproveRejectMessageTemplateByWorkFlowBody= createSelector(
  state,
  state => state.body
);

export const selectStatesApproveRejectMessageTemplateByWorkFlowData= createSelector(
  state,
  state => state.ApproveRejectMessageTemplateByWorkFlow
);
