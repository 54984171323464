import { createAction, props } from '@ngrx/store';

 
export const AddMessageTemplate = createAction(
  '[ADD_MessageTemplate] Add MessageTemplate',
  props<any>()
);

export const AddMessageTemplateSuccess = createAction(
'[ADD_MessageTemplate] Add MessageTemplate Success',
  props<any>()
);

export const AddMessageTemplateFailure = createAction(
'[ADD_MessageTemplate] Add MessageTemplate Failure',
  props<any>()
);

