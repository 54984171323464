import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as reducer from '../../reducers/dictionary/GetMessageTemplates.reducers'

const state = createFeatureSelector<reducer.State>('GetMessageTemplates');


export const selectLoadingGetMessageTemplates = createSelector(
  state,
  state => state.isLoading
);

export const selectStatesGetMessageTemplates= createSelector(
  state,
  state => state
);

export const selectStatesGetMessageTemplatesBody= createSelector(
  state,
  state => state.body
);

export const selectStatesGetMessageTemplatesData= createSelector(
  state,
  state => state.GetMessageTemplates
);
