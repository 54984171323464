<app-side-menu></app-side-menu>
<div class="header">
    <div class="logo">
        <h4 class="text"><img alt="" class="dashboard-img" src="../../assets/images/dash-icon.png"> Role </h4>
    </div>

    <div class="toggle-icon">
        <img alt="" src="../../assets/images/user.png" (click)="usericonDialog();"  style="width: 25px">
    </div>

</div>

<mat-drawer-container class="drawercontainer">
    <div class="main-page-wrapper">
        <div class="main-content-area" [ngClass]="{ 'disabled-form': loadingData }">
            <form class="container-fluid g-0" [formGroup]="RoleForm" (ngSubmit)="onSubmit();$event.stopPropagation();$event.preventDefault();">
                <div class="row mb-2 g-3">
                    <div class="col-md-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Role</mat-label>
                            <input [readonly]="queparm" matInput formControlName="rolename" [(ngModel)]="rolenameval" (input)="validateInputLength()">
                        </mat-form-field>
                        <mat-error class="mat-error"  *ngIf="(RoleForm.controls['rolename'].touched || submitted) &&
                        RoleForm.controls['rolename'].errors && RoleForm.controls['rolename'].errors?.['required'] && !queparm">*This Field is Required</mat-error>
                            
                        
                    </div>
                    
                    
                    <div class="col-md-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Description</mat-label>
                            <input [readonly]="queparm" matInput formControlName="description" [(ngModel)]="descriptionval">
                        </mat-form-field>
                        <mat-error  class="mat-error"  *ngIf="(RoleForm.controls['description'].touched || submitted) &&
                        RoleForm.controls['description'].errors && RoleForm.controls['description'].errors?.['required'] && !queparm">*This Field is Required</mat-error>
                  
                        
                    </div>
                    <div class="col-md-3">
                        <label class="switch-action">
                            <input [disabled]="queparm"   formControlName ="activeornot"   (change)="activenot()" type="checkbox">
                            <span  class="slider round"></span>
                        </label>
                    </div>
                </div>
    
            <div class="data-table">
                <table summary="table"  class="mat-elevation-z8 mat-table">
                    <thead >
                        <tr>
                            <th><mat-checkbox class="example-margin" [disabled]="queparm"  (change)="!this.id ? ScreenName($event) :ScreenName1($event) " [checked]="isScreenNameChacked('isActive')" ></mat-checkbox>Screen Name </th>
                            <th><mat-checkbox class="example-margin" [disabled]="queparm"  (change)="!this.id ? selectpermissionAdd($event): selectpermissionAdd1($event)" [checked]="isScreenNameChacked('add')"  ></mat-checkbox>Add</th>
                            <th><mat-checkbox class="example-margin" [disabled]="queparm"  (change)="!this.id ? selectpermissionEdit($event) : selectpermissionEdit1($event)" [checked]="isScreenNameChacked('edit')"></mat-checkbox>Edit</th>
                            <th><mat-checkbox class="example-margin" [disabled]="queparm"  (change)="!this.id ? selectpermissionDelete($event):selectpermissionDelete1($event)" [checked]="isScreenNameChacked('delete')"></mat-checkbox>Delete</th>
                            <th><mat-checkbox class="example-margin" [disabled]="queparm"  (change)="!this.id ? selectpermissionApprove($event): selectpermissionApprove1($event)" [checked]="isScreenNameChacked('approve')"></mat-checkbox>Approve</th>
                            <th><mat-checkbox class="example-margin" [disabled]="queparm"  (change)="!this.id ? selectpermissionAssign($event): selectpermissionAssign1($event)" [checked]="isScreenNameChacked('assign')"></mat-checkbox>Assign</th>
                        </tr>
                    </thead>
                   
                    <tbody *ngIf="!this.id">

                        <tr *ngFor="let permission of masterpermission let i =index ;
                        ">
                             
                            <td><mat-checkbox [disabled]="queparm"  class="example-margin" (change)="selectpermission($event,permission.id,i)"  [checked]="permission?.add || permission?.edit|| permission?.delete || permission?.approve || permission?.assign "></mat-checkbox>{{permission.name}}</td>
                            <td><mat-checkbox [disabled]="queparm"  class="example-margin" (change)="permissioncheck($event,i,'add')" [checked]="permission?.add" ></mat-checkbox></td>
                            <td><mat-checkbox [disabled]="queparm"  class="example-margin" (change)="permissioncheck($event,i,'edit')" [checked]=" permission?.edit"></mat-checkbox></td>
                            <td><mat-checkbox [disabled]="queparm"  class="example-margin" (change)="permissioncheck($event,i,'delete')" [checked]="permission?.delete "></mat-checkbox></td>
                            <td><mat-checkbox [disabled]="queparm"  class="example-margin" (change)="permissioncheck($event,i,'approve')" [checked]="permission?.approve"></mat-checkbox></td>
                            <td><mat-checkbox [disabled]="queparm"  class="example-margin" (change)="permissioncheck($event,i,'assign')" [checked]="permission?.assign"></mat-checkbox></td>
                        </tr>
                    
                    </tbody>
                    <tbody *ngIf="this.id">
                        <tr *ngFor="let permission of userdetails; let i =index ;
                        ">
                             
                            <td><mat-checkbox [disabled]="queparm"  class="example-margin" (change)="selectpermission1($event,permission.permission_Id,i)" [checked]="permission.permission_is_Active || permission?.add || permission?.edit|| permission?.delete || permission?.approve || permission?.assign "></mat-checkbox>{{permission.permission_Name}}</td>
                            <td><mat-checkbox [disabled]="queparm"  class="example-margin" (change)="permissioncheck1($event,i,'add')" [checked]="permission?.add" ></mat-checkbox></td>
                            <td><mat-checkbox [disabled]="queparm"  class="example-margin" (change)="permissioncheck1($event,i,'edit')" [checked]=" permission?.edit"></mat-checkbox></td>
                            <td><mat-checkbox [disabled]="queparm"  class="example-margin" (change)="permissioncheck1($event,i,'delete')" [checked]="permission?.delete "></mat-checkbox></td>
                            <td><mat-checkbox [disabled]="queparm"  class="example-margin" (change)="permissioncheck1($event,i,'approve')" [checked]="permission?.approve"></mat-checkbox></td>
                            <td><mat-checkbox [disabled]="queparm"  class="example-margin" (change)="permissioncheck1($event,i,'assign')" [checked]="permission?.assign"></mat-checkbox></td>
                        </tr>
                    
                    </tbody>
                </table>
                    <div class="loader" *ngIf="loadingData">
                     <mat-spinner diameter="40" color="accent"></mat-spinner>
                     <div>Loading...</div>
                    </div>
            </div>

            <div class="button-group">
                
                <button *ngIf="!queparm" type="submit" class="btn btn-theme" >{{id ? "Update" : "Save"}}</button>
                <button  class="btn btn-cancle" [routerLink]="'/role'" >Cancel</button>
            </div>
        </form>
        </div>
        
    </div>
    <app-footer></app-footer>

</mat-drawer-container>