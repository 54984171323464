<div class="selecttemplatecontainer">
    <div class="selecttemplateheader">
        <label class="selecttemplatelabel">Select Template</label>
        <img alt="" src="../../assets/images/SVGRepo_iconCarrier.png" id="templateselectimage">
    </div>
    <div class="selecttemplatecontent">
        <div class="selecttemplatetextandsort">
            <input type="search" name="templateserach" id="templatesearch" placeholder="Search Template">
            <select id="templateselect">
                <option value="volvo">Volvo</option>
                <option value="saab">Saab</option>
                <option value="mercedes">Mercedes</option>
                <option value="audi">Audi</option>
              </select>

        </div>
        <div class="selecttemplatetable">
            <table summary="table" class="selecttemplete">
                <th id="nearicon">
                </th>
                <th>Template Name</th>
                <th>Type</th>
                <th>Created By</th>
                <th>Created Date</th>
                <tr>
                    <td id="nearicon"><input type="checkbox" name="selecttemplateradio" id="selecttemplateradio"></td>
                    <td id="namewidth">Billling Message</td>
                    <td>Text</td>
                    <td>Yaseen Assaf</td>
                    <td>05/12/2022 12:35</td>
                </tr>

                <tr>
                    <td id="nearicon"><input type="checkbox" name="selecttemplateradio" id="selecttemplateradio"></td>
                    <td id="namewidth">Billling Message</td>
                    <td>Text</td>
                    <td>Yaseen Assaf</td>
                    <td>05/12/2022 12:35</td>
                </tr>
                <tr>
                    <td id="nearicon"><input type="checkbox" name="selecttemplateradio" id="selecttemplateradio"></td>
                    <td id="namewidth">Billling Message</td>
                    <td>Text</td>
                    <td>Yaseen Assaf</td>
                    <td>05/12/2022 12:35</td>
                </tr>
                <tr>
                    <td id="nearicon"><input type="checkbox" name="selecttemplateradio" id="selecttemplateradio"></td>
                    <td id="namewidth">Billling Message</td>
                    <td>Text</td>
                    <td>Yaseen Assaf</td>
                    <td>05/12/2022 12:35</td>
                </tr>

                <tr>
                    <td id="nearicon"><input type="checkbox" name="selecttemplateradio" id="selecttemplateradio"></td>
                    <td id="namewidth">Billling Message</td>
                    <td>Text</td>
                    <td>Yaseen Assaf</td>
                    <td>05/12/2022 12:35</td>
                </tr>

                <tr>
                    <td id="nearicon"><input type="checkbox" name="selecttemplateradio" id="selecttemplateradio"></td>
                    <td id="namewidth">Billling Message</td>
                    <td>Text</td>
                    <td>Yaseen Assaf</td>
                    <td>05/12/2022 12:35</td>
                </tr>

                <tr>
                    <td id="nearicon"><input type="checkbox" name="selecttemplateradio" id="selecttemplateradio"></td>
                    <td id="namewidth">Billling Message</td>
                    <td>Text</td>
                    <td>Yaseen Assaf</td>
                    <td>05/12/2022 12:35</td>
                </tr>
                </table>
               
        </div>
       <div class="linehr"></div>
        <div  calss="selecttemplateselection">
            <button class="selectbutton">Select</button>
            <button class="cancelbutton" mat-dialog-close>Cancel</button>
        </div>
</div>