import { createAction, props } from '@ngrx/store';

 
export const UpdateMessageTemplate = createAction(
  '[Update_MessageTemplate] Update MessageTemplate',
  props<any>()
);

export const UpdateMessageTemplateSuccess = createAction(
'[Update_MessageTemplate] Update MessageTemplate Success',
  props<any>()
);

export const UpdateMessageTemplateFailure = createAction(
'[Update_MessageTemplate] Update MessageTemplate Failure',
  props<any>()
);

