import { createAction, props } from '@ngrx/store';

 
export const sideMenu = createAction(
  '[SIDE_MENU] Get sideMenu',
  props<any>()
);

export const sideMenuSuccess = createAction(
'[SIDE_MENU] Get sideMenu Success',
  props<any>()
);

export const sideMenuFailure = createAction(
'[SIDE_MENU] Get sideMenu Failure',
  props<any>()
);


