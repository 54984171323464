import { createAction, props } from '@ngrx/store';

 
export const DeactivateUser = createAction(
  '[DeactivateUser]  DeactivateUser',
  props<any>()
);

export const DeactivateUserSuccess = createAction(
'[DeactivateUser]  DeactivateUser Success',
  props<any>()
);

export const DeactivateUserFailure = createAction(
'[DeactivateUser]  DeactivateUser Failure',
  props<any>()
);


