import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UsersService } from 'src/app/modules/usermanagement/service/users/users.service';

@Component({
  selector: 'app-preview-template',
  templateUrl: './preview-template.component.html',
  styleUrls: ['./preview-template.component.scss']
})
export class PreviewTemplateComponent {
  languages: Array<any> = [];
  language:any;
  templateId:any;
  arabic: boolean =false;
  english: boolean = false;
  englishSub='';
  englishBody='';
  arabicSub='';
  arabicBody='';
  arrind: any;
  engind: any;
  languageOptions: any[]=[]
constructor(@Inject(MAT_DIALOG_DATA) public data: any, private userservice:UsersService,private dialogref: MatDialog){
console.log(data.send,"dd")
let arrind =data?.send?.channelData?.content.findIndex((item:any)=>item.language == 1)
let engind =data?.send?.channelData?.content.findIndex((item:any)=>item.language == 2)
this.arrind =arrind
this.engind =engind
this.languageOptions = [];
          if(arrind != -1){
            this.arabicSub = data?.send?.channelData?.content[arrind].subject
            this.arabicBody = data?.send?.channelData?.content[arrind].body  
            this.languageOptions.push({ value: 1, label: 'Arabic' });
            this.language =1
          }
          if(engind != -1){
            this.englishSub = data?.send?.channelData?.content[engind].subject
            this.englishBody = data?.send?.channelData?.content[engind].body
            this.languageOptions.push({ value: 2, label: 'English' });
            this.language =2

          }

this.userservice.Getlanguages().subscribe(val=>{
  this.languages = val?.result
 })
}
cancel(){
  this.dialogref.closeAll()
}

}
