import { Component } from '@angular/core';
import { UsersService } from '../../service/users/users.service';
import { Store } from '@ngrx/store';
import * as actions from 'src/app/app-state/actions';
import * as fromRoot from 'src/app/app-state';
import { Subject, takeUntil } from 'rxjs';
import { ProfileComponent } from '../../../commonModule/components/profile/profile.component';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { permissionResponse } from 'src/app/app-state/types';
import { ActivatedRoute, Router } from '@angular/router';

export interface Event {
  checked:null | boolean
}
export interface RolePermissionResponse {
  result: RolePermission[];
  responseMessage: string;
  isSuccess: boolean;
  error: null;
}

interface RolePermission {
  id: number;
  tenant_Id: number;
  tenant_Name: string;
  name: string;
  description: string;
  is_Active: boolean;
  created_By: number;
  created_By_Name: string;
  created_On: string; 
  modified_By: number;
  modified_By_Name: string;
  modified_On: string; 
  isWorkFlow: boolean;
  action: number;
  approvedBy: number;
  approvedOn: string | null; 
  workFlowStatus: number;
  masterId: number;
  approvedByName: string | null;
  remark: string | null;
}

@Component({
  selector: 'app-role-add',
  templateUrl: './role-add.component.html',
  styleUrls: ['./role-add.component.scss']
})
export class RoleAddComponent {
  userdetails: Array<any>=[]
  
  destroy$: Subject<boolean> = new Subject<boolean>();
  isActive: boolean=true;
  
  public RoleForm:FormGroup;
  submitted: Boolean = false;
  masterpermission: permissionResponse[]=[];
  selectedpermissionId: string='';
  selectedpermissionId1: string='';
  getUserFormation:permissionResponse[]=[]
  getUserFormation1:any[]=[]
  id: number=0;
  isSuccess:boolean=false;
  checked1:boolean=false;
  queparm: number|any;
  userdetail: any = Array<[]>;
  rolenameval: string='';
  descriptionval:string='';
  loadingData: boolean = true;
  x: any[]=[];
  constructor( public dialog: MatDialog,private readonly store: Store,
    private UsersService:UsersService,
    private route:Router,
    private activateRoute :ActivatedRoute) {
      
      this.RoleForm = new FormGroup({

        rolename : new FormControl('',[Validators.required]),
        description : new FormControl('',[Validators.required]),  
        })

      this.activateRoute.queryParams.subscribe((par) => {
        // console.log(par,'parrrr')
        this.queparm = par['parm']
      })
      this.activateRoute.params.subscribe((ok) => {
        // console.log(ok,'parrrr')

       this.id = ok['id']
       this.store
       .select(fromRoot.selectLoadingGetMasterpermission)
       .pipe(takeUntil(this.destroy$))
       .subscribe((data) => {
         this.loadingData = data;
          })

        if(this.id){
          this.UsersService.GetRole({  "roleId": this.id
        }).subscribe((item: object|any)=>{
          console.log(item,'item')
            this.userdetail = item?.result

              this.RoleForm.value.rolename = this.userdetail?.name
              this.RoleForm.value.description = this.userdetail?.description
              this.rolenameval =  this.userdetail?.name
              this.descriptionval = this.userdetail?.description
              this.loadingData = false
          })  
             this.UsersService.GetRoleByID({
             "roleId": this.id,
             "status": 0,
             "action": 4
          }).subscribe((items:object|any)=>{
            console.log(items,'qqqqpppp')
            this.userdetails = items?.result
            // console.log(this.userdetails,'44444');
            
            this.userdetails = JSON.parse(JSON.stringify(items)).result;
            this.getUserFormation1=JSON.parse(JSON.stringify(items)).result
            this.loadingData = false;
            this.userdetails = this.userdetails.map((val:any)=>{
              val.addnew = false
              val.editnew = false
              val.deletenew = false
              val.approvenew = false
              val.assignnew = false
              val.isactivenew = false
              return val;
            })
            // console.log(this.userdetails,'wwwww')
          })          
        }
        else{
          this.store.dispatch(
            actions.GetMasterpermission({}))
           this.store
           .select(fromRoot.selectStatesGetMasterpermissionData)
           .pipe(takeUntil(this.destroy$))
           .subscribe((items: permissionResponse[]) => {
             this.masterpermission = JSON.parse(JSON.stringify(items)).result?.permissions;
            this.getUserFormation=JSON.parse(JSON.stringify(items)).result?.permissions

            this.masterpermission = this.masterpermission?.map((el:any)=>{
              el.add = false
              el.edit = false
              el.delete = false
              el.assign = false
              el.approve = false
              el.is_Active = false
              this.selectpermissionAdd(el.is_Active)
              return el
            })
            // console.log(this.masterpermission,'ddssd8')

            })
                    }
        }
      );

     
     


       
     
       

   }

  usericonDialog() {
    this.dialog.open(ProfileComponent,{
      panelClass:['common-modalbox'],
    })
    
  }

    selectpermissionAdd(ev:Event){
      // console.log("this.mast55",this.masterpermission)

      if(ev.checked){
        this.masterpermission?.forEach((item:permissionResponse)=>{
          item.add=true;
          // item.edit = false
          // item.delete = false
          // item.assign = false
          // item.approve = false
          // item.is_Active = false
        if(this.id){
          item.add=true;
        }
        }) 
      }
      else if(!ev.checked){
        // this.masterpermission = this.getUserFormation
        // console.log("oooooo",this.masterpermission)
        this.masterpermission = this.masterpermission?.map((item:permissionResponse)=>{
          item.add=false;
          if(this.id){
            item.add=false;
          }
          return item
        })
      }
      // console.log("this.mast55",this.masterpermission)

    }

    isScreenNameChacked(val:string){
      let isActive:boolean = true;
      if(val == 'isActive'){
        this.masterpermission?.forEach((item:any)=>{
          
          if(!item.is_Active ||
            !item.add ||
            !item.edit ||
            !item.delete ||
            !item.approve ||
            !item.assign){
              isActive = false;
            }
        });
        if(this.id){
          this.userdetails?.forEach((item:any)=>{
            if(!item.is_Permission_Active ||
              !item.add ||
              !item.edit ||
              !item.delete ||
              !item.approve ||
              !item.assign){
                isActive = false;
              }
          })
        }
      }
      if(val == 'add'){
        console.log(val)
        this.masterpermission?.forEach((item:any)=>{
          if(!item.add ){
              isActive = false;
            }
        });
        if(this.id){
          this.userdetails?.forEach((item:any)=>{
            if(
              !item.add ){
                isActive = false;
              }
          })
        }
      }  if(val == 'edit'){
        this.masterpermission?.forEach((item:any)=>{
          
          if(!item.edit ){
              isActive = false;
            }
        });
        if(this.id){
          this.userdetails?.forEach((item:any)=>{
            if(
              !item.edit ){
                isActive = false;
              }
          })
        }
      }  if(val == 'delete'){
        this.masterpermission?.forEach((item:any)=>{
          
          if(
            !item.delete){
              isActive = false;
            }
        });
        if(this.id){
          this.userdetails?.forEach((item:any)=>{
            if(
              !item.delete ){
                isActive = false;
              }
          })
        }
      }  if(val == 'assign'){
        this.masterpermission?.forEach((item:any)=>{
          
          if(
            !item.assign){
              isActive = false;
            }
        });
        if(this.id){
          this.userdetails?.forEach((item:any)=>{
            if(
              !item.assign ){
                isActive = false;
              }
          })
        }
      }if(val == 'approve'){
        this.masterpermission?.forEach((item:any)=>{
          
          if(
            !item.approve ){
              isActive = false;
            }
        });
        if(this.id){
          this.userdetails?.forEach((item:any)=>{
            if(
              !item.approve ){
                isActive = false;
              }
          })
        }
      }
      if(val=='edit' && this.id){
        // console.log("is68868",isActive, this.userdetails)
      }
     return isActive
    }
    ScreenName1(ev:Event){
      if(ev.checked){
        // console.log("kkkk")

        this.userdetails?.forEach((item:permissionResponse)=>{
          item.is_Permission_Active=true;
          item.add= true;
          item.edit= true;
          item.delete= true;
          item.approve= true;
          item.assign= true;
        // console.log("eewwww", item.is_Permission_Active)

          if(this.id){
          item.is_Permission_Active=true; 
          item.add= true;
          item.edit= true;
          item.delete= true;
          item.approve= true;
          item.assign= true;
          }
        }) 
      }
      else if(!ev.checked){
        this.userdetails = this.getUserFormation1
        this.userdetails?.forEach((item:any)=>{
          item.is_Permission_Active=false;
          item.add= false;
          item.edit= false;
          item.delete= false;
          item.approve= false;
          item.assign= false;
          if(this.id){
            item.is_Permission_Active=false;
            item.add= false;
            item.edit= false;
            item.delete= false;
            item.approve= false;
            item.assign= false;
  
            
            }
        })
      }
    }
    ScreenName(ev:Event){
      // console.log(ev,"event")
            if(ev.checked){
              // console.log(ev.checked,"event2")
        this.masterpermission?.forEach((item:permissionResponse)=>{  
          // console.log(item,"item")
          item.is_Active=true;
          item.add= true;
          item.edit= true;
          item.delete= true;
          item.approve= true;
          item.assign= true;
          if(this.id){
          item.is_Active=true;
          item.add= true;
          item.edit= true;
          item.delete= true;
          item.approve= true;
          item.assign= true;
          }
        }) 
        // this.x = this.masterpermission
      // console.log(this.x, "12");
      }
      else if(!ev.checked){
        // console.log(ev.checked,"event1")
        // this.masterpermission = this.getUserFormation
        this.masterpermission?.forEach((item:permissionResponse)=>{
          item.is_Active=false;
          item.add= false;
          item.edit= false;
          item.delete= false;
          item.approve= false;
          item.assign= false;
          if(this.id){
            item.is_Active=false;
            item.add= false;
          item.edit= false;
          item.delete= false;
          item.approve= false;
          item.assign= false;
            }
        })
      //  this.x=[]
      //  console.log(this.x,"vv1")
      }
    }

    selectpermissionEdit(ev:Event){
      if(ev.checked){
        this.masterpermission?.forEach((item:permissionResponse)=>{
          item.edit=true;
          if(this.id){
          item.edit=true;


          }
          
        }) 
      }
      else if(!ev.checked){
        // this.masterpermission = this.getUserFormation
        this.masterpermission?.forEach((item:permissionResponse)=>{
          item.edit=false;
          if(this.id){
            item.edit=false;
  
  
            }
        })
      }
    }
    selectpermissionDelete(ev:Event){
      if(ev.checked){
        this.masterpermission?.forEach((item:permissionResponse)=>{
          item.delete=true;
          if(this.id){
            item.delete=true;
            }

          
        }) 
      }
      else if(!ev.checked){
        // this.masterpermission = this.getUserFormation
        this.masterpermission?.forEach((item:permissionResponse)=>{
          item.delete=false;  
          if(this.id){
            item.delete=false;
  
  
            }
        })
      }
    }
    selectpermissionApprove(ev:Event){
      if(ev.checked){
        this.masterpermission?.forEach((item:permissionResponse)=>{
          item.approve=true;
          if(this.id){
            item.approve=true;
  
  
            }
        }) 
      }
      else if(!ev.checked){
        // this.masterpermission = this.getUserFormation
        this.masterpermission?.forEach((item:permissionResponse)=>{
          item.approve=false;
          if(this.id){
            item.approve=false;
  
  
            }
        })
      }
    }
    selectpermissionAssign(ev:Event){
      if(ev.checked){
        this.masterpermission?.forEach((item:permissionResponse)=>{
          item.assign=true;
          if(this.id){
          item.assign=true;


          }
        }) 
      }
      else if(!ev.checked){
        // this.masterpermission = this.getUserFormation
        this.masterpermission?.forEach((item:permissionResponse)=>{
          item.assign=false;
          if(this.id){
            item.assign=false;
          }
        })
      }
    }
   
    selectpermissionAdd1(ev:Event){
      if(ev.checked){
        // console.log("kkkk")

        this.userdetails?.forEach((item:permissionResponse)=>{
          item.add=true;
        if(this.id){
          item.add=true;


        }
        }) 
      }
      else if(!ev.checked){
        // this.userdetails = this.getUserFormation1
        // console.log("okkkkkk",this.userdetails)
        this.userdetails = this.userdetails?.map((item:permissionResponse)=>{
          item.add=false;
          if(this.id){
            item.add=false;
          }
          return item
        })
      }
    }

    selectpermissionEdit1(ev:Event){
      if(ev.checked){
        // console.log("kkkk")

        this.userdetails?.forEach((item:permissionResponse)=>{
          item.edit=true;
          if(this.id){
          item.edit=true;


          }
        }) 
      }
      else if(!ev.checked){
        // this.userdetails = this.getUserFormation1
        this.userdetails?.forEach((item:permissionResponse)=>{
          item.edit=false;
          if(this.id){
            item.edit=false;
  
  
            }
        })
      }
    }
    selectpermissionDelete1(ev:Event){
      if(ev.checked){
        // console.log("kkkk")
        this.userdetails?.forEach((item:permissionResponse)=>{
          item.delete=true;
          if(this.id){
            item.delete=true;
            }

          
        }) 
      }
      else if(!ev.checked){
        // this.userdetails = this.getUserFormation1
        this.userdetails?.forEach((item:permissionResponse)=>{
          item.delete=false;  
          if(this.id){
            item.delete=false;
  
  
            }
        })
      }
    }
    selectpermissionApprove1(ev:Event){
      if(ev.checked){
        this.userdetails?.forEach((item:permissionResponse)=>{
          item.approve=true;
          if(this.id){
            item.approve=true;
  
  
            }
        }) 
      }
      else if(!ev.checked){
        // this.userdetails = this.getUserFormation1
        this.userdetails?.forEach((item:permissionResponse)=>{
          item.approve=false;
          if(this.id){
            item.approve=false;
  
  
            }
        })
      }
    }
    selectpermissionAssign1(ev:Event){
      if(ev.checked){
        this.userdetails?.forEach((item:permissionResponse)=>{
          item.assign=true;
          if(this.id){
          item.assign=true;


          }
        }) 
      }
      else if(!ev.checked){
        // this.userdetails = this.getUserFormation1
        this.userdetails?.forEach((item:permissionResponse)=>{
          item.assign=false;
          if(this.id){
            item.assign=false;
          }
        })
      }
    }
  
    activenot(){
      this.isActive = !this.isActive;
      
    }
    selectpermission(event:Event,ev:number|any,index:number){
      if(event.checked){
        this.selectedpermissionId = this.masterpermission[index].id
        // this.x = []
        // this.x.push(this.selectedpermissionId )
        // console.log(this.x,"derf")
        this.masterpermission[index].is_Active = event.checked
        this.masterpermission[index].add=event.checked
        this.masterpermission[index].edit=event.checked
        this.masterpermission[index].assign=event.checked
        this.masterpermission[index].delete=event.checked
        this.masterpermission[index].approve=event.checked
      }
      if(!event.checked){
        const removeindex = this.x.indexOf(this.masterpermission[index].id);
        if (removeindex !== -1) {
          this.x.splice(removeindex, 1);
      }
      this.masterpermission[index].is_Active = event.checked
          this.masterpermission[index].add=event.checked
        this.masterpermission[index].edit=event.checked
        this.masterpermission[index].assign=event.checked
        this.masterpermission[index].delete=event.checked
        this.masterpermission[index].approve=event.checked
    }
    }

    selectpermission1(event:Event,ev:number|any,index:number){
      if(event.checked){
        this.selectedpermissionId1 = this.userdetails[index].permission_Id
        this.x.push(this.selectedpermissionId1)
        this.userdetails[index].is_Active = event.checked
        this.userdetails[index].add=event.checked
        this.userdetails[index].edit=event.checked
        this.userdetails[index].assign=event.checked
        this.userdetails[index].delete=event.checked
        this.userdetails[index].approve=event.checked

      }
      if(!event.checked){
        // console.log(this.userdetails[index],"indess")
        const removeindex = this.x.indexOf(this.userdetails[index].permission_Id);
        if (removeindex !== -1) {
          this.x.splice(removeindex, 1);
      }
      this.userdetails[index].is_Active = event.checked
      this.userdetails[index].add=event.checked
    this.userdetails[index].edit=event.checked
    this.userdetails[index].assign=event.checked
    this.userdetails[index].delete=event.checked
    this.userdetails[index].approve=event.checked
      // console.log(this.x,"xx  ")
    }
    }
    permissioncheck(eve:Event,index:number,val:string){
      // console.log(val,eve,index,"ad")
      if(eve.checked){
        if(val == 'add'){
          // this.selectedpermissionId = this.masterpermission[index].id
          // console.log(this.selectedpermissionId,"id")
          // this.x.push(this.selectedpermissionId )
          this.masterpermission[index].add=eve.checked
          this.masterpermission[index].is_Active=eve.checked
        }
        if(val == 'edit'){
          // this.selectedpermissionId = this.masterpermission[index].id
          // console.log(this.selectedpermissionId,"id")
          // this.x.push(this.selectedpermissionId )
          this.masterpermission[index].edit=eve.checked
          this.masterpermission[index].is_Active=eve.checked
        }
        if(val == 'delete'){
          // this.selectedpermissionId = this.masterpermission[index].id
          // console.log(this.selectedpermissionId,"id")
          // this.x.push(this.selectedpermissionId )
          this.masterpermission[index].delete=eve.checked
          this.masterpermission[index].is_Active=eve.checked
        }
        if(val == 'approve'){
          // this.selectedpermissionId = this.masterpermission[index].id
          // console.log(this.selectedpermissionId,"id")
          // this.x.push(this.selectedpermissionId )
          this.masterpermission[index].approve=eve.checked
          this.masterpermission[index].is_Active=eve.checked
        }
        if(val == 'assign'){
          // this.selectedpermissionId = this.masterpermission[index].id
          // console.log(this.selectedpermissionId,"id")
          // this.x.push(this.selectedpermissionId )
          this.masterpermission[index].assign=eve.checked
          this.masterpermission[index].is_Active=eve.checked
        }
      }
     if(!eve.checked) {
      // console.log(eve.checked,"1")
      if(val == 'add'){
        const removeindex = this.x.indexOf(this.masterpermission[index].id);
        if (removeindex !== -1) {
          this.x.splice(removeindex, 1);
        // console.log(this.x,"we")
      }
        // console.log(eve.checked,"13")
        this.masterpermission[index].add=eve.checked
      }
      if(val == 'edit'){
        const removeindex = this.x.indexOf(this.masterpermission[index].id);
        if (removeindex !== -1) {
          this.x.splice(removeindex, 1);
        // console.log(this.x,"we")
      }
        this.masterpermission[index].edit=eve.checked
      }
      if(val == 'delete'){
        const removeindex = this.x.indexOf(this.masterpermission[index].id);
        if (removeindex !== -1) {
          this.x.splice(removeindex, 1);
        // console.log(this.x,"we")
      }
        this.masterpermission[index].delete=eve.checked
      }
      if(val == 'approve'){
        const removeindex = this.x.indexOf(this.masterpermission[index].id);
        if (removeindex !== -1) {
          this.x.splice(removeindex, 1);
        // console.log(this.x,"we")
      }
        this.masterpermission[index].approve=eve.checked
      }
      if(val == 'assign'){
        const removeindex = this.x.indexOf(this.masterpermission[index].id);
        if (removeindex !== -1) {
          this.x.splice(removeindex, 1);
        // console.log(this.x,"we")
      }
        this.masterpermission[index].assign=eve.checked
      }
      this.masterpermission?.forEach((item:any)=>{
          
        if(
          item.add ||
          item.edit ||
          item.delete ||
          item.approve ||
          item.assign){
            // console.log("!!!!!!!!!!")
            item.is_Active= true
          }else{
            item.is_Active=false
          }
      });
     }
    }
    permissioncheck1(eve:Event,index:number,val:string){
      if(val == 'add'){

        this.userdetails[index].add=eve.checked
        // console.log(this.userdetails[index].add1,'qwwwqqww')
      }
      if(val == 'edit'){

        this.userdetails[index].edit=eve.checked

      }
      if(val == 'delete'){

        this.userdetails[index].delete=eve.checked

      }
      if(val == 'approve'){

        this.userdetails[index].approve=eve.checked
      }
      if(val == 'assign'){

        this.userdetails[index].assign=eve.checked
      }
      // console.log("data56",eve.checked, this.userdetails[index]);
      
    }

    validateInputLength(){
      if (this.rolenameval.length > 50) {
        this.UsersService.toast.snackbarSucces('Character limit exceeded (max: 50)');
      }
    }
    onSubmit(){
      if(this.rolenameval && this.rolenameval.length > 50){
        // console.log("!!!!!!!!!!!!!")
        this.submitted = false;
        this.UsersService.toast.snackbarSucces('Character limit exceeded (max: 50)');
        return
      }
      this.submitted = true;
     
      if(this.RoleForm.valid){
        
        if(!this.id){
          // this.isSuccess=false
      // console.log("qqqqq", this.x, this.masterpermission)
      let testvaribale = 0;
      this.masterpermission.map((getd:any)=>{
        if(getd.add || getd.edit || getd.assign || getd.delete || getd.approve){
          testvaribale = 1
          
        }
        
      })
      let arr :any[]=[]
      // if( this.x?.length > 0){
        if(testvaribale == 1){

          this.masterpermission?.forEach((ele:any)=>{
          // this.x.forEach((element:any)=>{
            // console.log(element,"sd",ele)
          //   if(element == ele.id ){
              arr.push({
              "is_Active": ele.is_Active,
              "permissionID": ele.id,
              "add": ele.add1 || ele.add ,
                "edit": ele.edit1 || ele.edit,
                "delete": ele.delete1 || ele.delete ,
                "approve": ele.approve1 || ele.approve,
                "assign": ele.assign1  || ele.assign
            //   })
            // }
                })
          })
          // console.log(arr,'464646464646')
          // return;
              this.UsersService.CreateRole({
                
                  "roleID": 0,
                  "roleName": this.RoleForm.value.rolename,
                  "roleDescription": this.RoleForm.value.description,
                  "isActive": this.isActive,
                  "permissions": arr
                
              
            }).subscribe((res)=>{
            //  console.log(res,"response")
        this.UsersService.toast.snackbarSucces("Role added Successfully!");
        // if(this.isSuccess== true){
          // console.log("ddddd")
        //   this.UsersService.toast.snackbarSucces("Role name already exists");}
        
        this.activateRoute.queryParams.subscribe((params: object|any) => {
        // console.log(params,'uiii')
        this.route.navigate(['/role'], {
          
          
          queryParams: {
            page: params?.page,
            pageIndex: params?.pageIndex,
          },
        })
        },(error)=>{
          // console.log(error,'sddssd7')
            this.UsersService.toast.snackbarSucces("Please select atleast one permission to create the role");
        
        
        })
        
        this.route.navigate(['role'])
            }
            )
        }else {
          this.UsersService.toast.snackbarSucces("Please select atleast one permission to create the role");
        }
     
       
     
       
        }

        else {
     
          const anyPermissionTrue = this.userdetails.some(val => val.add || val.edit || val.delete || val.assign || val.approve);
          
          if (anyPermissionTrue) {
            // console.log("qwd");
            let arr: any[] = [];
            this.userdetails?.forEach((ele: any) => {
              arr.push({
                "is_Active": ele.is_Permission_Active,
                "permissionID": ele.permission_Id,
                "add": ele.add1 || ele.add,
                "edit": ele.edit1 || ele.edit,
                "delete": ele.delete1 || ele.delete,
                "approve": ele.approve1 || ele.approve,
                "assign": ele.assign1 || ele.assign
              });
            });
          
            // console.log(arr, "464646");
          
            this.UsersService.CreateRole({
              "roleID": this.id,
              "roleName": this.RoleForm.value.rolename,
              "roleDescription": this.RoleForm.value.description,
              "isActive": this.isActive,
              "permissions": arr
            }).subscribe((res) => {
              this.UsersService.toast.snackbarSucces("Role Updated Successfully!");
          
              this.activateRoute.queryParams.subscribe((params: object | any) => {
                // console.log(params, 'uiii');
          
                this.route.navigate(['/role'], {
                  queryParams: {
                    page: params?.page,
                    pageIndex: params?.pageIndex,
                  },
                });
              });
            });
          } else {
            this.UsersService.toast.snackbarSucces('Please select at least one permission to update the role');
          }
          
          
        }
      }
  }

}
