import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as reducer from '../../reducers/dictionary/AddMessageTemplate.reducers'

const state = createFeatureSelector<reducer.State>('AddMessageTemplate');


export const selectLoadingAddMessageTemplate = createSelector(
  state,
  state => state.isLoading
);

export const selectStatesAddMessageTemplate= createSelector(
  state,
  state => state
);

export const selectStatesAddMessageTemplateBody= createSelector(
  state,
  state => state.body
);

export const selectStatesAddMessageTemplateData= createSelector(
  state,
  state => state.AddMessageTemplate
);
