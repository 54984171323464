import { UsersService } from '../../../usermanagement/service/users/users.service';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import * as actions from 'src/app/app-state/actions';
import * as fromRoot from 'src/app/app-state';

@Component({
  selector: 'app-edit-popup',
  templateUrl: './edit-popup.component.html',
  styleUrls: ['./edit-popup.component.scss']
})
export class EditPopupComponent {
  destroy$: Subject<boolean> = new Subject<boolean>();
  role: string ='';
  department: string='';
  roledata: any|Array<[]>;
  name:string='';
  value:string='' 
  rolename:string='';
  id:{}|object;
  userdetails: any|Array<[]>;
  errormes:boolean=false
  depid: number;
  checkbox:string='';
arabicName:string = '';
submit : boolean = false;
  constructor(  @Inject(MAT_DIALOG_DATA) public data: any,
  private MatDialog:MatDialog,
 private UsersService :UsersService ,
 private readonly store: Store){
  this.depid = data?.item?.id;
   this.id = data?.roledata?.id
    this.UsersService.GetDepartmentById({"departmentId": this.depid}).subscribe((items)=>{
      this.userdetails = items
      console.log(this.userdetails,'kkkkkiiii')
      this.name= this.userdetails?.result?.name_English
      this.arabicName = this.userdetails?.result?.name_Arabic
      this.checkbox = this.userdetails?.result?.is_Active
      if(this.role == 'role'){
      }

    this.value = this.userdetails?.result?.value


    })
  }
  preventSpace(event: any): void {
    console.log(event,"e")
    const inputValue: string = event.target.value;
    const isOnlySpaces = /^\s*$/.test(inputValue);

    if (event.code === "Space" && isOnlySpaces) {
        event.preventDefault();
    } 
  }

  validateInputLength(){
    if (this.name.length > 50 || this.arabicName.length > 50 || this.value.length > 50) {
      this.UsersService.toast.snackbarSucces('Character limit exceeded (max: 50)');
    }
  }
  Submit(){
    if(!this.name || !this.value || !this.arabicName ){
      this.errormes =true;
      return
    }
    if(this.name && this.name.length > 50 || this.arabicName && this.arabicName.length > 50 || this.value && this.value.length > 50){
      console.log("!!!!!!!!!!!!!")
      this.submit = false;
      this.UsersService.toast.snackbarSucces('Character limit exceeded (max: 50)');
      return
    }
    this.errormes=false;
    this.submit = true;
    if(this.data.department?.toLowerCase().trim() == 'dapartment'){ 
      if(!this.name || !this.value || !this.arabicName  ){
        this.errormes =true
        return
      }
      if(this.name && this.name.length > 50 || this.arabicName && this.arabicName.length > 50 || this.value && this.value.length > 50){
        console.log("!!!!!!!!!!!!!")
        this.submit = false;
        this.UsersService.toast.snackbarSucces('Character limit exceeded (max: 50)');
        return
      }
      this.errormes=false;
      this.submit = true;
      this.errormes =false
          this.UsersService.CreateDepartment({
          "id":this.depid,
          "departmentNameEnglish": this.name,
          "departmentNameArabic": this.arabicName,
          "departmentValue":this.value,
          "status": this.checkbox  
        }).subscribe(()=>{
          this.store.dispatch(
            actions.getDepartments({}))
        this.MatDialog.closeAll()
        this.UsersService.toast.snackbarSucces("Department Updated Successfully!");
        })

    }else{
      this.UsersService.CreateRole({
            
        "roleID": this.data?.id,
        "roleName": this.name,
        "roleDescription": this.rolename,
        "isActive": this.checkbox,
        "permissions": [
          {
            "permissionID": 0,
            "add": true,
            "edit": true,
            "delete": true,
            "approve": true,
            "assign": true
          }
        ]
  }).subscribe(()=>{
    this.store.dispatch(
      actions.getDepartments({}))
 
    this.MatDialog.closeAll()
  
  this.UsersService.toast.snackbarSucces("Role Updated Successfully!");
  
  })
    }
    
}

    
}
