import { ToastComponent } from './../../../../shared/toast/toast.component';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/modules/auth/service/auth/auth.service';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UsersService } from 'src/app/modules/usermanagement/service/users/users.service';

    
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class   LoginComponent {
  showPassword: boolean = false;
  loadingNext: boolean = false;

  public loginForm:FormGroup;
  submitted:boolean=false;
  userdata: Object = {};
  constructor(private AuthService: AuthService,private router :Router, 
   private UsersService:UsersService){

    this.loginForm = new FormGroup({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      tenantID:new FormControl (1)

    });

    this.UsersService.dataSourceErrorSubject.subscribe((err:any)=>{
      this.loadingNext = false
    })
  }
  preventSpace(event: any): void {
    console.log(event,"e")
    const inputValue: string = event.target.value;
    
    if (event.code === "Space" ) {
        event.preventDefault();
    }

  }
  OnSubmit() {
    this.submitted =true
    if (this.loginForm.valid) {
      this.loadingNext = true;  
      console.log(this.loginForm,"dd")
      this.AuthService.LoginUser(this.loginForm.value).then((res: any) => {
        
       
        if(res.isSuccess == true) {
          if( res?.result?.tenants?.length == 1){

            this.router.navigate(['/main-dashboard']);
            let details = {
              token: res?.result?.token?.token
            };
            let id = res?.result?.userId
            localStorage.setItem('userId', id)
            localStorage.setItem('userDetails', JSON.stringify(details));
            localStorage.setItem('userdata',this.loginForm.value.password )
            
          }else {
          this.loadingNext = true;
  
            this.router.navigate(['/role-one']);
            
  
            let details = {
              username: this.loginForm.value.username,
              password: this.loginForm.value.password,
              token: res?.result?.token,
  
              tenants: res.tenants,
            };
            localStorage.setItem('userDetails', JSON.stringify(details));
            
  
            
          }
        } 
        // else {
        //   console.log("ersdf")
        //   this.UsersService.toast.snackbarError("User not found with these details.")
        //   setTimeout(() => {
            
        //     this.loadingNext = false
        //   }, 200);

        // }
       
        })
      }
   
  }


}
