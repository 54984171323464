import { Component, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import * as actions from 'src/app/app-state/actions';
import * as fromRoot from 'src/app/app-state';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DeactivateUserResponse } from 'src/app/app-state/types/user-management/DeactivateUser.types';
import { UsersService } from 'src/app/modules/usermanagement/service/users/users.service';
import { MyRequest } from 'src/app/app-state/types/user-management/GetUsers.types';
import { FormControl, FormGroup, Validators } from '@angular/forms';

export interface getUserDetail{
  id: number,
  tenant_Id: number,
  tenant_Name: null,
  tenantUID: {
      iiD_Id: number,
      iiD_value: number,
      uiD_Id: number,
      uiD_value: number
  },
  display_Name: string,
  login_Name: string,
  password: string,
  login_On: string,
  is_Active: true,
  owned_By: number,
  owned_By_Name: string,
  created_By: number,
  created_By_Name: string,
  created_On: string,
  modified_By: number,
  modified_By_Name: null,
  modified_On: string,
  roles: [
      {
          roleId: number,
          roleNam:string
      }
  ],
  department: [
      {
          departmentId: number,
          departmentName: string
      }
  ],
  isWorkFlow: false,
  action: number,
  approvedBy: number,
  approvedOn: string,
  workFlowStatus: number,
  masterId: number,
  approvedByName: null,
  remark: null
}
@Component({
  selector: 'app-delete-popup',
  templateUrl: './delete-popup.component.html',
  styleUrls: ['./delete-popup.component.scss']
})
export class DeletePopupComponent {
  destroy$: Subject<boolean> = new Subject<boolean>();
  isActive: boolean=true;
  // public UserForm: FormGroup;
  departid: getUserDetail[]=[];
  rolesid: any[]=[];
  userdetails: any=Array<[]>;
  constructor(private UsersService:UsersService, private readonly store: Store,
    @Inject(MAT_DIALOG_DATA) public data: any, private MatDialog:MatDialog,private service:UsersService){
      console.log(data,"dataa")
      this.userdetails = this.data.item
      this.userdetails?.department?.forEach((val:object|any)=>{
        this.departid.push(val?.departmentId)
      })

      this.userdetails?.roles.forEach((val:object|any)=>{
        this.rolesid.push(val?.roleId)
      })
      // this.UserForm = new FormGroup({

      //   name : new FormControl('',[Validators.required]),
      //   password : new FormControl('',[Validators.required]),
      //   username : new FormControl('',[Validators.required]),
      //   rolesControl : new FormControl([],[Validators.required]),
      //   iidControl:  new FormControl([],[Validators.required]),
      //   uiidControl : new FormControl([],[Validators.required]),
      //   DepartmentControl : new FormControl([],[Validators.required]),
      //   isactive:new FormControl([],)
      
      //   })
      //   this.UserForm = new FormGroup({

      //     isactive : new FormControl(!this.userdetails?.is_Active),
  
      //     name : new FormControl(this.userdetails.display_Name,[Validators.required]),
      //     password : new FormControl(''),
      //     username : new FormControl(this.userdetails.login_Name,[Validators.required]),
      //     rolesControl : new FormControl(this.rolesid,[Validators.required]),
      //     iidControl:  new FormControl(this.userdetails.tenantUID.iiD_value,[Validators.required]),
      //     uiidControl : new FormControl(this.userdetails.tenantUID.uiD_value,[Validators.required]),
      //     DepartmentControl : new FormControl(this.departid,[Validators.required]),
        
      //     })
    }
  

  Delete(){
    if(this.data?.id){
      this.service.DeactivateUser({"userId": this.data?.id}).subscribe((res)=>{
        this.MatDialog.closeAll()
          this.service.toast.snackbarSucces("User Deactivated Successfully!");
      })
    }
    else if(this.data?.templateID){
    this.service.DeleteMessageTemplate({ "templateID": this.data?.templateID}).subscribe((res)=>{
      this.MatDialog.closeAll()
        this.service.toast.snackbarSucces("Template Deleted Successfully!");
    })
  }
}
Delete1(){
 
}
Activate(){
  this.UsersService.AddUser({
    "userId": this.data.id,
    "displayName": this.userdetails.display_Name,
    "loginName": this.userdetails.login_Name,
    // "password": '123456',
    "isActive": this.isActive,
    "tenantId": 1,

 
    "tenantIIDId": this.userdetails.tenantUID.iiD_value,
    "tenantUIId": this.userdetails.tenantUID.uiD_value,
    "roleIds": this.userdetails.roles.map((d:any) => d.roleId),
    "departmentIds": this.userdetails.department.map((d:any) => d.departmentId)

}).subscribe(()=>{
this.MatDialog.closeAll()
this.UsersService.toast.snackbarSucces("User Activated Successfully!");


})
}
}
