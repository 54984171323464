import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as reducer from '../../reducers/dictionary/GetMessageTemplateTypes.reducers'

const state = createFeatureSelector<reducer.State>('GetMessageTemplateTypes');


export const selectLoadingGetMessageTemplateTypes = createSelector(
  state,
  state => state.isLoading
);

export const selectStatesGetMessageTemplateTypes= createSelector(
  state,
  state => state
);

export const selectStatesGetMessageTemplateTypesBody= createSelector(
  state,
  state => state.body
);

export const selectStatesGetMessageTemplateTypesData= createSelector(
  state,
  state => state.GetMessageTemplateTypes
);
