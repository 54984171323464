import { createAction, props } from '@ngrx/store';

 
export const DeleteMessageTemplate = createAction(
  '[Delete_MessageTemplate] Delete MessageTemplate',
  props<any>()
);

export const DeleteMessageTemplateSuccess = createAction(
'[Delete_MessageTemplate] Delete MessageTemplate Success',
  props<any>()
);

export const DeleteMessageTemplateFailure = createAction(
'[Delete_MessageTemplate] Delete MessageTemplate Failure',
  props<any>()
);