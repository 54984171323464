import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as reducer from '../../reducers/dictionary/UpdateMessageTemplate.reducers'

const state = createFeatureSelector<reducer.State>('UpdateMessageTemplate');


export const selectLoadingUpdateMessageTemplate = createSelector(
  state,
  state => state.isLoading
);

export const selectStatesUpdateMessageTemplate= createSelector(
  state,
  state => state
);

export const selectStatesUpdateMessageTemplateBody= createSelector(
  state,
  state => state.body
);

export const selectStatesUpdateMessageTemplateData= createSelector(
  state,
  state => state.UpdateMessageTemplate
);
