<app-side-menu></app-side-menu>

<div class="header">
  <div class="logo">
    <h4 class="text">
      <img
        alt=""
        class="dashboard-img"
        src="../../assets/images/dash-icon.png"
      />
      Email Campaign
    </h4>
  </div>

  <div class="toggle-icon">
    <img alt="" src="../../assets/images/user.png" (click)="usericonDialog();" style="width: 25px" />
  </div>
</div>
<mat-drawer-container class="drawercontainer">
  <div class="main-page-wrapper">
    <div class="main-content-area">
      <div class="form-main-wrapper">
        <div class="info-fill-tab border-0">
          <mat-accordion class="mb-2">
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header class="headings">
                <mat-panel-title>
                  <span>1. Campaign Details* </span>
                </mat-panel-title>
                <mat-panel-description>
                  <span (click)="ques()" class="help-section"
                    ><i class="fa fa-question-circle-o me-1"></i
                  ></span>
                </mat-panel-description>
              </mat-expansion-panel-header>

              <div class="inner-body-content">
                <div class="container-fluid g-0">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-sm-3">
                          <div class="form-field-wrapper">
                            <mat-form-field class="example-full-width">
                              <mat-label>Campaign Name</mat-label>
                              <input
                                [disabled]="queparm"
                                matInput
                                [(ngModel)]="campaignname"
                                (input)="validateInputLength()"
                              />
                            </mat-form-field>
                          </div>
                          <div>
                            <span
                              *ngIf="!campaignname && errormes"
                              style="color: red"
                              class=""
                              >*This field is required</span
                            >
                          </div>
                        </div>

                        <!-- <div class="col-sm-3">
                                                    <div class="form-field-wrapper">
                                                        <mat-form-field appearance="fill">
                                                            <mat-label>Channel</mat-label>
                                                            <mat-select [disabled]="queparm" [(ngModel)]="channel">
                                                                <mat-option *ngFor="let channel of channels;"
                                                                    [value]="channel?.value">{{channel?.name}}</mat-option>

                                                            </mat-select>
                                                        </mat-form-field>

                                                    </div>

                                                </div> -->

                        <div class="col-sm-3">
                          <div class="form-field-wrapper">
                            <mat-form-field
                              class="disable-bg-input"
                              appearance="fill"
                            >
                              <mat-label>Sender ID</mat-label>
                              <mat-select
                                style="opacity: 0.5"
                                [ngClass]="senderid1 ? 'disable' : ''"
                                [disabled]="senderid"
                                [(ngModel)]="senderid"
                              >
                                <mat-option
                                  *ngFor="let shortcode of shortcodes"
                                  [value]="shortcode?.value"
                                  >{{ shortcode?.name }}</mat-option
                                >
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="form-field-wrapper">
                            <mat-form-field appearance="fill">
                              <mat-label>Message Type</mat-label>
                              <mat-select
                                [disabled]="queparm"
                                [(ngModel)]="messagetype"
                              >
                                <mat-option
                                  *ngFor="let message of messages"
                                  [value]="message?.value"
                                  >{{ message?.name }}</mat-option
                                >
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div>
                            <span
                              *ngIf="!messagetype && errormes"
                              style="color: red"
                              >*This field is required</span
                            >
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="form-field-wrapper">
                            <mat-form-field appearance="fill">
                              <mat-label>Department</mat-label>
                              <mat-select
                                [disabled]="queparm"
                                [(ngModel)]="dep"
                              >
                                <mat-option
                                  *ngFor="let department of departmentList"
                                  [value]="department?.value"
                                  >{{ department?.name }}</mat-option
                                >
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div>
                            <span *ngIf="!dep && errormes" style="color: red"
                              >*This field is required</span
                            >
                          </div>
                        </div>
                      </div>

                      <!-- <div class="col-sm-3">
                                                    <label class="label-value">
                                                        <span class="channel-t">Is Channel Information in Source</span>
                                                        <input type="checkbox">

                                                    </label>

                                                </div>
                                                <div class="col-sm-3">
                                                    <label class="label-value">
                                                        <span class="channel-t">Separate Query Require</span>
                                                        <input type="checkbox">

                                                    </label>

                                                </div>
                                                <div class="col-sm-3 d-filter-p">
                                                    <label class="channel-t">Data Filters</label>
                                                    <div class="outer-box">
                                                        <label class="new-label">
                                                            <input type="checkbox">
                                                            <span>Subscription</span>
                                                        </label>
                                                        <label class="new-label">
                                                            <input type="checkbox">
                                                            <span>Mandatory Opt Out</span>
                                                        </label>
                                                        <label class="new-label">
                                                            <input type="checkbox">
                                                            <span>DND</span>
                                                        </label>
                                                    </div>

                                                </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div class="info-fill-tab border-0">
          <mat-accordion class="mb-2">
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header class="headings">
                <mat-panel-title>
                  <span>2. Add recepients* </span>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="inner-body-content">
                <div class="container-fluid g-0">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-sm-2">
                          <div class="group-title">
                            <p>Campaign</p>
                          </div>

                          <div class="form-field-wrapper">
                            <mat-form-field appearance="fill">
                              <mat-label>Select Campaign</mat-label>
                              <input
                                [disabled]="true"
                                matInput
                                [(ngModel)]="receipent"
                              />

                              <!-- <mat-select [disabled]="queparm" [(ngModel)]="receipent">
                                                                <mat-option *ngFor="let campaign of campaigns;"
                                                                    [value]="campaign?.value">{{campaign?.name}}</mat-option>

                                                            </mat-select> -->
                            </mat-form-field>
                          </div>
                          <div>
                            <span
                              *ngIf="!receipent && errormes"
                              style="color: red"
                              >*This field is required</span
                            >
                          </div>
                        </div>
                        <!-- <div class="col-sm-1">

                                                </div> -->
                        <!-- <div class="col-sm-3">
                                                    <label class="type-head">Type Of Source</label>
                                                    <div class="campaign-box">
                                                        <label class="new-label">
                                                            <input type="checkbox" (change)="uploadfile($event)"
                                                                [checked]="checkfile">
                                                            <span>Excel/CSV file</span>
                                                        </label>
                                                        <label class="new-label">
                                                            <input type="checkbox" (change)="mannualentry($event)"
                                                                [checked]="checkmannual">
                                                            <span>Mannual Entry</span>
                                                        </label>
                                                        <label class="new-label">
                                                            <input type="checkbox" (change)="groupadd($event)"
                                                                [checked]="checkgroup">
                                                            <span>Groups</span>
                                                        </label>
                                                    </div>

                                                </div>
                                                <div class="col-sm-1">

                                                </div> -->

                        <div
                          class="col-sm-3"
                          *ngIf="!allnewfilename && !mannualentryvalue && !id"
                        >
                          <div class="group-title">
                            <p>Group</p>
                          </div>

                          <div class="form-field-wrapper">
                            <mat-form-field appearance="fill">
                              <mat-label>Select Group</mat-label>
                              <mat-select
                                [disabled]="queparm"
                                [(ngModel)]="group"
                                multiple
                              >
                                <mat-option
                                  *ngFor="let groups of grouplist"
                                  [value]="groups?.value"
                                  >{{ groups?.name }}</mat-option
                                >
                              </mat-select>
                            </mat-form-field>
                            <button
                              *ngIf="group && group.length > 0"
                              class="btn btn-theme "
                              style="color: green"
                              (click)="uploadGroup()"
                            >
                              Upload
                            </button>
                          </div>
                          <div>
                            <span *ngIf="!group && errormes" style="color: red"
                              >*This field is required</span
                            >
                          </div>
                        </div>
                        <div
                          class="col-sm-3"
                          *ngIf="campaign?.campaign?.groupSelected !== '' && id"
                        >
                          <div class="group-title">
                            <p>Group</p>
                          </div>

                          <div class="form-field-wrapper">
                            <mat-form-field appearance="fill">
                              <mat-label>Select Group</mat-label>
                              <mat-select
                                [disabled]="queparm"
                                [(ngModel)]="group"
                                multiple
                              >
                                <mat-option
                                  *ngFor="let groups of grouplist"
                                  [value]="groups?.value"
                                  >{{ groups?.name }}</mat-option
                                >
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div>
                            <span *ngIf="!group && errormes" style="color: red"
                              >*This field is required</span
                            >
                          </div>
                        </div>

                        <!-- <div *ngIf="checkfile == true" class="col-sm-1"></div> -->
                        <!-- <div class="col-sm-3 select-section" *ngIf="checkfile == true">
                                                    <div class="group-title">
                                                        <p>
                                                            Download sample excel/csv file.
                                                        </p>
                                                    </div>

                                                    <div class="excel-icon">
                                                        <div class="svg-name">
                                                            <svg  width="20px" height="20px" viewBox="0 0 32 32"
                                                                xmlns="http://www.w3.org/2000/svg" fill="var(--black-color)">
                                                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round"
                                                                    stroke-linejoin="round"></g>
                                                                <g id="SVGRepo_iconCarrier">
                                                                    <title>file_type_excel2</title>
                                                                    <path
                                                                        d="M28.781,4.405H18.651V2.018L2,4.588V27.115l16.651,2.868V26.445H28.781A1.162,1.162,0,0,0,30,25.349V5.5A1.162,1.162,0,0,0,28.781,4.405Zm.16,21.126H18.617L18.6,23.642h2.487v-2.2H18.581l-.012-1.3h2.518v-2.2H18.55l-.012-1.3h2.549v-2.2H18.53v-1.3h2.557v-2.2H18.53v-1.3h2.557v-2.2H18.53v-2H28.941Z"
                                                                        style="fill:#20744a;fill-rule:evenodd"></path>
                                                                    <rect x="22.487" y="7.439" width="4.323"
                                                                        height="2.2" style="fill:#20744a"></rect>
                                                                    <rect x="22.487" y="10.94" width="4.323"
                                                                        height="2.2" style="fill:#20744a"></rect>
                                                                    <rect x="22.487" y="14.441" width="4.323"
                                                                        height="2.2" style="fill:#20744a"></rect>
                                                                    <rect x="22.487" y="17.942" width="4.323"
                                                                        height="2.2" style="fill:#20744a"></rect>
                                                                    <rect x="22.487" y="21.443" width="4.323"
                                                                        height="2.2" style="fill:#20744a"></rect>
                                                                    <polygon
                                                                        points="6.347 10.673 8.493 10.55 9.842 14.259 11.436 10.397 13.582 10.274 10.976 15.54 13.582 20.819 11.313 20.666 9.781 16.642 8.248 20.513 6.163 20.329 8.585 15.666 6.347 10.673"
                                                                        style="fill:var(--white-color);fill-rule:evenodd">
                                                                    </polygon>
                                                                </g>
                                                            </svg>
                                                            <p class="t-icon">Excel</p>
                                                        </div>

                                                        <div class="svg-name">
                                                            <svg width="20px" height="20px" viewBox="-4 0 64 64"
                                                                xmlns="http://www.w3.org/2000/svg" fill="var(--black-color)">
                                                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round"
                                                                    stroke-linejoin="round"></g>
                                                                <g id="SVGRepo_iconCarrier">
                                                                    <path
                                                                        d="M5.106 0c-2.802 0-5.073 2.272-5.073 5.074v53.841c0 2.803 2.271 5.074 5.073 5.074h45.774c2.801 0 5.074-2.271 5.074-5.074v-38.605l-18.903-20.31h-31.945z"
                                                                        fill-rule="evenodd" clip-rule="evenodd"
                                                                        fill="#3276c3"></path>
                                                                    <path
                                                                        d="M20.306 43.197c.126.144.198.324.198.522 0 .378-.306.72-.703.72-.18 0-.378-.072-.504-.234-.702-.846-1.891-1.387-3.007-1.387-2.629 0-4.627 2.017-4.627 4.88 0 2.845 1.999 4.879 4.627 4.879 1.134 0 2.25-.486 3.007-1.369.125-.144.324-.233.504-.233.415 0 .703.359.703.738 0 .18-.072.36-.198.504-.937.972-2.215 1.693-4.015 1.693-3.457 0-6.176-2.521-6.176-6.212s2.719-6.212 6.176-6.212c1.8.001 3.096.721 4.015 1.711zm6.802 10.714c-1.782 0-3.187-.594-4.213-1.495-.162-.144-.234-.342-.234-.54 0-.361.27-.757.702-.757.144 0 .306.036.432.144.828.739 1.98 1.314 3.367 1.314 2.143 0 2.827-1.152 2.827-2.071 0-3.097-7.112-1.386-7.112-5.672 0-1.98 1.764-3.331 4.123-3.331 1.548 0 2.881.467 3.853 1.278.162.144.252.342.252.54 0 .36-.306.72-.703.72-.144 0-.306-.054-.432-.162-.882-.72-1.98-1.044-3.079-1.044-1.44 0-2.467.774-2.467 1.909 0 2.701 7.112 1.152 7.112 5.636.001 1.748-1.187 3.531-4.428 3.531zm16.994-11.254l-4.159 10.335c-.198.486-.685.81-1.188.81h-.036c-.522 0-1.008-.324-1.207-.81l-4.142-10.335c-.036-.09-.054-.18-.054-.288 0-.36.323-.793.81-.793.306 0 .594.18.72.486l3.889 9.992 3.889-9.992c.108-.288.396-.486.72-.486.468 0 .81.378.81.793.001.09-.017.198-.052.288z"
                                                                        fill="var(--white-color)"></path>
                                                                    <g fill-rule="evenodd" clip-rule="evenodd">
                                                                        <path
                                                                            d="M56.001 20.357v1h-12.8s-6.312-1.26-6.128-6.707c0 0 .208 5.707 6.003 5.707h12.925z"
                                                                            fill="#349C42"></path>
                                                                        <path
                                                                            d="M37.098.006v14.561c0 1.656 1.104 5.791 6.104 5.791h12.8l-18.904-20.352z"
                                                                            opacity=".5" fill="var(--white-color)"></path>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                            <p class="t-icon">CSV</p>
                                                        </div>
                                                    </div>

                                                </div> -->

                        <div
                          class="col-sm-2"
                          *ngIf="group.length == 0 && !allnewfilename && !id"
                        >
                          <div class="group-title">
                            <p>Manual</p>
                          </div>

                          <div class="form-field-wrapper">
                            <mat-form-field appearance="fill">
                              <textarea
                                [(ngModel)]="mannualentryvalue"
                                [disabled]="queparm"
                                matInput
                                name="questionText"
                                placeholder="Enter Manually"
                                required
                              ></textarea>
                            </mat-form-field>
                            <button
                              *ngIf="mannualentryvalue"
                              class="btn btn-theme upload-btn-design"
                              style="color: green"
                              (click)="uploadManual()"
                            >
                              Upload
                            </button>
                          </div>
                        </div>
                        <div class="col-sm-3" *ngIf="mannualentryvalue && id">
                          <div class="group-title">
                            <p>Manual</p>
                          </div>

                          <div class="form-field-wrapper">
                            <mat-form-field appearance="fill">
                              <textarea
                                [(ngModel)]="mannualentryvalue"
                                [disabled]="queparm"
                                matInput
                                name="questionText"
                                placeholder="Enter Manually"
                                required
                              ></textarea>
                            </mat-form-field>
                            <button
                              *ngIf="mannualentryvalue"
                              class="btn btn-theme"
                              style="color: green"
                              (click)="uploadManual()"
                            >
                              Upload
                            </button>
                          </div>
                        </div>

                        <div
                        class="col-sm-4 "
                        *ngIf="group.length == 0 && !mannualentryvalue && !id"
                      >
                      <div class="group-title">
                        <p>Files</p>
                      </div>
                        <div class="d-flex align-items-start gap-3"> 
                         
    
                       
                            <div class="file-input-grup">
                              <div class="drop-area">
                                <i class="fa fa-upload"></i>
                                <div class="title-sub">
                                  <div class="title">
                                    Drop file here or click to upload
                                  </div>
                                  <div class="sub">
                                    Only Excel/CSV files are allowed to upload
                                  </div>
                                </div>
                              </div>
                              <input
                                style="cursor: unset"
                                class="custom-file-input choosefile upload-input-file"
                                type="file"
                                [(ngModel)]="file"
                                (change)="selectFile($event)"
                              />
                              <div *ngIf="errorforfile">
                                <p class="filename-text">{{ this.allnewfilename }}</p>
                                <!-- <span
                                  *ngIf="!allnewfilename && errormes"
                                  style="color: red"
                                  >*This field is required</span
                                > -->
                              </div>
                            </div>
                        
                          <div  class=" text-end">
                              <i
                              class="fa fa-download download-icon "
                              style="cursor: pointer"
                              (click)="downloadexcel()"
                              (mouseover)="showTooltip = true"
                              (mouseout)="showTooltip = false"
                            ></i>
                            </div>
                            <span class="tooltip-text" [ngClass]="{'show': showTooltip}">Download sample file.</span>
                        </div>
                      </div>
                     
                      <ng-container  *ngIf="!id">
                        <div class="col-sm-3 hii"
                        *ngIf="
                          !id &&
                          allnewfilename &&
                          !mannualentryvalue &&
                          !group.length
                        "
                      >
                        <div class="group-title">
                          <p>Success Ratio</p>
                        </div>
                        <div class="bg-theme">
                          <p>Total Recipient :<span> {{ totalCount || 0}}</span></p>
                          <p>
                            Valid Recipient :<span>{{
                              validCount || 0
                            }}</span>
                          </p>
                          <p>
                            Duplicate Recipient:<span>{{
                              duplicateRecords || 0
                            }}</span>
                          </p>
                          <p>
                            Invalid Recipient :<span>{{
                              invalidMobNumberCount 
                            }}</span>
                          </p>
                        </div>
                      </div>
                      <div
                      class="col-sm-3 hii"
                        *ngIf="
                          !id &&
                          mannualentryvalue &&
                          !allnewfilename &&
                          !group.length
                        "
                      >
                        <div class="group-title">
                          <p>Success Ratio</p>
                        </div>
                        <div class="bg-theme">
                          <p>Total Recipient :<span> {{
                            manualTotalCount || 0
                          }}</span></p>
                          <p>
                            Valid Recipient :<span>{{
                              manualvalidCount || 0
                            }}</span>
                          </p>
                          <p>
                            Duplicate Recipient:<span>{{
                              manualduplicateRecords || 0
                            }}</span>
                          </p>
                          <p>
                            Invalid Recipient :<span>{{
                              manualinvalidMobNumberCount || 0
                            }}</span>
                          </p>
                        </div>
                      </div>
                      <div
                      class="col-sm-3 hii"
                        *ngIf="
                          !id &&
                          group.length &&
                          !allnewfilename &&
                          !mannualentryvalue
                        "
                      >
                        <div class="group-title">
                          <p>Success Ratio</p>
                        </div>
                        <div class="bg-theme">
                          <p>Total Recipient :<span> {{
                            groupTotalCount || 0
                          }}</span></p>
                          <p>
                            Valid Recipient :<span>{{
                              groupvalidCount || 0
                            }}</span>
                          </p>
                          <p>
                            Duplicate Recipient:<span>{{
                              groupduplicateRecords || 0
                            }}</span>
                          </p>
                          <p>
                            Invalid Recipient :<span>{{
                              groupinvalidMobNumberCount || 0
                            }}</span>
                          </p>
                        </div>
                      </div>
                      </ng-container>
                      
<ng-container *ngIf="id">
    <div class="col-sm-3 hello" >
        <div class="group-title">
          <p>Success Ratio</p>
        </div>
        <div class="bg-theme">
          <p>Total Recipient :<span> {{
            totalCount || recordCount
          }}</span></p>
          <p>
            Valid Recipient :<span>{{
              validCount || recordCount
            }}</span>
          </p>
          <!-- <p>
            Duplicate Recipient:<span>{{
              duplicateRecords || 0
            }}</span>
          </p>
          <p>
            Invalid Recipient :<span>{{
              invalidMobNumberCount || 0
            }}</span>
          </p> -->
        </div>
        <!-- <div class="bg-theme" *ngIf="mannualentryvalue && !allnewfilename && !group.length">
                                      <p>Total Recipient :<span> 0</span></p>
                                      <p>Valid Recipient :<span>{{manualrecordCount || 0}}</span></p>
                                      <p>Duplicate Recipient:<span>{{manualduplicateRecords || 0}}</span></p>
                                      <p>Invalid Recipient :<span>{{manualinvalidMobNumberCount || 0}}</span>
                                       <i
                                              class="fa fa-download"></i>
                                      </p>
                                  </div>
                                  <div class="bg-theme" *ngIf="group.length && !allnewfilename && !mannualentryvalue">
                                      <p>Total Recipient :<span> 0</span></p>
                                      <p>Valid Recipient :<span>{{grouprecordCount || 0}}</span></p>
                                      <p>Duplicate Recipient:<span>{{groupduplicateRecords || 0}}</span></p>
                                      <p>Invalid Recipient :<span>{{groupinvalidMobNumberCount || 0}}</span>
                                         <i
                                              class="fa fa-download"></i>
                                      </p>
                                  </div> -->
      </div>
</ng-container>
</div>

                      <!-- <div class="info-fill-tab border-0">
                                                <div class="button-group mt-2">
                                                    <button *ngIf="checkfile == true" type="submit"
                                                        class="btn btn-theme">Upload</button>
                                                </div>
                                            </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div class="info-fill-tab border-0">
          <mat-accordion class="mb-2">
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header class="headings">
                <mat-panel-title>
                  <span>3. Campaign Content*</span>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="inner-body-content">
                <div class="container-fluid g-0">
                  <div class="row">
                    <div class="col-md-8">
                     <div [ngClass]=" id ? 'd-flex' : '' "
>    
<label class="switch-action discard selectSlider">
  <input 
    type="checkbox"
    (change)="contentchange()"
    [disabled]="true"
  />
  <span class="slider round" style="cursor: default; opacity: 0.5;"></span>
</label>                 <div class="d-block">
  <div class="d-flex ms-3">
   
    <div class="low select-content">
      <div
        class="form-field-wrapper-one phone-view"
        *ngIf="isActive1 == false"
      >
        <mat-form-field appearance="fill">
          <mat-label>Language</mat-label>
          <mat-select
            [disabled]="queparm"
            [(ngModel)]="language"
            multiple
            (selectionChange)="languagechange(language)"
          >
            <mat-option
              *ngFor="let language of languages"
              [value]="language?.name"
              >{{ language?.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div
        style="opacity: 0.3"
        class="file-input-group-inner-one"
        *ngIf="!isActive1"
      >
        <div class="drop-area">
          <i class="fa fa-upload" aria-hidden="true"></i>
          <div class="title">Upload Your Assets</div>
          <input
            [disabled]="queparm"
            class="custom-file-input  "
            type="file"
          />
        </div>
      </div>

      <div class="row" *ngIf="isActive1 == true && !id">
        <div class="p-0" >
          <button
            class="select-text"
            class="pointer"
            (click)="selectpopup()"
            
            [disabled]="queparm == 'view'"
          >
            Select Existing Template
          </button>
        </div>
       
      </div>
      <div>
        <span
          *ngIf="!selectedtemplate && errormes"
          style="color: red"
          >*Please select template</span
        >
      </div>
      <div *ngIf="isActive1 && selectedtemplate?.name && !id" class="d-flex gap-5">
        <div>
          <p class="select-lable-value">
            Selected Template
          </p>
          <p class="select-template-name" *ngIf="selectedtemplate?.name ">{{ selectedtemplate?.name }}</p>
        </div>


        <div
          class=" "
          *ngIf="isActive1 && selectedtemplate?.name"
        >
          <button
            class="btn-preview"
            (click)="openPreview(selectedtemplate)"
          >
            PREVIEW
          </button>
        </div>
      </div>
     
       <div class="d-flex gap-5" *ngIf="isActive1 && id">
        <div>
          <p class="select-lable-value">
            Selected Template
          </p>
          <p class="select-template-name" *ngIf="selectedTemplateName">{{ selectedTemplateName }}</p>
        </div>
       

        <div
          class=""
          *ngIf="isActive1 && selectedTemplateName"
        >
          <button
            class="btn-preview"
            [disabled]="queparm == 'view'"
            (click)="openPreview(selectedTemplateData)"
          >
            PREVIEW
          </button>
        </div>
      </div>
    </div>
  </div>

</div>
                     </div>
                      <!-- <div class="row select-content" *ngIf="isActive1 == true">
                                                <div class="col-sm-3" >
                                                    <div class="group-title">
                                                        <p>
                                                            Customer No Field
                                                        </p>
                                                    </div>

                                                    <div class="form-field-wrapper">
                                                        <mat-form-field appearance="fill">
                                                            <mat-label></mat-label>

                                                            <mat-select [disabled]="queparm" [(ngModel)]="custno">


                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="col-sm-3" *ngIf="isActive1 == true">
                                                    <div class="group-title">
                                                        <p>
                                                            Product No Field
                                                        </p>
                                                    </div>

                                                    <div class="form-field-wrapper">
                                                        <mat-form-field appearance="fill">
                                                            <mat-label></mat-label>

                                                            <mat-select [disabled]="queparm" [(ngModel)]="custno">


                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row select-content" *ngIf="isActive1 == true">
                                                <div class="col-sm-3" >
                                                    <div class="group-title">
                                                        <p>
                                                            To Email
                                                        </p>
                                                    </div>


                                                    <div class="form-field-wrapper">
                                                        <mat-form-field appearance="fill">
                                                            <mat-label></mat-label>
                                                            <mat-select [disabled]="queparm" [(ngModel)]="selectmail">
                                                                <mat-option></mat-option>

                                                            </mat-select>
                                                        </mat-form-field>

                                                    </div>
                                                </div>
                                                <div class="col-sm-3" *ngIf="isActive1 == true">
                                                    <div class="group-title">
                                                        <p>
                                                            MSISDN
                                                        </p>
                                                    </div>


                                                    <div class="form-field-wrapper">
                                                        <mat-form-field appearance="fill">
                                                            <mat-label></mat-label>
                                                            <mat-select [disabled]="queparm" [(ngModel)]="selectMSISDN">
                                                                <mat-option></mat-option>

                                                            </mat-select>
                                                        </mat-form-field>

                                                    </div>
                                                </div>
                                                <div class="col-sm-3" *ngIf="isActive1 == true">
                                                    <div class="group-title">
                                                        <p>
                                                            Shortcode
                                                        </p>
                                                    </div>


                                                    <div class="form-field-wrapper">
                                                        <mat-form-field appearance="fill">
                                                            <mat-label></mat-label>
                                                            <mat-select [disabled]="queparm"
                                                                [(ngModel)]="selectshortcode">
                                                                <mat-option *ngFor="let shortcode of shortcodes"
                                                                    [value]="shortcode.value">{{shortcode.name}}</mat-option>

                                                            </mat-select>
                                                        </mat-form-field>

                                                    </div>
                                                </div>
                                                <div class="col-sm-3" *ngIf="isActive1 == true">
                                                    <label class="connect-d">Use Connectors Details</label>
                                                    <div class="outer-box">
                                                        <label class="new-label">
                                                            <input type="checkbox" (change)="chooseyes($event)"
                                                                [checked]="selectyes">
                                                            <span>Yes</span>
                                                        </label>
                                                        <label class="new-label">
                                                            <input type="checkbox" (change)="chooseno($event)"
                                                                [checked]="selectno">
                                                            <span>No</span>
                                                        </label>

                                                    </div>

                                                </div>
                                                <div class="col-sm-3" *ngIf="isActive1 == true && selectyes ">
                                                    <div class="group-title">
                                                        <p>
                                                            Connector
                                                        </p>
                                                    </div>


                                                    <div class="form-field-wrapper">
                                                        <mat-form-field appearance="fill">
                                                            <mat-label>Select Smtp</mat-label>
                                                            <mat-select [disabled]="queparm"
                                                                [(ngModel)]="selectconnector">
                                                                <mat-option *ngFor="let smtp of smtps"
                                                                    [value]="smtp.value">{{smtp.name}}</mat-option>

                                                            </mat-select>
                                                        </mat-form-field>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row select-content" *ngIf="isActive1 == true">
                                                <div class="col-sm-3" >
                                                    <div class="group-title">
                                                        <p>
                                                            Message Template
                                                        </p>
                                                    </div>


                                                    <div class="form-field-wrapper">
                                                        <mat-form-field appearance="fill">
                                                            <mat-label>Select Template</mat-label>
                                                            <mat-select [disabled]="queparm"
                                                                [(ngModel)]="selectTemplate">
                                                                <mat-option *ngFor="let camptemplate of camptemplates"
                                                                    [value]="camptemplate?.value">{{camptemplate?.name}}</mat-option>

                                                            </mat-select>
                                                        </mat-form-field>

                                                    </div>
                                                </div>

                                            </div> -->
                      <div class="row select-content">
                        <div class="three-button" *ngIf="isActive1 == false">
                          <span
                            class="three-button"
                            *ngFor="let lang of language"
                          >
                            <span class="Eng">{{ lang }}</span>
                          </span>
                        </div>

                        <mat-tab-group
                          [(selectedIndex)]="selectednum"
                          *ngIf="isActive1 == false"
                          (selectedTabChange)="languagechange(language)"
                        >
                          <mat-tab label="English" *ngIf="english == true">
                            <div>
                              <div class="subb">
                                <span class="from-one">Subject </span>
                                <div class="coldiv">
                                  <div class="form-field-wrappers">
                                    <mat-form-field class="example-full-width">
                                      <input
                                        [disabled]="queparm"
                                        matInput
                                        [(ngModel)]="sub"
                                      />
                                    </mat-form-field>
                                  </div>
                                </div>
                              </div>
                              <ckeditor
                                [disabled]="queparm"
                                [(ngModel)]="editorval"
                                #editor
                                id="description"
                                name="description"
                              >
                              </ckeditor>
                            </div>
                          </mat-tab>
                          <mat-tab label="Arabic" *ngIf="arabic == true">
                            <div>
                              <div class="subb">
                                <span class="from-one">Subject </span>
                                <div class="coldiv">
                                  <div class="form-field-wrappers">
                                    <mat-form-field
                                      class="example-full-width arabictext"
                                    >
                                      <input
                                        [disabled]="queparm"
                                        matInput
                                        [(ngModel)]="sub1"
                                      />
                                    </mat-form-field>
                                  </div>
                                </div>
                              </div>
                              <ckeditor
                                #editor
                                id="description"
                                name="description"
                                [disabled]="queparm"
                                [config]="editorConfig"
                                [(ngModel)]="editorval1"
                              >
                              </ckeditor>
                            </div>
                          </mat-tab>
                          <!-- <mat-tab label="French" *ngIf="french ==true">
                                                        <div>
                                                            <div class="subb">
                                                                <span class="from-one">Subject </span>
                                                                <div class="coldiv">

                                                                    <div class="form-field-wrappers">
                                                                        <mat-form-field class="example-full-width">

                                                                            <input [disabled]="queparm" matInput>
                                                                        </mat-form-field>

                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <ckeditor #editor id="description" name="description">
                                                            </ckeditor>

                                                        </div>
                                                    </mat-tab> -->
                        </mat-tab-group>

                        <div class="col-12 position-relative">
                          <div class="tab-selection-wrapper">
                            <div class="info">
                              <div class="last-line">
                                <div>
                                  <i
                                    class="fa fa-question-circle-o me-1 question-icon"
                                    (click)="openDialog('0ms', '0ms')"
                                  ></i>
                                </div>
                                <div style="opacity: 0.5;" class="line">
                                  In Addition to plain text, enter##
                                  ParameterName## in the template message
                                  without any spaces for the parameter fields
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                   
                  <div
                    class="col-sm-4"
                    *ngIf="
                      campaign?.campaign?.excelFilePath !==
                        'E:\\FCC\\Campaign\\' && id
                    "
                  >
                    <div class="d-flex align-items-start gap-3">
                      <div class="group-title">
                        <p>Files</p>
                      </div>

                     
                        <div class="file-input-grup file-outer-box">
                          <div class="drop-area">
                            <i class="fa fa-upload"></i>
                            <div class="title-sub">
                              <div class="title">
                                Drop file here or click to upload
                              </div>
                              <div class="sub">
                                Only Excel/CSV files are allowed to upload
                              </div>
                            </div>
                          </div>
                          
                          <input
                            style="cursor: unset"
                            class="custom-file-input choosefile "
                            type="file"
                            [(ngModel)]="file"
                            (change)="selectFile($event)" [disabled]="queparm=='view'"
                          />
                          <div>
                            <p class="filename-text">{{ this.allnewfilename }}</p>
                            <span
                              *ngIf="!allnewfilename && errormes"
                              style="color: red"
                              >*This field is required</span
                            >
                          </div>
                        </div>
                      
                      <div  class=" text-end">
                          <i
                          class="fa fa-download download-icon "
                          style="cursor: pointer"
                          (click)="downloadexcel()"
                        ></i>
                        </div>
                    </div>
                  </div>

                    <div
                      class="col-md-4 text-center"
                      *ngIf="
                        english == true &&
                        selectednum == 0 &&
                        isActive1 == false
                      "
                    >
                      <div class="frame-class">
                        <img
                          class="frame-img"
                          src="../../../../../assets/images/frame-1.png"
                        />
                        <div class="template">
                          <div class="inside-box" [innerHTML]="sub"></div>
                          <div class="inside-box" [innerHTML]="editorval"></div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-md-4 text-center"
                      *ngIf="
                        arabic == true && isActive1 == false && selectednum == 1
                      "
                    >
                      <div class="frame-class">
                        <img
                          class="frame-img"
                          src="../../../../../assets/images/frame-1.png"
                        />
                        <div class="template template-box">
                          <div
                            class="inside-box arabictext"
                            [innerHTML]="sub1"
                          ></div>
                          <div
                            class="inside-box arabictext"
                            [innerHTML]="editorval1"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-8">
                      <div class="NgxEditor__Wrapper"></div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div class="info-fill-tab border-0">
          <mat-accordion>
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header class="headings">
                <mat-panel-title>
                  <span>4. Campaign Schedule</span>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="inner-body-content">
                <div class="container-fluid g-0">
                  <div class="row">
                    <div class="col-md-8">
                      <div class="row">
                        <div class="col-sm-3">
                          <div class="form-field-wrapper">
                            <mat-form-field
                              class="example-full-width date-timenew-box"
                            >
                              <mat-label>Choose a date*</mat-label>
                              <input
                                [disabled]="queparm"
                                [(ngModel)]="ChooseDate"
                                [min]="minDate"
                                matInput
                                [matDatepicker]="picker"
                                (dateChange)="checkdate($event)"
                              />
                              <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
                              <mat-datepicker-toggle
                                matIconSuffix
                                [for]="picker"
                              ></mat-datepicker-toggle>

                              <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="form-field-wrapper">
                            <mat-form-field class="example-full-width">
                              <mat-label  >Choose a time*</mat-label>
                              <input
                                class="clock-icon choose-lable"
                                [disabled]="queparm"
                                [(ngModel)]="ChooseTime"
                                matInput
                                type="time"
                                (ngModelChange)="checkTime($event)"
                                onfocus="this.showPicker()"
                              />
                            </mat-form-field>
                          </div>
                        </div>

                        <div class="col-sm-6">
                          <div class="form-field-wrapper">
                            <mat-form-field
                              class="disable-bg-input"
                              appearance="fill"
                            >
                              <mat-label>Sender ID</mat-label>
                              <mat-select
                                style="opacity: 0.5"
                                [ngClass]="senderid1 ? 'disable' : ''"
                                [(ngModel)]="senderid1"
                                [disabled]="senderid1"
                              >
                                <mat-option
                                  *ngFor="let shortcode of shortcodes"
                                  [value]="shortcode?.value"
                                  >{{ shortcode?.name }}</mat-option
                                >
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-12">
                          <div class="row">
                            <div class="col-sm-12">
                              <div class="check-toggle">
                                <p>Split In Batches?</p>

                                <label class="switch-action">
                                  <input
                                    [disabled]="queparm"
                                    type="checkbox"
                                    (change)="getsplit()"
                                  />
                                  <span class="slider round"></span>
                                </label>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-6">
                              <p class="numbatches">No. of Batches</p>
                              <div class="center">
                                <div class="input-group">
                                  <div class="input-area-inc">
                                    <span class="input-group-btn">
                                      <button
                                        [disabled]="queparm || !isActive"
                                        (click)="Minus()"
                                        type="button"
                                        class="btn btn-number"
                                        data-type="minus"
                                        data-field="quant[2]"
                                      >
                                        <i class="fa fa-chevron-left"></i>

                                        <span class="glyphicon glyphicon-minus">
                                        </span>
                                      </button>
                                    </span>
                                    <input
                                    [disabled]="queparm || batchvalue == 1 || !isActive"
                                      [(ngModel)]="batchvalue"
                                      (ngModelChange)="checkBatch($event)"
                                      type="text"
                                      name="quant[2]"
                                      class="form-control input-number"
                                      batchvalue="10"
                                      min="1"
                                      max="100"
                                    />
                                    <span class="input-group-btn">
                                      <button
                                      [disabled]="queparm || !isActive"
                                        (click)="Add()"
                                        type="button"
                                        class="btn btn-number"
                                        data-type="plus"
                                        data-field="quant[2]"
                                      >
                                        <i class="fa fa-chevron-right"></i>

                                        <span
                                          class="glyphicon glyphicon-plus"
                                        ></span>
                                      </button>
                                    </span>
                                  </div>
                                  <span class="recbatch"  *ngIf="
                                  !id &&
                                  allnewfilename &&
                                  !mannualentryvalue &&
                                  !group.length
                                "
                                    >Recipients per Batch: {{recipientPerBatch?.toFixed(2)}}</span
                                  >
                                  <span class="recbatch"   *ngIf="
                                  !id &&
                                  mannualentryvalue &&
                                  !allnewfilename &&
                                  !group.length
                                "
                                    >Recipients per Batch: {{recipientPerBatch?.toFixed(2)}}</span
                                  >
                                  <span class="recbatch"   *ngIf="
                                  !id &&
                                  group.length &&
                                  !allnewfilename &&
                                  !mannualentryvalue
                                "
                                    >Recipients per Batch: {{recipientPerBatch?.toFixed(2)}}</span
                                  >
                                  <span class="recbatch"   *ngIf="
                                  id
                                "
                                    >Recipients per Batch: {{recipientPerBatch?.toFixed(2)}}</span
                                  >
                                </div>
                                <div>
                                  <span
                                    *ngIf="!batchvalue && errormes"
                                    style="color: red"
                                    >*This field is required</span
                                  >
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <p class="Timebatches">
                                Time Gap Between Batches
                              </p>

                              <div class="time-date-gap">
                                <div>
                                  <div class="form-field-wrapper">
                                    <P class="fromtext">Hours</P>

                                    <ngx-timepicker-field
                                    [disabled]="queparm == 'view'"
                                      [(ngModel)]="calltime"
                                      (ngModelChange)="checkHours($event)"
                                      [format]="24"
                                      style="height: 46px; border-radius: 5px"
                                      class="form-control hours-picker"
                                    ></ngx-timepicker-field>
                                  </div>
                                  <div>
                                    <span
                                      *ngIf="!calltime && errormes"
                                      style="color: red"
                                      >*This field is required</span
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-sm-12">
                          <div class="check-toggle">
                            <p>Set Allowed Days & Time For This Campaign?</p>
                            <label class="switch-action">
                              <input [disabled]="queparm" type="checkbox" (change)="setDayTime()"/>
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <p class="discardmsg">
                            Days On Which Campaign Will Run
                          </p>
                          <div class="weekday">
                            <button [disabled]="queparm == 'view'"
                              (click)="toggleDay(0)"
                              [ngClass]="
                                selectedDays.includes(0) ? 'green' : 'circlebtn'
                              "
                            >
                              Su
                            </button>
                            <button [disabled]="queparm == 'view'"
                              (click)="toggleDay(1)"
                              [ngClass]="
                                selectedDays.includes(1) ? 'green' : 'circlebtn'
                              "
                            >
                              Mo
                            </button>
                            <button [disabled]="queparm == 'view'"
                              (click)="toggleDay(2)"
                              [ngClass]="
                                selectedDays.includes(2) ? 'green' : 'circlebtn'
                              "
                            >
                              Tu
                            </button>
                            <button [disabled]="queparm == 'view'"
                              (click)="toggleDay(3)"
                              [ngClass]="
                                selectedDays.includes(3) ? 'green' : 'circlebtn'
                              "
                            >
                              We
                            </button>
                            <button [disabled]="queparm == 'view'"
                              (click)="toggleDay(4)"
                              [ngClass]="
                                selectedDays.includes(4) ? 'green' : 'circlebtn'
                              "
                            >
                              Th
                            </button>
                            <button [disabled]="queparm == 'view'"
                              (click)="toggleDay(5)"
                              [ngClass]="
                                selectedDays.includes(5) ? 'green' : 'circlebtn'
                              "
                            >
                              Fr
                            </button>
                            <button [disabled]="queparm == 'view'"
                              (click)="toggleDay(6)"
                              [ngClass]="
                                selectedDays.includes(6) ? 'green' : 'circlebtn'
                              "
                            >
                              Sa
                            </button>
                          </div>
                          <div>
                            <span
                              *ngIf="errormes && !selectedDays.length"
                              style="color: red"
                              >*This field is required</span
                            >
                          </div>
                        </div>
                        <div class="col-md-6">
                          <p class="discardmsg">
                            Allowed Start And End Time For Each Day
                          </p>
                          <div class="time-date-gap">
                            <div>
                              <div class="form-field-wrapper">
                                <P class="fromtext">From</P>
                                <ngx-timepicker-field
                                  [(ngModel)]="calltime3"
                                  (ngModelChange)="fromCheck($event)"
                                  [format]="24"
                                  style="height: 46px; border-radius: 5px"
                                  class="form-control hours-picker"
                                ></ngx-timepicker-field>
                              </div>
                              <div>
                                <span
                                  *ngIf="!calltime3 && errormes"
                                  style="color: red"
                                  >*This field is required</span
                                >
                              </div>
                            </div>
                            <div>
                              <div class="form-field-wrapper">
                                <P class="fromtext">To</P>
                                <ngx-timepicker-field
                                  [(ngModel)]="calltimeto3"
                                  (ngModelChange)="toCheck($event)"
                                  [format]="24"
                                  style="height: 46px; border-radius: 5px"
                                  class="form-control hours-picker"
                                ></ngx-timepicker-field>
                              </div>
                              <div>
                                <span
                                  *ngIf="!calltimeto3 && errormes"
                                  style="color: red"
                                  >*This field is required</span
                                >
                              </div>
                            </div>
                          </div>
                       
                          <p class="camptime mt-3">Campaign Time {{ timeDifference }}</p>
                         
                        </div>
                      </div>

                      <div class="discardmsg">
                        <p>Discard Message If Not Sent During Selected Time?</p>
                        <label class="switch-action discard">
                          <input [disabled]="queparm" type="checkbox" />
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>

                   
                    <!-- <div class="col-md-4">

                                        </div> -->
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div class="info-fill-tab border-0">
          <div class="button-group mt-2">
            <button
              *ngIf="!queparm"
              type="submit"
              (click)="Submit()"
              class="btn btn-theme"
            >
              Save
            </button>
            <button
              class="btn btn-cancle"
              [routerLink]="'/campaign'"
              mat-dialog-close
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-footer></app-footer>
</mat-drawer-container>
