import { Component } from '@angular/core';

@Component({
  selector: 'app-campaignbox',
  templateUrl: './campaignbox.component.html',
  styleUrls: ['./campaignbox.component.scss']
})
export class CampaignboxComponent {

}
