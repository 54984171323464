import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BnNgIdleService } from 'bn-ng-idle';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    private bnIdle: BnNgIdleService,
    private readonly router: Router,
    public dialog: MatDialog,
  ){
    this.bnIdle.startWatching(900).subscribe((res) => {
      if(res && this.router.url !='/login') {
   
        localStorage.clear();
        this.router.navigate(['/login']);
        this.dialog.closeAll()
        
      }
    })

  }


  title = 'campain-management';
  dateRange = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });
  
}
