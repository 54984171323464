<div class="header-wrapper">
    <span> {{data?.val  == 'add' ? 'Add Group'  : data?.val == 'edit' ? 'Edit Group': 'View Group'}}</span>
    <button mat-icon-button class="btn close-button" [mat-dialog-close]="true">
        <i class="fa fa-close"></i>
      </button>
</div>
<form [formGroup]="productForm" (ngSubmit)="onSubmit()" *ngIf="data?.val  == 'add' || data?.val  == 'edit'" >
  
<div class="add-group-wrapper">
    <div mat-dialog-content>

        <div class="container-fluid">
            <div class="row mb-3">
                <div class="col-md-6">
                    <mat-form-field class="example-full-width">
                        <mat-label>Group Name*</mat-label>
                        <input [disabled]="!data?.val" matInput [ngModelOptions]="{standalone: true}"[(ngModel)]="groupname" (keydown)="preventSpace($event)" (input)="validateInputLength()" >
                    </mat-form-field>
                    <span *ngIf="!groupname && errormes" style="color:red;">*This field is required</span>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="example-full-width">
                        <mat-label>Group Description*</mat-label>
                        <input [disabled]="!data?.val" matInput [ngModelOptions]="{standalone: true}" [(ngModel)]="groupdescription"  (keydown)="preventSpace($event)" (input)="validateInputLength1()">
                    </mat-form-field>
                    <span *ngIf="!groupdescription && errormes" style="color:red;">*This field is required</span>
                </div>
            </div>
            <div class="row mb-3" > 
                <div class="col-md-6">
                    <mat-form-field appearance="fill">
                        <mat-label>Channel*</mat-label>
                        <mat-select [disabled]="!data?.val" class="mat-channel" [ngModelOptions]="{standalone: true}"  [(ngModel)]="channelid" multiple>
                            <mat-option *ngFor="let channel of channels" [value]="channel?.value"> {{channel?.name}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    <span *ngIf="channelid.length == 0 && errormes" style="color:red;">*This field is required</span>
                </div>
                <div class="col-md-6">
                    <div class="check-toggle">
                        <p>Active</p>
                        <label  [ngClass]="activeornot == true ? 'switch-action' : 'switch-action1'">
                            <input [disabled]="!data?.val" type="checkbox" [(ngModel)] ="activeornot" [ngModelOptions]="{standalone: true}"  (change)="activenot()">
                            <span class="slider round"></span>
                        </label>
                    </div>
                </div>
            </div>
            <!-- <div class="row mb-3">
                <div class="col-md-12 inner-member-area">
                    <h3>Add Members</h3>
                    <div class="row">
                        <div class="col-12">
                            <div class="tab-selection-wrapper">
                                <mat-tab-group>
                                    <mat-tab label="Add Manually">
                                        <div class="row">
                                            <div class="col-12">
                                                    <table summary="table" class="table table-bordered" formArrayName="quantities">  
                                                        <tr class="tab-row-data1">  
                                                        <th colspan="3">Add Multiple Quantity:</th>  
                                                        <th width="150px" style="text-align: end; padding: 0 10px 0 0;"><button type="button" (click)="addQuantity()" class="btn btn-primary add-button">Add More</button></th>  
                                                        </tr>  
                                                        <tr class="tab-row-data2" *ngFor="let quantity of quantities()?.controls; let i=index" [formGroupName]="i">  
                                                        
                                                        <td colspan="3"> 
                                                            <div class="col-md-12">
                                                                <input type="text" formControlName="price" class="form-control">  

                                                            </div> 
                                                            
                                                        </td>  
                                                        <td class="remove-data"> 
                                                        
                                                            <button (click)="removeQuantity(i)" class="btn btn-danger">Remove</button> 
                                                            
                                                        </td>  
                                                        </tr>  
                                                    </table>
                                            </div>

                                           
                                            </div>

                                            <div class="row">
                                            <div class="col-md-6 mb-3">
                                                <mat-form-field appearance="fill">
                                                    <mat-label>Language</mat-label>
                                                    <mat-select [ngModelOptions]="{standalone: true}" [(ngModel)]="language">
                                                        <mat-option *ngFor="let language of languages" [value]="language?.value">{{language?.name}}</mat-option>
                                                    </mat-select>
                                                  </mat-form-field>
                                            </div>
                                        </div>
                                    </mat-tab>
        
                                    <mat-tab label="Upload File">
                                        <div class="row">
                                            <div class="col-md-12 mb-3">
                                                <div class="form-field-wrapper">
                                                    <div class="file-input-grup">
                                                        <div class="drop-area">
                                                            <i class="fa fa-upload"></i>
                                                            <div class="title-sub">
                                                                <div class="title">
                                                                    Drop file here or click to upload
                                                                </div>
                                                                <div class="sub">
                                                                    Only Excel/CSV files are allowed to upload
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <input class="custom-file-input" type="file">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-tab>
        
                               
        
                                </mat-tab-group>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>
<div mat-dialog-actions>
<button *ngIf="data?.val !== 'view'" class="btn btn-theme">{{data?.val == 'add' ? 'Add' : 'Edit'}}</button>

<button class="btn btn-cancle" mat-dialog-close>Cancel</button>
</div>
</form>
<form [formGroup]="productForm" (ngSubmit)="onSubmit()" *ngIf="data?.val  == 'view'">
  
    <div class="add-group-wrapper">
        <div mat-dialog-content>
    
            <div class="container-fluid">
                <div class="row mb-3">
                    <div class="col-md-6">
                        <mat-form-field class="example-full-width">
                            <mat-label>Group Name</mat-label>
                            <input [disabled]="data?.val" matInput [ngModelOptions]="{standalone: true}"[(ngModel)]="groupname">
                        </mat-form-field>
                        <span *ngIf="!groupname && errormes" style="color:red;">*This field is required</span>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field class="example-full-width">
                            <mat-label>Group Description</mat-label>
                            <input [disabled]="data?.val" matInput [ngModelOptions]="{standalone: true}" [(ngModel)]="groupdescription"  >
                        </mat-form-field>
                        <span *ngIf="!groupdescription && errormes" style="color:red;">*This field is required</span>
                    </div>
                </div>
                <div class="row mb-3" > 
                    <div class="col-md-6">
                        <mat-form-field appearance="fill">
                            <mat-label>Channel</mat-label>
                            <mat-select [disabled]="data?.val" class="mat-channel" [ngModelOptions]="{standalone: true}"  [(ngModel)]="channelid" multiple >
                                <mat-option *ngFor="let channel of channels" [value]="channel?.value"> {{channel?.name}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                        <span *ngIf="!channelid.length && errormes" style="color:red;">*This field is required</span>
                    </div>
                    <div class="col-md-6">
                        <div class="check-toggle">
                            <p>Active</p>
                            <label  [ngClass]="activeornot == true ? 'switch-action' : 'switch-action1'">
                                <input [disabled]="data?.val" type="checkbox" [(ngModel)] ="activeornot" [ngModelOptions]="{standalone: true}"  (change)="activenot()">
                                <span class="slider round"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <!-- <div class="row mb-3">
                    <div class="col-md-12 inner-member-area">
                        <h3>Add Members</h3>
                        <div class="row">
                            <div class="col-12">
                                <div class="tab-selection-wrapper">
                                    <mat-tab-group>
                                        <mat-tab label="Add Manually">
                                            <div class="row">
                                                <div class="col-12">
                                                        <table summary="table" class="table table-bordered" formArrayName="quantities">  
                                                            <tr class="tab-row-data1">  
                                                            <th colspan="3">Add Multiple Quantity:</th>  
                                                            <th width="150px" style="text-align: end; padding: 0 10px 0 0;"><button type="button" (click)="addQuantity()" class="btn btn-primary add-button">Add More</button></th>  
                                                            </tr>  
                                                            <tr class="tab-row-data2" *ngFor="let quantity of quantities()?.controls; let i=index" [formGroupName]="i">  
                                                            
                                                            <td colspan="3"> 
                                                                <div class="col-md-12">
                                                                    <input type="text" formControlName="price" class="form-control">  
    
                                                                </div> 
                                                                
                                                            </td>  
                                                            <td class="remove-data"> 
                                                            
                                                                <button (click)="removeQuantity(i)" class="btn btn-danger">Remove</button> 
                                                                
                                                            </td>  
                                                            </tr>  
                                                        </table>
                                                </div>
    
                                               
                                                </div>
    
                                                <div class="row">
                                                <div class="col-md-6 mb-3">
                                                    <mat-form-field appearance="fill">
                                                        <mat-label>Language</mat-label>
                                                        <mat-select [ngModelOptions]="{standalone: true}" [(ngModel)]="language">
                                                            <mat-option *ngFor="let language of languages" [value]="language?.value">{{language?.name}}</mat-option>
                                                        </mat-select>
                                                      </mat-form-field>
                                                </div>
                                            </div>
                                        </mat-tab>
            
                                        <mat-tab label="Upload File">
                                            <div class="row">
                                                <div class="col-md-12 mb-3">
                                                    <div class="form-field-wrapper">
                                                        <div class="file-input-grup">
                                                            <div class="drop-area">
                                                                <i class="fa fa-upload"></i>
                                                                <div class="title-sub">
                                                                    <div class="title">
                                                                        Drop file here or click to upload
                                                                    </div>
                                                                    <div class="sub">
                                                                        Only Excel/CSV files are allowed to upload
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <input class="custom-file-input" type="file">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </mat-tab>
            
                                   
            
                                    </mat-tab-group>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
    <div mat-dialog-actions>
    <button *ngIf="data?.val !== 'view'" class="btn btn-theme">{{data?.val == 'add' ? 'Add' : 'Edit'}}</button>
    
    <button class="btn btn-cancle" mat-dialog-close>Cancel</button>
    </div>
    </form>