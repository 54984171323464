<div class="header-wrapper">
    <span>Add User</span>
    <button mat-icon-button class="btn close-button" [mat-dialog-close]="true">
        <i class="fa fa-close"></i>
      </button>
</div>
  
<form  [formGroup]="UserForm" (ngSubmit)="onSubmit();$event.stopPropagation();$event.preventDefault();">
<div mat-dialog-content>
    <div class="container-fluid">

        <div class="row mb-3">
            <div class="col-md-6">
                <mat-form-field class="example-full-width">
                    <mat-label>Name</mat-label>
                    <input matInput  formControlName ="name" (input)="validateInputLength()">
                    
                  </mat-form-field>
                  <mat-error class="mat-error"  *ngIf="(UserForm.controls['name'].touched || submitted) &&
         UserForm.controls['name'].errors && UserForm.controls['name'].errors?.['required']">*This Field is Required</mat-error>
             
            </div>
            <div class="col-md-6">
                <div class="check-toggle">
                    <p>Active</p>
                    <label class="switch-action">
                      <input type="checkbox" formControlName ="activeornot" (change)="activenot()">
                      <span class="slider round"></span>
                  </label>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-6">

                <mat-form-field>
                    <mat-label>IID</mat-label>
                    <mat-select formControlName ="iidControl" >
                        <mat-option *ngFor="let iid of tenantiid?.result?.iids" [value]="iid.iid">{{iid.iid}}</mat-option>
                    
                      </mat-select>
                  </mat-form-field>
           <mat-error class="mat-error"  *ngIf="(UserForm.controls['iidControl'].touched || submitted) &&
         UserForm.controls['iidControl'].errors && UserForm.controls['iidControl'].errors?.['required']">*This Field is Required</mat-error>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="fill">
                    <mat-label>UID</mat-label>
                    <mat-select formControlName ="uiidControl">
                        <mat-option *ngFor="let uiid of tenantuiid?.result?.uids" [value]="uiid.uid">{{uiid.uid}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-error class="mat-error"  *ngIf="(UserForm.controls['uiidControl'].touched || submitted) &&
                  UserForm.controls['uiidControl'].errors && UserForm.controls['uiidControl'].errors?.['required']">*This Field is Required</mat-error>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Roles</mat-label>
                    <mat-select formControlName ="rolesControl" multiple>
                  
                
                  
                      <mat-option *ngFor="let role of tenantrole?.result?.roles" [value]="role">{{role.name}}</mat-option>
                
                    </mat-select>
                  </mat-form-field>
                <mat-error class="mat-error"  *ngIf="(UserForm.controls['rolesControl'].touched || submitted) &&
         UserForm.controls['rolesControl'].errors && UserForm.controls['rolesControl'].errors?.['required']">*This Field is Required</mat-error>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Departments</mat-label>
                    <mat-select formControlName ="DepartmentControl"  multiple >
                  
                     
                  
                      <mat-option *ngFor="let department of departmentList" [value]="department?.value">{{department?.name}}</mat-option>
                  
                    </mat-select>
                  </mat-form-field>
                  <mat-error class="mat-error"  *ngIf="(UserForm.controls['DepartmentControl'].touched || submitted) &&
                  UserForm.controls['DepartmentControl'].errors && UserForm.controls['DepartmentControl'].errors?.['required']">*This Field is Required</mat-error>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-12">
                <mat-form-field class="example-full-width">
                    <mat-label>Username</mat-label>
                    <input matInput type="username" name="uuuusss" formControlName ="username" (keydown)="preventSpace($event)">
                  </mat-form-field>
                  <mat-error class="mat-error"  *ngIf="
                  (UserForm.controls['username'].touched || submitted) &&
                    UserForm.controls['username'].errors &&
                    UserForm.controls['username'].errors?.['required']
                " >*this Field is Required</mat-error>

            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field class="example-full-width">
                    <mat-label>Password</mat-label>
                    <img alt="" class="eye-icon" src="../../assets/img/{{showPassword ? 'Show' : 'Show_Hide'}}.svg"
                    (click)="showPassword = !showPassword" alt="show-hide" />
                    <input matInput name = 'sdsd' type="{{ showPassword ? 'text' : 'password' }}" formControlName ="password" (keydown)="preventSpace($event)">
                </mat-form-field> 
                <mat-error class="mat-error"  *ngIf="
                (UserForm.controls['password'].touched || submitted) && UserForm.controls['password'].errors && UserForm.controls['password'].errors?.['required']"
                >*This Field is Required</mat-error>

                <mat-error *ngIf="(UserForm.get('password')?.touched || submitted) && UserForm.get('password')?.hasError('minlength')">
                  Password must be at least 6 characters long.
                </mat-error>
            </div>
        </div>
      </div>
    </div>
    <div mat-dialog-actions>
      <button class="btn btn-theme" type="submit">Add</button>
      <button class="btn btn-cancle "mat-dialog-close >Cancel</button>
    </div>
  </form>