import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as reducer from '../../reducers/dictionary/UpdateMessageTemplateWorkflow.reducers'

const state = createFeatureSelector<reducer.State>('UpdateMessageTemplate');


export const selectLoadingUpdateMessageTemplateWorkflow = createSelector(
  state,
  state => state.isLoading
);

export const selectStatesUpdateMessageTemplateWorkflow= createSelector(
  state,
  state => state
);

export const selectStatesUpdateMessageTemplateWorkflowBody= createSelector(
  state,
  state => state.body
);

export const selectStatesUpdateMessageTemplateWorkflowData= createSelector(
  state,
  state => state.UpdateMessageTemplateWorkflow
);
