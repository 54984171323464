import { AuthenticationGuard } from './guard/authentication.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './modules/auth/components/login/login.component';
import { TemplateComponent } from './modules/dictionary/components/template/template.component';
import { RoleAddComponent } from './modules/usermanagement/components/role-add/role-add.component';
import { CampaignboxComponent } from './modules/campaign/components/campaignbox/campaignbox.component';
import { SelecttemplateComponent } from './selecttemplate/selecttemplate.component';
import { GroupsComponent } from './modules/adressbook/components/groups/groups.component';
import { MessageReportComponent } from './message-report/message-report.component';
import { MobileReportComponent } from './modules/commonModule/components/mobile-report/mobile-report.component';
import { CampaignReportComponent } from './modules/campaign/components/campaign-report/campaign-report.component';
import { SummaryReportComponent } from './summary-report/summary-report.component';
import { SelectTemplatePopupComponent } from './select-template-popup/select-template-popup.component';
import { PagenotfoundComponent } from './modules/commonModule/components/pagenotfound/pagenotfound.component';
import { LoginguardGuard } from './guard/loginguard.guard';


const routes: Routes = [
  { path: '', component: LoginComponent ,canActivate:[LoginguardGuard]},
  { path: 'login', component: LoginComponent ,canActivate:[LoginguardGuard]},
  { path: 'template', component: TemplateComponent ,canActivate:[AuthenticationGuard]},
  { path: 'role-add', component: RoleAddComponent ,canActivate:[AuthenticationGuard]},
  { path: 'role-edit/:id', component: RoleAddComponent ,canActivate:[AuthenticationGuard]},
  { path: 'role-view/:id', component: RoleAddComponent ,canActivate:[AuthenticationGuard]},
  { path: 'campaignbox', component:CampaignboxComponent,canActivate:[AuthenticationGuard]},
  { path: 'selecttemplate', component:SelecttemplateComponent,canActivate:[AuthenticationGuard]},
  { path: 'groups', component:GroupsComponent,canActivate:[AuthenticationGuard]},
  { path: 'message-report', component:MessageReportComponent,canActivate:[AuthenticationGuard]},
  { path: 'mobile-report', component:MobileReportComponent,canActivate:[AuthenticationGuard]},
  { path: 'campaign-report', component:CampaignReportComponent,canActivate:[AuthenticationGuard]},
  { path: 'summary-report', component:SummaryReportComponent,canActivate:[AuthenticationGuard]},
  {path:'select-template-popup', component:SelectTemplatePopupComponent,canActivate:[AuthenticationGuard]},

  {
    path: '',
    loadChildren: () =>
      import('./modules/campaign/campaign-module').then(
        (m) => m.CampaignModule
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./modules/usermanagement/usermanagement-module').then(
        (m) => m.UsermanagementModule
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./modules/dictionary/dictionary-module').then(
        (m) => m.DictionaryModule
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./modules/main-dashboard/mainDashboard-module').then(
        (m) => m.MainDashboardModule
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./modules/adressbook/addressbook-module').then(
        (m) => m.AddressBookModule
      ),
  },

  {
    path: '',
    loadChildren: () =>
      import('./modules/auth/auth-module').then(
        (m) => m.AuthModule
      ),
  },
  { path: '**', redirectTo: '404' },
  { path: '404', component: PagenotfoundComponent },


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
