<div class="header-wrapper">
    <span>Delete</span>
    <button mat-icon-button class="btn close-button" [mat-dialog-close]="true">
        <i class="fa fa-close"></i>
      </button>
</div>
  
<div mat-dialog-content>
    <div class="container-fluid">
        <div class="row mb-3">
            <div class="col-12">
                <i class="fa fa-exclamation-triangle"></i>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-12">
                <p>Are you sure that you want to delete this campaign?</p>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions>
<button class="btn btn-theme" >Delete</button>
<button class="btn btn-cancle" mat-dialog-close>Cancel</button>
</div>