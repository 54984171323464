import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as reducer from '../../reducers/side-menu/sideMenu.reducers'

const state = createFeatureSelector<reducer.State>('sideMenu');


export const selectLoadingsideMenu = createSelector(
  state,
  state => state.isLoading
);

export const selectStatessideMenu= createSelector(
  state,
  state => state
);

export const selectStatessideMenuBody= createSelector(
  state,
  state => state.body
);

export const selectStatessideMenuData= createSelector(
  state,
  state => state.sideMenu
);
