import { Action, createReducer, on } from '@ngrx/store';
import { getUsernameToUserInformation,getUsernameToUserInformationBody,GetUsersResponse ,ApprovedRequest,TenantUID,MyRequest,PendingRequest,Roles,Department} from '../../types';
import * as actions from '../../actions';
import * as storage from '../../state/storage';

export interface State {
    getUsernameToUserInformation: getUsernameToUserInformation;
    defaultGetUsernameToUserInformation: getUsernameToUserInformation | null;
    isLoading: boolean;
    isLoadingSuccess: boolean;
    isLoadingFailure: string;
    permissions:any;
    body: getUsernameToUserInformationBody;
}

export const initialState: State = {
  getUsernameToUserInformation: storage.getItem("usernameToUserInformation").getUsernameToUserInformation,
  defaultGetUsernameToUserInformation: storage.getItem("usernameToUserInformation").defaultGetUsernameToUserInformation,
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: '',
  permissions:[],
  body:{
  },
};

const funReducer = createReducer(
  initialState,
  on(actions.getUsernameToUserInformationList, (state) => ({...state, isLoading: true})),
  on(actions.getUsernameToUserInformationListSuccess, (state, result) => {
    return{
      ...state,
      defaultGetUsernameToUserInformation: result.response, 
      getUsernameToUserInformation: result.response, 
      isLoading: false, 
      isLoadingSuccess: true
    }}),
  on(actions.setPermissionForUser, (state, result) => {
    return{
      ...state,
      permissions:result.response,
      isLoading: false, 
      isLoadingSuccess: true
    }}),
  on(actions.getUsernameToUserInformationListFailure, (state, result) => ({
      ...state,
      getUsernameToUserInformation: {}, 
      isLoading: false, 
      isLoadingSuccess: false,
      isLoadingFailure:result.error
  })),
);

export function reducer(state: State | undefined, action: Action): any {
  return funReducer(state, action);
}

