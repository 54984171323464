import { Component, ViewChild } from "@angular/core";
import { ProfileComponent } from "../../../commonModule/components/profile/profile.component";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { FormControl } from "@angular/forms";
import * as moment from "moment";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { DeletecampaignComponent } from "../deletecampaign/deletecampaign.component";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { UsersService } from "src/app/modules/usermanagement/service/users/users.service";

@Component({
  selector: "app-campaigns",
  templateUrl: "./campaigns.component.html",
  styleUrls: ["./campaigns.component.scss"],
  animations: [
    trigger("detailExpand", [
      state(
        "collapsed",
        style({ height: "0px", minHeight: "0", display: "none" })
      ),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class CampaignsComponent {
  loadingData: boolean = true;
  dataSource = new MatTableDataSource<any>([]);
  SortVal: string = "first";
  userSearch: string = "";
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  pageSize: number = 10;
  pageIndex: number = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  columnsToDisplay = [
    "campaignName",
    "campaignStatus",
    "channels",
    "allowedStartTime",
    "allowedEndTime",
    "recordCount",
    "scheduledDateTime",
    "approvedByName",
    "approvedOn",
    "createdByName",
    "createdOn",
    // "Action",
  ];
   currentDate = moment(new Date);
  
  foption = new FormControl("");
  foptionsList: string[] = [
    "CAMPAIGN",
    "CAMPAIGN STATUS",
    "CHANNELS",
    "STARTDATE",
    "ENDDATE",
    "RECEPIENTS",
    "SCHEDULEDATE",
    "APPROVEDBY",
    "APPROVEDDATE",
    "CREATEDBY",
    "CREATEDDATE",
    "Action",
  ];
  destroy$: Subject<boolean> = new Subject<boolean>();
  public moment = moment;
  end: string = "";
  start: string = "";
  campaigns: Array<any> = [];
  constructor(
    public dialog: MatDialog,
    private userservice: UsersService,
    private router: Router
  ) {
    this.getcampaignlist();
  }
  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }
  getcampaignlist() {
    this.loadingData = true;
    const distantFutureDate = new Date();
distantFutureDate.setFullYear(distantFutureDate.getFullYear() + 10);
      this.userservice
        .Getcampaigns({
          offset: 0,
          count: 500,
          searchFilters: {
            fromDate: "2019-11-19",
            // toDate: this.formatDate(distantFutureDate), 
            toDate:  this.currentDate.add(2, 'day').format('YYYY-MM-DD'),
            // toDate: "2023-12-27",
            tenantId: 0,
            message: "",
            campaignName: "",
            isSearchonCreatedDate: false,
            searchOn: 1,
          },
        })
        .subscribe((res) => {
          this.campaigns = res?.result?.all;
          this.dataSource.data = res?.result?.all;
          this.loadingData = false;
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        });
  }


  usericonDialog() {
    this.dialog.open(ProfileComponent, {
      panelClass: ["common-modalbox"],
    });
  }

  deletecamp() {
    this.dialog.open(DeletecampaignComponent, {
      panelClass: ["common-delete-popupbox"],
    });
  }
  SearchUser(event: string): void {
    this.applyFilters();
  }
  
  dateRangeChange(eve: string, startend: string): void {
    if (startend === 'start') {
      this.start = eve;
    }
    if (startend === 'end') {
      this.end = eve;
    }
    this.applyFilters();
  }
  
  Sort(e: string): void {
    this.applyFilters();
  }
  
  applyFilters(): void {
    let filteredData = this.campaigns;
  
    if (this.userSearch) {
      filteredData = filteredData.filter((val) =>
        val?.campaignName.toLowerCase().trim().includes(this.userSearch.toLowerCase().trim())
      );
    }
  
    if (this.start && this.end) {
      filteredData = filteredData.filter((arr) =>
        this.start && this.end
          ? moment(moment(arr?.createdOn).format('YYYY-MM-DD')).isBetween(
              moment(this.start).format('YYYY-MM-DD'),
              moment(this.end).format('YYYY-MM-DD'),
              undefined,
              '[]'
            )
          : true
      );
    }
  
    if (this.SortVal === 'last') {
      filteredData = [...filteredData].reverse();
    }
  
    this.dataSource.data = filteredData;
  }
  
  resetFilters(): void {
    this.userSearch = '';
    this.start = '';
    this.end = '';
    this.SortVal = 'first';
    this.applyFilters();
  }
  
  // dateRangeChange(eve: string, startend: string) {
  //   console.log(this.campaigns, "  sdsd8");
  //   if (startend == "start") {
  //     this.start = eve;
  //   }
  //   if (startend == "end") {
  //     this.end = eve;
  //   }
  //   let datevalue = [];
  //   if (this.start && this.end) {
  //     datevalue = this.campaigns.filter((arr) => {
  //       return this.start && this.end
  //         ? moment(moment(arr?.createdOn).format("YYYY-MM-DD")).isBetween(
  //             moment(this.start).format("YYYY-MM-DD"),
  //             moment(this.end).format("YYYY-MM-DD"),
  //             undefined,
  //             "[]"
  //           )
  //         : true;
  //     });
  //     this.dataSource.data = datevalue;
  //   }
  // }

  resetSorting() {
    if (this.sort) {
      this.sort.active = "";
      this.sort.direction = "";
    }
    this.dataSource.data = [...this.dataSource.data];
  }

  // Sort(e: string) {
  //   this.dataSource.data = [];
  //   this.resetSorting();
  //   if (e == "first") {
  //     this.dataSource.data = this.campaigns;
  //   } else if (e == "last") {
  //     this.dataSource.data = [...this.campaigns].reverse();
  //   }
  //   this.userSearch = ''
  // }
  Reset() {
    this.start = "";
    this.end = "";
    this.userSearch = "";
    this.SortVal = "first";
    this.applyFilters();
  }

  // SearchUser(event: string): void {
  //   this.start = "";
  //   this.end = "";
  //   if (event) {
  //     this.dataSource.data = this.campaigns.filter((val) =>
  //       val?.campaignName
  //         .toLowerCase()
  //         .trim()
  //         .includes(event.toLowerCase().trim())
  //     );
  //   } else {
  //     this.dataSource.data = this.campaigns;
  //   }
  // }
  GoToCreate() {
    localStorage.removeItem("campdata");
    this.router.navigate(["/create-campaign/"]);
  }

  View(item: object | any) {
    let campdata: object | any = {
      Campaignname: item.CAMPAIGN,
      Startdate: item.STARTDATE,
      receip: item.RECEPIENTS,
    };

    localStorage.setItem("campdata", JSON.stringify(campdata));

    this.router.navigate(["/view-campaign/" + item?.rowId], {
      queryParams: { parm: "view" },
    });
  }

  GotoEdit(item: object | any) {
    let campdata: any = {
      Campaignname: item.CAMPAIGN,
      Startdate: item.STARTDATE,
      receip: item.RECEPIENTS,
    };

    localStorage.setItem("campdata", JSON.stringify(campdata));

    this.router.navigate(["/edit-campaign/" + item?.rowId]);
  }
}
