import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';

import {
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { SideMenuComponent } from 'src/app/side-menu/side-menu.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { FooterComponent } from 'src/app/footer/footer.component';


@NgModule({
  declarations: [
    SideMenuComponent,
    FooterComponent

  
  ],
  imports: [
    MatSidenavModule,
    MatTableModule,
    MatChipsModule,
    CommonModule,
    MatIconModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatExpansionModule,

  ],
  exports:[
    SideMenuComponent,
    FooterComponent
  ],
})
export class CommonFileModule { }
