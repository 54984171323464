<div class="header-wrapper">
    <span>My Profile</span>
    <button mat-icon-button class="btn close-button" [mat-dialog-close]="true">
        <i class="fa fa-close"></i>
    </button>
</div>
<div class="mainbox">
<div *ngIf="!loadingData">
    <div class="profilesection">

        <div class="user-icon">
            <i class="fa fa-user" aria-hidden="true"></i>
        </div>


        <div>
            <span class="name">{{userdetails?.display_Name}}</span> <br>
           <span>Campaign Manager <br> (Marketing)</span> 
        </div>

    </div>

    <p class="mt-3"> <span class="roles">Roles</span>
        <br>
        <span class="manager"> {{userdetails?.roles[0]?.roleName}}</span>

    </p>



    <p> <span class="roles"> Department</span>
        <br>
        <span class="manager">{{userdetails?.department[0]?.departmentName}}</span>
    </p>

    <p class="passwordtext">Change Password?</p>

    <div class="row">
        <div class="col-md-12">
            <div class="row mb-3">
                <div class="col-md-12">
                    <mat-form-field class="example-full-width inputbtn">
                        <mat-label>Current Password<span class="required-star">*</span></mat-label>
                        <img alt="" class="eye-icon" src="../../assets/img/{{showPassword ? 'Show' : 'Show_Hide'}}.svg"
                        (click)="showPassword = !showPassword" alt="show-hide" />
                        <input [(ngModel)]="currentpw" type="{{ showPassword ? 'text' : 'password' }}" matInput type="password" (keydown)="preventSpace($event)">
                    </mat-form-field>
                    <span style="color: red;" *ngIf="!currentpw && showerror">*This field is required</span>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="row mb-3">
                <div class="col-md-12">
                    <mat-form-field class="example-full-width inputbtn">
                        <mat-label>New Password<span class="required-star">*</span></mat-label>
                        <img alt="" class="eye-icon" src="../../assets/img/{{showPassword1 ? 'Show' : 'Show_Hide'}}.svg"
                        (click)="showPassword1 = !showPassword1" alt="show-hide" />
                        <input [(ngModel)]="newpw" type="{{ showPassword1 ? 'text' : 'password' }}" matInput type="password" (keydown)="preventSpace($event)">
                    </mat-form-field>
                    <span style="color: red;" *ngIf="!newpw && showerror">*This field is required</span>

                </div>
            </div>
        </div>
    </div>


    <div class="row">
        <div class="col-md-12">
            <div class="row mb-3">
                <div class="col-md-12">
                    <mat-form-field class="example-full-width inputbtn">
                        <mat-label>Confirm Password<span class="required-star">*</span></mat-label>
                        <img alt="" class="eye-icon" src="../../assets/img/{{showPassword2 ? 'Show' : 'Show_Hide'}}.svg"
                        (click)="showPassword2 = !showPassword2" alt="show-hide" />
                        <input [(ngModel)]="confirmpw" type="{{ showPassword2 ? 'text' : 'password' }}" matInput type="password" (keydown)="preventSpace($event)">
                    </mat-form-field>
                    <span style="color: red;" *ngIf="!confirmpw && showerror">*This field is required</span>

                </div>
            </div>
        </div>
    </div>
</div>
    <ng-container *ngIf="loadingData" >
        <!-- <div *ngIf="loadingData" class="loader">Loading...</div> -->
         <div class="loader">
          <mat-spinner diameter="40" color="accent"></mat-spinner>
          <div>Loading...</div>
         </div>
        
      </ng-container>


</div>
<div mat-dialog-actions >
    <button class="btn btn-theme" (click)="save()">Save</button>
    <button class="btn btn-cancle" mat-dialog-close>Cancel</button>
</div>
<div>
    <button class="btn btn-logout" (click)="logout()">Logout</button>
</div>