<div class="header-wrapper">
    <span>View Department</span>
    <button mat-icon-button class="btn close-button" [mat-dialog-close]="true">
        <i class="fa fa-close"></i>
      </button>
  </div>
  
  <div mat-dialog-content>
    <div class="container-fluid">
        <div class="row mb-3">
            <div class="col-md-12">
                <mat-form-field class="example-full-width">
                    <mat-label>English Name*</mat-label>
                    <input matInput value="{{data.showdata.name_English}}" readonly="readonly">
                </mat-form-field>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-md-12">
                <mat-form-field class="example-full-width">
                    <mat-label> Arabic Name*</mat-label>
                    <input matInput value="{{data.showdata.name_Arabic}}" readonly="readonly">
                </mat-form-field>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-md-6">
                <mat-form-field class="example-full-width">
                    <mat-label>Value*</mat-label>
                    <input matInput value="{{data.showdata.value}}" readonly="readonly">
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <div class="check-toggle">
                    <p>Active</p>
                    <label [ngClass]=" data.showdata.is_Active ? 'switch-action1' : 'switch-action'">
                        <input [(ngModel)]="checkbox" disabled type="checkbox">
                        <span class="slider round"></span>
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>
  <!-- <div mat-dialog-content>
    <div class="container-fluid">
  
        <div class="row mb-3">
            <div class="col-md-6">
                <mat-form-field class="example-full-width">
                    <mat-label>English Name</mat-label>
                    <input matInput value="{{data.showdata.name_English}}" readonly="readonly" >
                    
                  </mat-form-field>
             
                     </div>
                     <div class="col-md-6">
                        <mat-form-field class="example-full-width">
                            <mat-label>English Name</mat-label>
                            <input matInput value="{{data.showdata.name_English}}" readonly="readonly" >
                            
                          </mat-form-field>
                     
                             </div>
            <div class="col-md-6">
                <div class="check-toggle">
                    <p>{{data.showdata.is_Active ? 'Active' : 'Inactive'}}</p>
                    <label [ngClass]=" data.showdata.is_Active ? 'switch-action' : 'switch-action1'">
                        <input disabled type="checkbox">
                        <span class="slider round"></span>
                    </label>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-6">
  
                <mat-form-field appearance="fill">
                    <mat-label>Value</mat-label>
                    <input matInput value="{{data.showdata.value}}" readonly="readonly" >

                  </mat-form-field>
                  </div>
           
        </div>
       
      </div>
    </div> -->
    <div mat-dialog-actions>
      <button class="btn btn-cancle" mat-dialog-close>Cancel</button>
    </div>