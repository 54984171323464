

  export const environment = {
    production: true,
    LOGIN_SDK_KEY: "g1pll6m1eh0mp9e3tbp5phbcg",
    DEV_ENDPOINT : 'https://twowayserver.future-club.com/api/',
    X_Mt_Puffin_Id : `u8uqjK1SfLHVcJ2mRVbG07H6yf83MN+pLSoT03umoct14pOZRBE/22JS4uBjldZAq8GIIP0QZ7JzVSnElyvTCg==`

    };

