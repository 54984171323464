import { Component } from '@angular/core';

@Component({
  selector: 'app-linepopup',
  templateUrl: './linepopup.component.html',
  styleUrls: ['./linepopup.component.scss']
})
export class LinepopupComponent {

}
