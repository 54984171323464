import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './index';
import { GetUsernameToUserInformationListEffects,} from './effects/auth/auth.effects';
import { GetUsersEffects,} from './effects/user-management/GetUsers.effects';
import { sideMenuEffects,} from './effects/side-menu/sideMenu.effects';
import { getDepartmentsEffects,} from './effects/user-management/getDepartments.effects';


import{EffectsModule} from '@ngrx/effects'
import {getGroupsEffects , GetMasterpermissionEffects, GetMessageTemplatesEffects, GetMessageTemplateEffects, getRolesEffects, AddMessageTemplateEffects, UpdateMessageTemplateEffects, IsTemplateAssociatedToServiceEffects, GetMessageTemplateTypesEffects, DeleteMessageTemplateEffects, ApproveRejectMessageTemplateByWorkFlowEffects, UpdateMessageTemplateWorkflowEffects, UpdateMessageAssetPathXmlEffects, DeactivateUserEffects, GetDepartmentByIdEffects  } from './effects';
import { UpdateMessageTemplate } from './actions';

@NgModule({
  imports: [
    StoreModule.forRoot(reducers, {
      metaReducers,
    }),
    EffectsModule.forRoot([

      GetUsernameToUserInformationListEffects,
      GetUsersEffects,
      getRolesEffects,
      getGroupsEffects,
      GetMasterpermissionEffects,
      sideMenuEffects,
      getDepartmentsEffects,
      GetMessageTemplatesEffects,
      GetMessageTemplateEffects,
      AddMessageTemplateEffects,
      UpdateMessageTemplateEffects,
      IsTemplateAssociatedToServiceEffects,
      GetMessageTemplateTypesEffects,
      DeleteMessageTemplateEffects,
      ApproveRejectMessageTemplateByWorkFlowEffects,
      UpdateMessageTemplateWorkflowEffects,
      UpdateMessageAssetPathXmlEffects,
      DeactivateUserEffects,
      GetDepartmentByIdEffects
    ]),
  ],
  exports: [StoreModule, EffectsModule],
})

export class AppStateModule {}
